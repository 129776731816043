import * as yup from 'yup'

export const editTimeSchema = yup.object().shape({
  time: yup
    .string()
    .required('Time is required')
    .test(
      'test',
      "Selected datetime shouldn't be greater than current datetime",
      value => new Date(value || 0).getTime() <= new Date().getTime(),
    ),
})

export const editBreakTimeSchema = yup.object().shape({
  startTime: yup
    .string()
    .required('Start time is required')
    .test(
      'test',
      "Start datetime shouldn't be greater than current datetime",
      value => new Date(value || 0).getTime() <= new Date().getTime(),
    ),
  endTime: yup
    .string()
    .required('End time is required')
    .test(
      'test',
      "End datetime shouldn't be greater than current datetime",
      value => new Date(value || 0).getTime() <= new Date().getTime(),
    )
    .test(
      'less than start time',
      'End datetime must be greater than start datetime',
      (value, context) => {
        const startValue = context.parent.startTime
        return new Date(value || 0).getTime() > new Date(startValue).getTime()
      },
    ),
})
