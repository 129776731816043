import { Box, Typography, CircularProgress } from '@mui/material'
import { getServerDate } from 'core/config/server'
import Path from 'core/navigation/path'
import { User } from 'core/service'
import {
  setIsDateFetchingAction,
  setServerDateAction,
} from 'features/app/reducers/app'
import React, { ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type RouterProps = {
  user: User | null
  path: string
  children: ReactElement
}

export const ProtectedRoute = ({
  user,
  children,
}: RouterProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { isDateFetching } = useAppSelector(state => state.app)

  useEffect(() => {
    dispatch(setIsDateFetchingAction(true))
    getServerDate().then(date => {
      dispatch(setServerDateAction(date))
      dispatch(setIsDateFetchingAction(false))
    })
  }, [dispatch])

  if (!user) {
    return <Navigate replace to={Path.Root} />
  }
  return isDateFetching ? <DateFetchingLoader /> : children
}

const DateFetchingLoader = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    height="100%"
    justifyContent="center"
  >
    <CircularProgress />
    <Typography mt={3}>Fetching Today&apos;s Date</Typography>
  </Box>
)
