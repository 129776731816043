import {
  CoffeeOutlined,
  PlayArrowOutlined,
  StopOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import WorkFromHomeIcon from 'core/assets/48x48 Home new.svg'
import WorkFromOfficeIcon from 'core/assets/48x48 office new.svg'
import colors from 'core/constants/color'
import { UserStatus, WorkLocation } from 'core/constants/enum'
import { WorkClock } from 'features/dashboard/components/WorkClock'

import {
  punchBack,
  punchBreak,
  punchIn,
  punchOut,
  sendNotificationToManager,
} from 'features/dashboard/thunks/dashboard'
import {
  isUserOnBreak,
  milliSecondsToHMFormat,
} from 'features/dashboard/utils/dashboard'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const UserActivity = () => {
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState(false)
  const { user } = useAppSelector(state => state.auth)
  const { userActivity, totalBreak, attendanceDate } = useAppSelector(
    state => state.dashboard,
  )
  const userOnBreak = isUserOnBreak(userActivity?.intervals ?? [])
  const isUserAvailable = userActivity.start !== 0 && userActivity.end === 0

  const handlePunchIn = async () => {
    setLoader(true)
    await dispatch(
      punchIn({
        uid: user?.uid ?? '',
        date: attendanceDate,
      }),
    )
    setLoader(false)
    dispatch(
      sendNotificationToManager({
        userId: user?.uid ?? '',
        action: UserStatus.In,
      }),
    )
  }

  const handlePunchOut = async () => {
    setLoader(true)
    await dispatch(
      punchOut({
        uid: user?.uid ?? '',
        docId: userActivity?.id ?? '',
      }),
    )
    setLoader(false)
    dispatch(
      sendNotificationToManager({
        userId: user?.uid ?? '',
        action: UserStatus.Out,
      }),
    )
  }

  const handlePunchBrbAndBack = async () => {
    setLoader(true)
    userOnBreak
      ? await dispatch(
          punchBack({ uid: user?.uid ?? '', docId: userActivity?.id ?? '' }),
        )
      : await dispatch(
          punchBreak({ uid: user?.uid ?? '', docId: userActivity?.id ?? '' }),
        )
    setLoader(false)
    dispatch(
      sendNotificationToManager({
        userId: user?.uid ?? '',
        action: userOnBreak ? UserStatus.Back : UserStatus.Brb,
      }),
    )
  }

  return (
    <>
      <CardContent>
        <Box display="flex" justifyContent="center" py={4}>
          <Tooltip title={userActivity.start === 0 ? 'Punch In' : ''}>
            <Box>
              <IconButton
                disabled={loader || userActivity.start !== 0}
                sx={{ ...styles.onBreak, ...styles.iconBtn }}
                onClick={handlePunchIn}
              >
                <PlayArrowOutlined sx={styles.icon} />
              </IconButton>
            </Box>
          </Tooltip>

          <Tooltip
            title={
              // eslint-disable-next-line no-nested-ternary
              !isUserAvailable ? '' : userOnBreak ? 'Stop Break' : 'Start Break'
            }
          >
            <Box>
              <IconButton
                disabled={loader || !isUserAvailable}
                sx={
                  userOnBreak
                    ? { ...styles.onBreak, ...styles.iconBtn }
                    : { ...styles.notOnBreak, ...styles.iconBtn }
                }
                onClick={handlePunchBrbAndBack}
              >
                <CoffeeOutlined sx={styles.breakIcon} />
              </IconButton>
            </Box>
          </Tooltip>

          <Tooltip title={isUserAvailable ? 'Punch Out' : ''}>
            <Box>
              <IconButton
                disabled={loader || !isUserAvailable || userOnBreak}
                sx={styles.outButton}
                onClick={handlePunchOut}
              >
                <StopOutlined sx={styles.icon} />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="space-between" px={6} py={2}>
        {userActivity.workLocation && (
          <Tooltip title={`Working From ${userActivity.workLocation}`}>
            <Avatar
              src={
                userActivity.workLocation === WorkLocation.Office
                  ? WorkFromOfficeIcon
                  : WorkFromHomeIcon
              }
            />
          </Tooltip>
        )}
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography fontWeight="bold" variant="h6">
            {userActivity?.start === 0 ? '00:00:00' : <WorkClock />}
          </Typography>
          <Typography variant="caption">Total Work</Typography>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography fontWeight="bold" variant="h6">
            {milliSecondsToHMFormat(totalBreak)}
          </Typography>
          <Typography variant="caption">Breaks</Typography>
        </Box>
      </Box>
    </>
  )
}

const styles = {
  iconBtn: { p: 0, color: 'white', mr: 2.5, my: 1 },
  icon: { width: '3rem', height: '3rem', p: 1 },
  breakIcon: { width: '3rem', height: '3rem', p: 1.5 },
  avatar: {
    width: '2rem',
    height: '2rem',
  },
  locationIcon: { color: 'white', p: 0.125 },
  onBreak: {
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.green,
      opacity: '75%',
    },
  },
  notOnBreak: {
    backgroundColor: colors.yellow,
    '&:hover': {
      backgroundColor: colors.yellow,
      opacity: '75%',
    },
  },
  outButton: {
    p: 0,
    color: 'white',
    my: 1,
    backgroundColor: colors.red,
    '&:hover': {
      backgroundColor: colors.red,
      opacity: '75%',
    },
  },
}
