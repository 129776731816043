import {
  FilterListTwoTone,
  Person4,
  Edit,
  Done,
  Clear,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material'
import { Role, MembersFilter } from 'core/constants/enum'
import {
  getMembersData,
  updateUserWorkLocation,
} from 'features/dashboard/thunks/dashboard'
import {
  getFilteredMembersBasedOnStatus,
  getStatusBasedChipData,
} from 'features/members/utils/members'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

interface TableRowStyle {
  '&:hover'?: Object
  '&:last-child td, &:last-child th': Object
}

export const MembersTable = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const rowsPerPage = 10
  const { isDarkMode, serverDate } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const { membersData, isLoading } = useAppSelector(state => state.dashboard)
  const [loading, setLoading] = useState<boolean>(false)
  const [userLocation, setUserLocation] = useState<string>('')
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [updatedUserId, setUpdatedUserId] = useState<string>('')
  const [filterLabel, setFilterLabel] = useState<string>('Status')

  const filteredMembers = membersData?.length
    ? getFilteredMembersBasedOnStatus(membersData, filterLabel)
    : []

  const [page, setPage] = useState(0)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const tableRowStyle: TableRowStyle = {
    '&:last-child td, &:last-child th': { border: 0 },
  }
  if (user?.role === Role.Admin) {
    if (isDarkMode)
      tableRowStyle['&:hover'] = {
        background: 'rgba(61,61,61,0.67)',
        cursor: 'pointer',
      }
    else
      tableRowStyle['&:hover'] = {
        background: '#eeeeee',
        cursor: 'pointer',
      }
  }

  useEffect(() => {
    dispatch(getMembersData(serverDate))
  }, [dispatch, serverDate])

  const updateLocationOfUser = async (shiftId: string, location: string) => {
    setLoading(true)
    await dispatch(updateUserWorkLocation({ docId: shiftId, location }))
    setLoading(false)
    setOpenMenu(false)
    setUpdatedUserId('')
    setUserLocation('')
  }

  if (isLoading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress size={35} />
      </Box>
    )
  }

  return (
    <Container sx={styles.root}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex" gap={1}>
          <Person4 color="action" fontSize="large" />
          <Typography variant="h4">Members</Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent="flex-end" mb={2} mt={3}>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleMenu}
        >
          Filter by {filterLabel}
        </Button>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          id="menu-filter"
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.values(MembersFilter).map(value => (
            <MenuItem
              key={value}
              onClick={() => {
                setFilterLabel(
                  value === MembersFilter.ClearFilters ? 'Status' : value,
                )
                setPage(0)
                handleClose()
              }}
            >
              <Typography variant="body2">{value}</Typography>
            </MenuItem>
          ))}
        </Menu>
        {(filteredMembers?.length ?? 0) > rowsPerPage && (
          <TablePagination
            component="div"
            count={filteredMembers?.length ?? 0}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            onPageChange={handleChangePage}
          />
        )}
      </Box>
      <Paper
        sx={isDarkMode ? styles.tableDark : styles.tableLight}
        variant="outlined"
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Name</TableCell>
                <TableCell sx={styles.tableHeader}>Email Address</TableCell>
                <TableCell sx={styles.tableHeader}>Role</TableCell>
                <TableCell sx={styles.tableHeader}>
                  Current Work Location
                </TableCell>
                <TableCell sx={styles.tableHeader}>Current Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMembers
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(row => {
                  const { label, color, bgColor } = getStatusBasedChipData(
                    row?.currentStatus ?? '',
                  )
                  return (
                    <TableRow
                      key={row.uid}
                      sx={tableRowStyle as SxProps}
                      onClick={() =>
                        user?.role === Role.Admin &&
                        navigate(`/users/${row.uid}`)
                      }
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        <Typography
                          sx={styles.capitalizedText}
                          variant="inherit"
                        >
                          {row.role}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box
                          component="span"
                          sx={styles.locationBox}
                          onClick={(event: MouseEvent) =>
                            event.stopPropagation()
                          }
                        >
                          {updatedUserId === row.uid ? (
                            <Box component="span" sx={styles.selectBox}>
                              <FormControl sx={{ m: '6px', minWidth: 150 }}>
                                <InputLabel id="work-location">
                                  Work Location
                                </InputLabel>
                                <Select
                                  id="work-location"
                                  label="Work Location"
                                  open={openMenu}
                                  size="small"
                                  value={userLocation}
                                  onChange={event =>
                                    setUserLocation(event.target.value)
                                  }
                                  onClose={() => setOpenMenu(false)}
                                  onOpen={() => setOpenMenu(true)}
                                >
                                  <MenuItem value="Home">Home</MenuItem>
                                  <MenuItem value="Office">Office</MenuItem>
                                </Select>
                              </FormControl>
                              <Tooltip title="Save">
                                <IconButton
                                  disabled={loading}
                                  sx={styles.iconBtn}
                                  onClick={() =>
                                    updateLocationOfUser(
                                      row?.shiftId ?? '',
                                      userLocation,
                                    )
                                  }
                                >
                                  <Done sx={styles.icon} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Cancel">
                                <IconButton
                                  disabled={loading}
                                  sx={styles.iconBtn}
                                  onClick={() => {
                                    setOpenMenu(false)
                                    setUpdatedUserId('')
                                    setUserLocation(row.workLocation ?? '')
                                  }}
                                >
                                  <Clear sx={styles.icon} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box component="span" sx={styles.flexBox}>
                              <Typography>
                                {row?.workLocation ?? 'N/A'}
                              </Typography>
                              {user?.role === Role.Admin && row.workLocation && (
                                <Tooltip title="Edit Work Location">
                                  <IconButton
                                    sx={styles.iconBtn}
                                    onClick={() => {
                                      setUpdatedUserId(row?.uid ?? '')
                                      setUserLocation(row.workLocation ?? '')
                                      setOpenMenu(true)
                                    }}
                                  >
                                    <Edit sx={styles.icon} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={label}
                          sx={{
                            backgroundColor: bgColor,
                            minWidth: 80,
                            color,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  )
}

export const styles = {
  root: { p: 0.5, mt: 2.5 },
  tableDark: {
    border: '0.031rem solid rgba(255, 255, 255, 10%)',
  },
  tableLight: {
    border: '0.031rem solid rgba(0, 0, 0, 10%)',
  },
  tableRow: {
    position: 'relative',
    '&:last-child td, &:last-child th': { border: 0 },
  },
  tableHeader: {
    fontSize: '0.9rem',
  },
  capitalizedText: { textTransform: 'capitalize' },
  filterIcon: {
    p: 0,
  },
  circleIcon: { mr: 1 },
  loader: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  iconBtn: {
    padding: '4px',
  },
  icon: {
    fontSize: '18px',
  },
  locationBox: {
    display: 'inline-flex',
    gap: '10px',
    alignItems: 'center',
  },
  selectBox: {
    display: 'flex',
    alignItems: 'center',
  },
  flexBox: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}
