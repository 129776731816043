import colors from 'core/constants/color'
import {
  DateFilterLabelForMembers,
  MembersFilter,
  UserStatus,
} from 'core/constants/enum'
import { Interval, User } from 'core/service'
import {
  getEndOfLastWeek,
  getEndOfPreviousMonth,
  getEndOfThisMonth,
  getEndOfThisWeek,
  getStartOfLastWeek,
  getStartOfPreviousMonth,
  getStartOfThisMonth,
  getStartOfThisWeek,
} from 'features/activity-feed/utils/activity-feed'

export const parsedDate = (dateString: string) => new Date(dateString).getTime()

export const getBreakDuration = (intervals: Array<Interval>) => {
  let totalBreak = 0
  intervals.forEach(interval => {
    if (interval.start !== 0 && interval.end !== 0) {
      const startTime = new Date(interval.start).getTime()
      const endTime = new Date(interval.end).getTime()
      const diff = endTime - startTime
      const milliSeconds = Math.floor(diff)
      totalBreak += milliSeconds
    }
  })
  return totalBreak
}

export const getFilteredMembersBasedOnStatus = (
  members: User[],
  filter: string,
) => {
  if (filter === 'Status') {
    return members
  } else if (filter === MembersFilter.UsersIn) {
    return members.filter(
      member =>
        member.currentStatus === UserStatus.In ||
        member.currentStatus === UserStatus.Brb,
    )
  } else if (filter === MembersFilter.UsersOut) {
    return members.filter(member => member.currentStatus === UserStatus.Out)
  } else if (filter === MembersFilter.UsersAvailable) {
    return members.filter(member => member.currentStatus === UserStatus.In)
  } else if (filter === MembersFilter.UsersOnBreak) {
    return members.filter(member => member.currentStatus === UserStatus.Brb)
  } else if (filter === MembersFilter.UsersNotIn) {
    return members.filter(member => !member.currentStatus)
  }
}

export const getFilterBasedDateRange = (
  currentDate: number,
  filter: string,
): { start: number; end: number } => {
  if (filter === DateFilterLabelForMembers.Today) {
    return {
      start: currentDate,
      end: currentDate,
    }
  } else if (
    filter === DateFilterLabelForMembers.ThisWeek ||
    filter === DateFilterLabelForMembers.Default
  ) {
    return {
      start: getStartOfThisWeek(currentDate),
      end: getEndOfThisWeek(currentDate),
    }
  } else if (filter === DateFilterLabelForMembers.LastWeek) {
    return {
      start: getStartOfLastWeek(currentDate),
      end: getEndOfLastWeek(currentDate),
    }
  } else if (filter === DateFilterLabelForMembers.ThisMonth) {
    return {
      start: getStartOfThisMonth(currentDate),
      end: getEndOfThisMonth(currentDate),
    }
  }
  return {
    start: getStartOfPreviousMonth(currentDate),
    end: getEndOfPreviousMonth(currentDate),
  }
}

export const getStatusBasedChipData = (
  status: string,
): { bgColor: string; label: string; color: string } => {
  if (status === UserStatus.In) {
    return {
      bgColor: colors.green,
      label: 'Available',
      color: colors.black,
    }
  } else if (status === UserStatus.Out) {
    return {
      bgColor: colors.red,
      label: 'Out',
      color: colors.white,
    }
  } else if (status === UserStatus.Brb) {
    return {
      bgColor: colors.yellow,
      label: 'On Break',
      color: colors.black,
    }
  }
  return {
    bgColor: colors.grey,
    label: 'Not In',
    color: colors.white,
  }
}
