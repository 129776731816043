import { dayAndDateFormat } from 'core/constants/enum'
import { Report } from 'core/service'
import {
  milliSecondsToHMFormat,
  milliSecondsToHours,
} from 'features/dashboard/utils/dashboard'
import { DateRange } from 'moment-range'
import { v4 as uuidv4 } from 'uuid'

export const getUniqueObjectsWithinArray = <T>(
  dataArray: T[],
  objKey: keyof T,
) =>
  dataArray.reduce((uniqueArr: T[], currItem: T) => {
    const existingItem = uniqueArr.find(
      item => item[objKey] === currItem[objKey],
    )
    !existingItem && uniqueArr.push(currItem)
    return uniqueArr
  }, [])

export const calculateNoTFoundShifts = (
  reports: Report[],
  dateRange: DateRange,
  userName: string,
) => {
  const allDates: string[] = reports.map(obj => obj.date)

  return Array.from(dateRange.by('day'))
    .filter(date => {
      const dateString = date.format(dayAndDateFormat)
      return !allDates.includes(dateString)
    })
    .map(date => ({
      date: date.format(dayAndDateFormat),
      name: userName,
      shift: 'No Shift Found',
      isNoShift: true,
    }))
}

export const hoursToHMFormat = (hours: number) => {
  const isNegative = hours < 0
  const absoluteValue = Math.abs(hours)
  const integerPart = Math.floor(absoluteValue)
  const decimalPart = (absoluteValue - integerPart) * 60
  return integerPart
    ? `${isNegative ? '-' : ''}${integerPart}h${Math.floor(decimalPart)}m`
    : `${isNegative ? '-' : ''}${Math.floor(decimalPart)}m`
}
export const getUpdatedReports = (reports: Report[]) =>
  reports.map(report => {
    const newReport = { ...report }

    if (typeof newReport.workDurationInHrs === 'number') {
      newReport.workDurationInHrs = milliSecondsToHours(
        newReport.workDurationInHrs,
      )
    }
    if (typeof newReport.totalDuration === 'number') {
      newReport.totalDuration = milliSecondsToHMFormat(newReport.totalDuration)
    }
    if (typeof newReport.breakDuration === 'number') {
      newReport.breakDuration = milliSecondsToHMFormat(newReport.breakDuration)
    }
    if (typeof newReport.workDuration === 'number') {
      newReport.workDuration = milliSecondsToHMFormat(newReport.workDuration)
    }

    return newReport
  })

export const getVarianceCellColor = (
  isDark: boolean,
  deviationHours: number | undefined,
) => {
  if (deviationHours !== 0 && !deviationHours) {
    return { backgroundColor: isDark ? '#888c15' : '#fff176' }
  } else if (deviationHours > 0) {
    return { fontWeight: 'bold', backgroundColor: '#c62828', color: 'white' }
  }
  return { fontWeight: 'bold', backgroundColor: '#388e3c', color: 'white' }
}

export const UUID = () => {
  const uuid = uuidv4().replace(/-/g, '')
  return uuid.substring(0, 20)
}
