import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material'
import React, { useState } from 'react'

type ConfirmModalProps = {
  closeModal: () => void
  dialogTitle: string
  open: boolean
  dialogText: string
  handleConfirm: () => void
}
const ConfirmModal: React.FC<ConfirmModalProps> = props => {
  const { closeModal, dialogTitle, open, dialogText, handleConfirm } = props
  const [loading, setLoading] = useState(false)
  const onConfirm = async () => {
    setLoading(true)
    await handleConfirm()
    setLoading(false)
    closeModal()
  }

  return (
    <div>
      <Dialog
        fullWidth
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth="xs"
        open={open}
        onClose={closeModal}
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          <Box sx={styles.btnBox}>
            <Button
              color="inherit"
              disabled={loading}
              variant="text"
              onClick={closeModal}
            >
              No
            </Button>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default ConfirmModal

const styles = {
  btnBox: {
    mt: '1.5rem',
    display: 'flex',
    justifyContent: 'right',
    gap: '0.75rem',
  },
}
