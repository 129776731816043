import { Box, FormHelperText, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { useField } from 'formik'
import React from 'react'

type FormikTextFieldProps = {
  name: string
  readonly: boolean
  textLabel?: string
  size?: string
} & TextFieldProps

const FormikTextField: React.FC<FormikTextFieldProps> = ({
  name,
  readonly,
  textLabel,
  size = 'small',
  ...props
}) => {
  const [field, meta] = useField(name)
  const helperText =
    // eslint-disable-next-line no-nested-ternary
    !readonly && meta.touched ? meta.error : readonly ? meta.error : undefined

  return (
    <Box>
      <TextField
        {...field}
        {...props}
        fullWidth
        error={readonly ? !!meta.error : !!(meta.touched && meta.error)}
        inputProps={{
          ...props.inputProps,
          readOnly: readonly,
        }}
        label={textLabel}
        size={size}
        sx={{ position: 'relative' }}
      />
      <FormHelperText sx={{ color: '#e0435d', ml: '8px' }}>
        {helperText ?? ''}
      </FormHelperText>
    </Box>
  )
}

export default FormikTextField

FormikTextField.defaultProps = {
  textLabel: '',
  size: 'small',
}
