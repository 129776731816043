import { WhoisinPingServer, WorkLocation } from 'core/constants/enum'

const fetchWithTimeout = async (
  resource: string,
  options: { timeout: number },
) => {
  const { timeout } = options
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    mode: 'no-cors',
    cache: 'no-cache',
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

export const pingServerForLocation = async () => {
  try {
    await fetchWithTimeout(WhoisinPingServer, {
      timeout: 5000,
    })
    return WorkLocation.Office
  } catch (error) {
    return WorkLocation.Home
  }
}
