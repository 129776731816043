const Path = {
  Root: '/',
  Dashboard: '/dashboard',
  ActivityFeed: '/activity-feed',
  Reports: '/reports',
  Members: '/users',
  ResetPassword: '/reset-password',
  MemberDetails: '/users/:id',
  WorkRequest: '/work-request',
  UsersManagement: '/users-management',
  Settings: '/settings',
  CalendarView: '/work-requests/calendar-view',
  MyTeam: '/my-team',
}

export default Path
