import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ImportedLog } from 'core/service'
import { reportService } from '../service/service'

export const getReports = createAsyncThunk(
  'reports/getReports',
  (
    values: {
      uids: string[]
      fromDate: number
      toDate: number
      reportType: string
      workLocation: string
    },
    { rejectWithValue },
  ) => {
    try {
      return reportService.getReports(
        values.uids,
        values.fromDate,
        values.toDate,
        values.reportType,
        values.workLocation,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const importTimesheetData = createAsyncThunk(
  'reports/importTimesheetData',
  (
    values: {
      startDate: string
      endDate: string
    },
    { rejectWithValue },
  ) => {
    try {
      return reportService.importTimesheetData(values.startDate, values.endDate)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getTimesheetLogs = async (startDate: string, endDate: string) => {
  const apiURL = `${process.env.REACT_APP_GET_TIMESHEET_LOGS}?startDate=${startDate}&endDate=${endDate}`
  const response = await axios.get(apiURL)
  return response.data.data.rows as ImportedLog[]
}
