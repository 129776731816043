import { createAsyncThunk } from '@reduxjs/toolkit'
import { myTeamService } from 'features/my-team/service/service'

export const getMyTeam = createAsyncThunk(
  'my-team/getMyTeam',
  (uid: string, { rejectWithValue }) => {
    try {
      return myTeamService.getMyTeam(uid)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const enableUserToCreateWorkRequestForToday = createAsyncThunk(
  'my-team/enableUserToCreateWorkRequestForToday',
  ({ uid, date }: { uid: string; date: string }, { rejectWithValue }) => {
    try {
      return myTeamService.enableUserToCreateWorkRequestForToday(uid, date)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const disableUserToCreateWorkRequestForToday = createAsyncThunk(
  'my-team/disableUserToCreateWorkRequestForToday',
  ({ uid, date }: { uid: string; date: string }, { rejectWithValue }) => {
    try {
      return myTeamService.disableUserToCreateWorkRequestForToday(uid, date)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const checkIfMemberRequestAlreadyCreatedForToday = createAsyncThunk(
  'my-team/checkIfMemberRequestAlreadyCreatedForToday',
  ({ uid, date }: { uid: string; date: string }, { rejectWithValue }) => {
    try {
      return myTeamService.checkIfMemberRequestAlreadyCreatedForToday(uid, date)
    } catch (err) {
      console.error(err)
      return rejectWithValue(err)
    }
  },
)

export const updateMemberNotifications = createAsyncThunk(
  'my-team/updateMemberNotifications',
  (
    {
      uid,
      notificationsEnabled,
    }: { uid: string; notificationsEnabled: boolean },
    { rejectWithValue },
  ) => {
    try {
      return myTeamService.updateMemberNotifications(uid, notificationsEnabled)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
