import LoginForm from 'features/landing/components/LoginForm'
import React from 'react'

const Landing = () => (
  <>
    <LoginForm />
  </>
)

export default Landing
