import activityFeedSlice from 'features/activity-feed/reducers/activity-feed'
import appSlice from 'features/app/reducers/app'
import dashboardSlice from 'features/dashboard/reducers/dashboard'
import authSlice from 'features/landing/reducers/auth'
import membersSlice from 'features/members/reducers/members'
import reportsSlice from 'features/reports/reducers/reports'
import SettingsSlice from 'features/settings/reducers/settings'
import workRequestSlice from 'features/work-request/reducers/work-request'
import { combineReducers } from 'redux'
import myTeamSlice from '../features/my-team/reducers/my-team'
import UsersManagementSlice from '../features/users-management/reducers/users-managements'

const rootReducers = combineReducers({
  app: appSlice.reducer,
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  members: membersSlice.reducer,
  reports: reportsSlice.reducer,
  activityFeed: activityFeedSlice.reducer,
  workRequests: workRequestSlice.reducer,
  settings: SettingsSlice.reducer,
  usersManagement: UsersManagementSlice.reducer,
  myTeam: myTeamSlice.reducer,
})

export default rootReducers
