export enum Role {
  Admin = 'admin',
  Member = 'member',
  Manager = 'manager',
}

export enum UserStatus {
  In = 'In',
  Brb = 'Will be right back',
  Back = 'Back',
  Out = 'Out',
}

export enum Status {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum DateFilter {
  Today = 'today',
  ThisWeek = 'this-week',
  LastWeek = 'last-week',
  ThisMonth = 'this-month',
}

export enum StatusFilter {
  In = 'in',
  Available = 'available',
  OnBreak = 'on-break',
  Out = 'out',
  NotIn = 'not-in',
}

export enum StatusFilterLabel {
  Default = 'Status',
  In = 'Signed-in',
  Available = 'Available',
  OnBreak = 'On-break',
  Out = 'Out',
  NotIn = 'Not Signed-in',
}

export enum DateFilterLabel {
  Default = 'Date',
  Today = 'Today',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  ThisMonth = 'This Month',
}

export enum DateFilterLabelForMembers {
  Default = 'Date',
  Today = 'Today',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  ThisMonth = 'This Month',
  LastMonth = 'LastMonth',
}

export enum ReportType {
  Date = 'date',
  User = 'user',
}

export enum WorkLocation {
  Office = 'Office',
  Home = 'Home',
}

export enum WorkRequestStatus {
  Open = 'Open',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}

export enum WorkRequestType {
  WFH = 'WFH',
  Leave = 'Leave',
}
export enum LeaveRequestType {
  Annual = 'Annual',
  Casual = 'Casual',
  Sick = 'Sick',
}

export enum CustomRequestsFilterLabel {
  MyRequests = 'My Requests',
  TeamRequests = 'Team Requests',
}

export enum CustomDaysFilterLabel {
  Next3Months = 'Next 3 Months',
  ThisMonth = 'This Month',
  Last6Months = 'Last 6 Months',
  Last1Year = 'Last 1 Year',
}

export const defaultUsername = 'John Doe'

export const ErrorAlertTimeout = 1500
export const SuccessAlertTimeout = 500

const timezone = 'Etc/UTC'
export const timeServerApi = 'https://worldtimeapi.org/api/timezone/' + timezone

export const WhoisinPingServer = 'https://whoisinping.techno-soft.com/'

export const dayInMillis = 86400000

export const dayAndDateFormat = 'ddd, MMM D, YYYY'
export const dateFormat = 'MMM DD, YYYY'

export const dateTimeFormat = 'MMM DD, YYYY hh:mm A'

export const YMDFormat = 'YYYY-MM-DD'
export const TimeHMFFormat = 'h:mm a'
export const LocalStringDateFormat = 'ddd, MMM D, YYYY'

export const UserActivityStatus = Object.freeze({
  Active: 'Active',
  InActive: 'In Active',
})

export const RequestType = Object.freeze({
  MyRequests: 'my-requests',
  TeamRequests: 'team-requests',
})

export const UserCurrentStatus = Object.freeze({
  In: 'In',
  Out: 'Out',
  OnBreak: 'brb',
})

export const MembersFilter = Object.freeze({
  UsersIn: 'Users In',
  UsersAvailable: 'Users Available',
  UsersOnBreak: ' Users On Break',
  UsersOut: 'Users Out',
  UsersNotIn: 'Users Not In',
  ClearFilters: 'Clear Filters',
})
