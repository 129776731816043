import {
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import {
  LeaveRequestType,
  WorkRequestType,
  YMDFormat,
} from 'core/constants/enum'
import { User, UserWorkRequest } from 'core/service'
import { timestampToYMDFormat } from 'features/dashboard/utils/dashboard'
import { Formik, Form } from 'formik'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setLoadingAction } from '../reducers/work-request'
import { LeaveRequestSchema } from '../schema'
import {
  createUserLeaveRequestByAdmin,
  fetchMemberWorkRequest,
  getManagerTeam,
} from '../thunks/work-request'
import {
  getCountOfDaysWithoutWeekends,
  canAddNewRequest,
} from '../utils/work-request'

type ComponentProps = {
  onClose: () => void
}

const CreateMemberLeaveRequestForm: React.FC<ComponentProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [selectedMember, setSelectedMember] = useState<User | null>(null)
  const { user } = useAppSelector(state => state.auth)
  const { serverDate } = useAppSelector(state => state.app)
  const { managerTeam } = useAppSelector(state => state.workRequests)

  useEffect(() => {
    const fetchData = async () => {
      const userId = user?.uid ?? ''
      const role = user?.role ?? ''

      await dispatch(getManagerTeam({ uid: userId, role }))
      dispatch(setLoadingAction(false))
    }
    fetchData()
  }, [dispatch, user])

  const initialValues = {
    fromDate: timestampToYMDFormat(serverDate),
    toDate: timestampToYMDFormat(serverDate),
    reason: '',
    leaveType: LeaveRequestType.Annual,
    memberId: '',
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LeaveRequestSchema}
      onSubmit={async values => {
        setLoading(true)
        const requests: UserWorkRequest[] = await fetchMemberWorkRequest(
          values?.memberId ?? '',
        )
        const canAdd = canAddNewRequest(
          requests,
          values.fromDate,
          values.toDate,
          WorkRequestType.Leave,
        )
        if (!canAdd) {
          toast.warning(
            'Member have already created work request that overlaps with this date range.',
          )
          setLoading(false)
          onClose()
          return
        }
        await dispatch(
          createUserLeaveRequestByAdmin({
            uid: values?.memberId ?? '',
            requestedBy: selectedMember?.name ?? '',
            type: WorkRequestType.Leave,
            leaveType: values?.leaveType ?? '',
            reason: values?.reason ?? '',
            fromDate: moment(values.fromDate).format(YMDFormat),
            toDate: moment(values.fromDate).format(YMDFormat),
            approvedBy: user?.name ?? '',
          }),
        )
        setLoading(false)
        onClose()
      }}
    >
      {({ values, setFieldValue, handleChange, errors, touched }) => (
        <Form>
          <Grid container spacing={2.5} sx={{ mt: '0rem' }}>
            <Grid item md={6} sx={{ pt: '0rem' }} xs={12}>
              <Box sx={styles.radioGroup}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Leave Type
                </Typography>
                <RadioGroup
                  row
                  value={values.leaveType}
                  onChange={e => setFieldValue('leaveType', e.target.value)}
                >
                  {Object.values(LeaveRequestType).map(value => (
                    <FormControlLabel
                      key={value}
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">{value}</Typography>}
                      value={value}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={option => option.name}
                options={managerTeam}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!!(touched.memberId && errors.memberId)}
                    helperText={
                      touched.reason && errors.memberId ? errors.memberId : ''
                    }
                    label="Select Member"
                    name="memberId"
                    variant="outlined"
                  />
                )}
                renderOption={(params, option) => (
                  <Box component="li" {...params}>
                    {option.name}
                  </Box>
                )}
                value={selectedMember}
                onChange={(_event, newValue: User | null) => {
                  setFieldValue('memberId', newValue?.uid)
                  setSelectedMember(newValue)
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="fromDate"
                      readonly={true}
                      size="medium"
                      textLabel="From Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.fromDate}
                  onChange={newVal => setFieldValue('fromDate', newVal)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="toDate"
                      readonly={true}
                      size="medium"
                      textLabel="To Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.toDate}
                  onChange={newVal => setFieldValue('toDate', newVal)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={6} sx={styles.days} xs={12}>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                Days Requested :
              </Typography>
              <Typography sx={{ fontSize: '13px' }}>
                {getCountOfDaysWithoutWeekends(values.fromDate, values.toDate)}{' '}
                day(s)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                error={!!(touched.reason && errors.reason)}
                helperText={
                  touched.reason && errors.reason ? errors.reason : ''
                }
                id="reason"
                label="Reason"
                rows={6}
                size="medium"
                type="text"
                value={values.reason}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={styles.btnBox}>
            <Button
              color="inherit"
              disabled={loading}
              variant="text"
              onClick={onClose}
            >
              Close
            </Button>
            <Button disabled={loading} type="submit" variant="contained">
              Create & Approve
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default CreateMemberLeaveRequestForm

const styles = {
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  btnBox: {
    mt: '1rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
}
