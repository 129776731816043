import { Timer } from '@mui/icons-material'
import { Box, Container, Grid, Typography } from '@mui/material'
import { dayInMillis } from 'core/constants/enum'
import { Attendance } from 'features/dashboard/components/Attendance'
import { UserActivityList } from 'features/dashboard/components/UserActivityList'
import { WhosInOut } from 'features/dashboard/components/WhosInOut'
import { setAttendanceDateAction } from 'features/dashboard/reducers/dashboard'
import {
  getMembersData,
  getUserActivities,
  getUserPrevDayActivities,
} from 'features/dashboard/thunks/dashboard'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const { serverDate } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const { attendanceDate } = useAppSelector(state => state.dashboard)

  useEffect(() => {
    if (serverDate) {
      const date = serverDate
      dispatch(setAttendanceDateAction(date))
      dispatch(
        getUserPrevDayActivities({
          uid: user?.uid ?? '',
          date: date - dayInMillis,
        }),
      )
    }
  }, [dispatch, user, serverDate])

  useEffect(() => {
    if (attendanceDate) {
      dispatch(
        getUserActivities({ uid: user?.uid ?? '', date: attendanceDate }),
      )
      dispatch(getMembersData(attendanceDate))
    }
  }, [dispatch, user, attendanceDate])

  return (
    <Container sx={styles.root}>
      <Box p={1.5}>
        <Box>
          <Box alignItems="center" display="flex" gap={2}>
            <Timer color="action" fontSize="large" />
            <Typography variant="h4">Dashboard</Typography>
          </Box>
          <Typography ml={6} mt={0.5} variant="body2">
            Welcome, {user?.name ?? ''}
          </Typography>
        </Box>
      </Box>

      <Grid container mt={2}>
        <Grid item lg={4.5} md={12} sx={styles.grid} xs={12}>
          <Attendance />
        </Grid>
        <Grid item lg={4} md={6} sx={styles.grid} xs={12}>
          <UserActivityList />
        </Grid>
        <Grid item lg={3.5} md={6} sx={styles.grid} xs={12}>
          <WhosInOut />
        </Grid>
      </Grid>
    </Container>
  )
}

const styles = {
  root: { mt: 2.5, p: 0 },
  grid: { p: { xs: 1, sm: 1.5 } },
  clickableGrid: { p: 1.5, '&:hover': { cursor: 'pointer' } },
  spinner: { height: { xs: 'calc(100vh - 7rem)', sm: 'calc(100vh - 8rem)' } },
}

export default Dashboard
