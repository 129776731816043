import colors from 'core/constants/color'

const baseTheme = {
  typography: {
    fontFamily: 'Quicksand',
    fontSize: 13,
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: colors.indigo,
    },
    secondary: {
      main: colors.grey,
    },
  },
}

export default baseTheme
