import { createSlice } from '@reduxjs/toolkit'
import {
  CustomRequestsFilterLabel,
  WorkRequestStatus,
} from 'core/constants/enum'
import { UserWorkRequest, User } from 'core/service'
import { signout } from 'features/landing/thunks/auth'
import {
  getMembersWorkRequests,
  approveOrRejectUserWorkRequest,
  createUserWorkRequest,
  createUserLeaveRequestByAdmin,
  deleteUserWorkRequest,
  getUserWorkRequests,
  updateUserWorkRequest,
  getManagerTeam,
  cancelUserWorkRequest,
  getSpecificMemberWorkRequests,
} from 'features/work-request/thunks/work-request'
import { WorkRequestState } from 'features/work-request/types'
import { orderBy } from 'lodash'
import { toast } from 'react-toastify'

const initialState = {
  isLoading: false,
  statusFilter: 'All',
  requestType: CustomRequestsFilterLabel.MyRequests,
  myRequests: [] as Array<UserWorkRequest>,
  membersRequests: [] as Array<UserWorkRequest>,
  selectedMember: null,
  managerTeam: [] as Array<User>,
} as WorkRequestState

const workRequestSlice = createSlice({
  name: 'workRequestSlice',
  initialState,
  reducers: {
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload
    },
    setLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
    setRequestType: (state, action) => {
      state.requestType = action.payload
    },
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload
    },
    setMembersRequests: (state, action) => {
      state.membersRequests = action.payload
    },
    setMyRequests: (state, action) => {
      state.myRequests = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(createUserWorkRequest.fulfilled, (state, action) => {
      state.myRequests.push(action.payload)
      state.myRequests = orderBy(state.myRequests, 'fromDate', 'desc')
      state.isLoading = false
      toast.success('Work request created successfully.')
    })
    builder.addCase(
      createUserLeaveRequestByAdmin.fulfilled,
      (state, action) => {
        state.membersRequests.push(action.payload)
        state.membersRequests = orderBy(
          state.membersRequests,
          'fromDate',
          'desc',
        )
        toast.success('Member Leave request created & approved successfully.')
      },
    )
    builder.addCase(updateUserWorkRequest.fulfilled, (state, action) => {
      const response = action.payload
      state.myRequests.map(row => {
        if (row.id === response.id) {
          row.fromDate = response.fromDate
          row.toDate = response.toDate
          row.reason = response.reason
          row.type = response.type
          row.leaveType = response.leaveType
          row.medicalDoc = response.medicalDoc
          row.remarks = response.remarks
          row.status = response.status
        }
        return true
      })
      state.myRequests = orderBy(state.myRequests, 'fromDate', 'desc')
      state.isLoading = false
      toast.success('Work request updated successfully.')
    })
    builder.addCase(
      approveOrRejectUserWorkRequest.fulfilled,
      (state, action) => {
        const response = action.payload
        state.membersRequests.forEach(row => {
          if (row.id === response.id) {
            row.approvedAt = response.approvedAt
            row.approvedBy = response.approvedBy
            row.remarks = response.remarks
            row.status = response.status
          }
        })
        state.membersRequests = orderBy(
          state.membersRequests,
          'fromDate',
          'desc',
        )
        state.isLoading = false
        const status =
          response.status === WorkRequestStatus.Open
            ? 'Returned'
            : response.status
        toast.success(`Work request ${status} successfully.`)
      },
    )

    builder.addCase(getMembersWorkRequests.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getMembersWorkRequests.fulfilled, (state, action) => {
      state.membersRequests = orderBy(action.payload, 'fromDate', 'desc')
      state.isLoading = false
    })
    builder.addCase(getMembersWorkRequests.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(getSpecificMemberWorkRequests.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      getSpecificMemberWorkRequests.fulfilled,
      (state, action) => {
        state.membersRequests = action.payload
        state.isLoading = false
      },
    )
    builder.addCase(getSpecificMemberWorkRequests.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(getManagerTeam.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getManagerTeam.fulfilled, (state, action) => {
      state.managerTeam = orderBy(
        action.payload,
        e => e.name.toLowerCase(),
        'asc',
      )
    })
    builder.addCase(getManagerTeam.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(getUserWorkRequests.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getUserWorkRequests.fulfilled, (state, action) => {
      const response = action.payload
      state.myRequests = orderBy(response, 'fromDate', 'desc')
      state.isLoading = false
    })
    builder.addCase(getUserWorkRequests.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(deleteUserWorkRequest.fulfilled, (state, action) => {
      state.isLoading = false
      const docId = action.payload
      state.myRequests = state.myRequests.filter(row => row.id !== docId)
      state.myRequests = orderBy(state.myRequests, 'fromDate', 'desc')
      toast.success('Work request deleted successfully.')
    })

    builder.addCase(cancelUserWorkRequest.fulfilled, (state, action) => {
      state.isLoading = false
      const response = action.payload
      state.myRequests.forEach(req => {
        if (req.id === response.id) {
          req.status = response.status
          req.cancelledAt = response.cancelledAt
        }
      })
      toast.success('Work request cancelled successfully.')
    })

    builder.addCase(signout.fulfilled, state => {
      state.isLoading = false
      state.statusFilter = 'All'
      state.requestType = CustomRequestsFilterLabel.MyRequests
      state.myRequests = [] as Array<UserWorkRequest>
      state.membersRequests = [] as Array<UserWorkRequest>
      state.selectedMember = null
      state.managerTeam = [] as Array<User>
    })
  },
})
export const {
  setLoadingAction,
  setStatusFilter,
  setRequestType,
  setSelectedMember,
  setMembersRequests,
  setMyRequests,
} = workRequestSlice.actions

export default workRequestSlice
