import SettingsIcon from '@mui/icons-material/Settings'
import { Typography, Container, Box, Tab, Tabs } from '@mui/material'
import { Role } from 'core/constants/enum'
import Path from 'core/navigation/path'
import EmailConfigurationForm from 'features/settings/components/EmailConfigurationForm'
import React, { SyntheticEvent, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import GazettedHolidays from '../components/GazettedHolidays'

const Settings = () => {
  const [value, setValue] = useState('gazetted')
  const { user } = useAppSelector(state => state.auth)
  return user?.role === Role.Admin ? (
    <Container sx={styles.root}>
      <Box>
        <Box alignItems="center" display="flex" gap={2}>
          <SettingsIcon color="action" fontSize="large" />
          <Typography variant="h4">Settings</Typography>
        </Box>
      </Box>
      <Tabs
        sx={styles.tabs}
        value={value}
        variant="fullWidth"
        onChange={(_event: SyntheticEvent, newValue) => {
          setValue(newValue)
        }}
      >
        <Tab label="Gazetted Holidays" sx={styles.tab} value="gazetted" />
        <Tab label="Email configuration" sx={styles.tab} value="config" />
      </Tabs>
      {value === 'config' && <EmailConfigurationForm />}
      {value === 'gazetted' && <GazettedHolidays />}
    </Container>
  ) : (
    <Navigate to={Path.Root} />
  )
}

export default Settings

const styles = {
  root: { mt: 2.5, p: 0 },
  tabs: { mt: 2, py: 1, px: 0, width: '350px' },
  tab: {
    px: 0.25,
  },
}
