import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Role } from 'core/constants/enum'
import { User } from 'core/service'
import { CreateOrUpdateUserSchema } from 'features/users-management/schema'
import {
  createUser,
  updateUser,
} from 'features/users-management/thunks/users-management'
import { Formik } from 'formik'
import { capitalize, orderBy } from 'lodash'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type CreateOrUpdateUserFormProps = {
  editedUserId: string
  editedInitialValues: User
  onClose: () => void
}

const CreateOrUpdateUserModal: React.FC<
  CreateOrUpdateUserFormProps
> = props => {
  const { editedUserId, editedInitialValues, onClose } = props
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { users } = useAppSelector(state => state.usersManagement)

  const filteredUsers = users.filter(
    user => user.role !== Role.Member && user?.isActive,
  )
  const orderedUsers = orderBy(filteredUsers, e => e.name.toLowerCase())

  const rolesOptions = Object.values(Role).map(value => value)

  const initialValues = {
    name: editedInitialValues.name ?? '',
    email: editedInitialValues.email ?? '',
    password: '',
    newPassword: '',
    role: editedInitialValues.role ?? '',
    managerId: editedInitialValues.managerId ?? '',
    isActive: editedInitialValues.isActive ?? true,
    userCanCreateWFHRequest:
      editedInitialValues?.userCanCreateWFHRequest ?? false,
    isUpdating: !!editedUserId,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CreateOrUpdateUserSchema}
      onSubmit={async values => {
        setLoading(true)
        editedUserId
          ? await dispatch(
              updateUser({
                uid: editedUserId,
                name: values.name,
                email: values.email,
                newPassword: values.newPassword,
                role: values.role,
                managerId: values.managerId,
                isActive: values.isActive,
                userCanCreateWFHRequest: values.userCanCreateWFHRequest,
              }),
            )
          : await dispatch(
              createUser({
                name: values.name,
                email: values.email,
                password: values.password,
                role: values.role.toLowerCase(),
                managerId: values.managerId,
                isActive: values.isActive,
                userCanCreateWFHRequest: values.userCanCreateWFHRequest,
              }),
            )
        setLoading(false)
        onClose()
      }}
    >
      {({
        values,
        setFieldValue,
        touched,
        errors,
        handleSubmit,
        handleChange,
      }) => (
        <Box
          noValidate
          component="form"
          sx={{ mt: '0.5rem' }}
          onSubmit={handleSubmit}
        >
          <Grid container>
            <Grid item display="flex" justifyContent="start" md={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isActive}
                      onChange={event => {
                        setFieldValue('isActive', event.target.checked)
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 500 }}>Is Active</Typography>
                  }
                  labelPlacement="start"
                  sx={{ ml: 0 }}
                />
              </FormGroup>
            </Grid>
            <Grid item display="flex" justifyContent="start" md={5}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.userCanCreateWFHRequest}
                      onChange={event => {
                        setFieldValue(
                          'userCanCreateWFHRequest',
                          event.target.checked,
                        )
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 500 }}>
                      Enable user to create WFH requests
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{ ml: 0 }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: '0rem' }}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={!!(touched.name && errors.name)}
                helperText={touched.name && errors.email ? errors.name : ''}
                label="Full Name"
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={!!(touched.email && errors.email)}
                helperText={touched.email && errors.email ? errors.email : ''}
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            {!editedUserId ? (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.password && errors.password)}
                  helperText={
                    touched.password && errors.password ? errors.password : ''
                  }
                  label="Password"
                  name="password"
                  type="text"
                  value={values.password}
                  onChange={handleChange}
                />
              </Grid>
            ) : (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.newPassword && errors.newPassword)}
                  helperText={
                    touched.newPassword && errors.newPassword
                      ? errors.newPassword
                      : ''
                  }
                  label="New Password"
                  name="newPassword"
                  type="text"
                  value={values.newPassword}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                select
                error={!!(touched.role && errors.role)}
                helperText={touched.role && errors.role ? errors.role : ''}
                label="Role"
                name="role"
                type="text"
                value={values.role}
                onChange={handleChange}
              >
                {rolesOptions.map(role => (
                  <MenuItem key={role} value={role}>
                    {capitalize(role)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                select
                error={!!(touched.managerId && errors.managerId)}
                helperText={
                  touched.managerId && errors.managerId ? errors.managerId : ''
                }
                label="Reports to"
                name="managerId"
                type="text"
                value={values.managerId}
                onChange={handleChange}
              >
                {orderedUsers.map(user => (
                  <MenuItem key={user.uid} value={user.uid}>
                    {capitalize(user.name)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box sx={styles.btnBox}>
            <Button
              color="inherit"
              disabled={loading}
              variant="text"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              disabled={loading}
              startIcon={loading && <CircularProgress size={18} />}
              type="submit"
              variant="contained"
            >
              {editedUserId ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export default CreateOrUpdateUserModal

const styles = {
  btnBox: {
    mt: '1.5rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
}
