import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  dateFormat,
  dateTimeFormat,
  LeaveRequestType,
  Role,
  WorkRequestStatus,
  WorkRequestType,
} from 'core/constants/enum'
import { ApproveOrRejectWorkRequestSchema } from 'features/work-request/schema'
import {
  approveOrRejectUserWorkRequest,
  cancelUserWorkRequest,
  getMedicalDocDownloadURL,
  sendEmailOnRequestApproval,
} from 'features/work-request/thunks/work-request'
import { EditedInitialValues } from 'features/work-request/types'
import { Formik } from 'formik'
import { capitalize } from 'lodash'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type ApproveOrRejectRequestFormProps = {
  editedRequestId: string | undefined
  editedInitialValues: EditedInitialValues
  onClose: () => void
}

const ApproveOrRejectRequestForm: React.FC<
  ApproveOrRejectRequestFormProps
> = props => {
  const { onClose, editedInitialValues, editedRequestId } = props
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const [loading, setLoading] = useState(false)

  return (
    <Box sx={{ mt: '1rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={styles.field}>
          <Typography sx={styles.label}>Requested By</Typography>
          <Typography>{editedInitialValues.requestedBy}</Typography>
        </Box>
        <Box sx={styles.field}>
          <Typography sx={styles.label}>Type</Typography>
          <Typography>
            {editedInitialValues.type}
            {editedInitialValues.type === WorkRequestType.Leave &&
              ` -  ${editedInitialValues.leaveType}`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={styles.field}>
          <Typography sx={styles.label}>From Date</Typography>
          <Typography>
            {moment(new Date(editedInitialValues.fromDate)).format(dateFormat)}
          </Typography>
        </Box>

        <Box sx={styles.field}>
          <Typography sx={styles.label}>To Date</Typography>
          <Typography>
            {moment(new Date(editedInitialValues.toDate)).format(dateFormat)}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.field}>
        <Typography sx={styles.label}>Reason</Typography>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          {editedInitialValues.reason}
        </Typography>
      </Box>

      {editedInitialValues.type === WorkRequestType.Leave &&
        editedInitialValues.leaveType === LeaveRequestType.Sick && (
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Medical Document</Typography>
            {!editedInitialValues.medicalDoc && (
              <Typography>The Attachment is not uploaded.</Typography>
            )}
            {editedInitialValues?.medicalDoc &&
              typeof editedInitialValues.medicalDoc === 'string' && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <Typography>{editedInitialValues.medicalDoc}</Typography>
                  <Tooltip title="Open file in new Tab">
                    <IconButton
                      size="small"
                      onClick={async () => {
                        const res = await dispatch(
                          getMedicalDocDownloadURL({
                            uid: editedInitialValues.userId
                              ? editedInitialValues.userId
                              : '',
                            fileName:
                              typeof editedInitialValues.medicalDoc === 'string'
                                ? editedInitialValues.medicalDoc
                                : '',
                          }),
                        )
                        const url = res.payload as string
                        window.open(url)
                      }}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Box>
        )}

      <Box sx={styles.field}>
        <Typography sx={styles.label}>Remarks</Typography>
        {editedInitialValues?.remarks?.length ? (
          editedInitialValues.remarks.map(
            ({ createdAt, remarks, remarksBy }) => {
              if (remarks)
                return (
                  <Box key={createdAt} sx={styles.remarks}>
                    <Typography variant="body2">
                      {moment(createdAt).format(dateTimeFormat)} -{' '}
                      {capitalize(remarksBy)}:
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
                      {remarks}
                    </Typography>
                  </Box>
                )
              return <></>
            },
          )
        ) : (
          <Typography variant="body2">N/A</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={styles.lastField}>
          <Typography sx={styles.label}>Status</Typography>
          <Typography>{editedInitialValues.status}</Typography>
        </Box>
        {editedInitialValues.status !== WorkRequestStatus.Open &&
          editedInitialValues.status !== WorkRequestStatus.Cancelled && (
            <Box sx={styles.lastField}>
              <Typography sx={styles.label}>
                {editedInitialValues.status} By
              </Typography>
              <Typography>{editedInitialValues.approvedBy}</Typography>
            </Box>
          )}
      </Box>

      {user?.uid === editedInitialValues.userId &&
        editedInitialValues?.status === WorkRequestStatus.Approved && (
          <Box sx={{ textAlign: 'right', marginTop: '1rem' }}>
            <Button
              color="error"
              disabled={loading}
              variant="contained"
              onClick={async () => {
                setLoading(true)
                if (!moment().isBefore(moment(editedInitialValues.fromDate))) {
                  toast.error('You can not cancel the work request now.')
                  setLoading(true)
                  onClose()
                  return
                }
                await dispatch(cancelUserWorkRequest(editedRequestId ?? ''))
                setLoading(false)
                onClose()
              }}
            >
              Cancel Work Request
            </Button>
          </Box>
        )}

      {(user?.role === Role.Manager &&
        editedInitialValues.status === WorkRequestStatus.Open) ||
      user?.role === Role.Admin ? (
        <Formik
          initialValues={{ requestStatus: '', remarks: '' }}
          validationSchema={ApproveOrRejectWorkRequestSchema}
          onSubmit={async values => {
            setLoading(true)
            let remarks = []
            if (
              values.requestStatus === WorkRequestStatus.Approved &&
              !values.remarks
            ) {
              remarks = [...(editedInitialValues?.remarks || [])]
            } else {
              remarks = [
                ...(editedInitialValues?.remarks || []),
                {
                  createdAt: Date.now(),
                  remarks: values.remarks.trim(),
                  remarksBy: user ? user?.name : '',
                },
              ]
            }

            await dispatch(
              approveOrRejectUserWorkRequest({
                uid: editedInitialValues?.userId
                  ? editedInitialValues?.userId
                  : '',
                userType: user?.role ? user?.role : '',
                docId: editedRequestId ? editedRequestId : '',
                status: values.requestStatus,
                remarks,
                approvedBy: user ? user?.name : '',
              }),
            )
            setLoading(false)
            onClose()
            const reqId = editedRequestId ? editedRequestId : ''
            const userId = editedInitialValues?.userId
              ? editedInitialValues?.userId
              : ''
            const role = user?.role ? user?.role : ''
            dispatch(
              sendEmailOnRequestApproval({
                reqId,
                userId,
                role,
              }),
            )
          }}
        >
          {({
            setFieldValue,
            values,
            touched,
            errors,
            handleSubmit,
            handleChange,
          }) => (
            <Box
              noValidate
              component="form"
              sx={{ mt: '1.5rem' }}
              onSubmit={handleSubmit}
            >
              <TextField
                fullWidth
                multiline
                error={!!(touched.remarks && errors.remarks)}
                helperText={
                  touched.remarks && errors.remarks ? errors.remarks : ''
                }
                id="remarks"
                label={
                  user?.role === Role.Admin
                    ? 'Admin Remarks'
                    : 'Supervisor Remarks'
                }
                rows={6}
                size="small"
                type="text"
                value={values.remarks}
                onChange={handleChange}
              />

              <Box sx={styles.btnBox}>
                <Button
                  color="warning"
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue('requestStatus', WorkRequestStatus.Open)
                  }}
                >
                  Return
                </Button>
                <Button
                  color="error"
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setFieldValue('requestStatus', WorkRequestStatus.Rejected)
                  }}
                >
                  Reject
                </Button>
                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    setFieldValue('requestStatus', WorkRequestStatus.Approved)
                  }
                >
                  Approve
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      ) : null}
    </Box>
  )
}

export default ApproveOrRejectRequestForm

const styles = {
  btnBox: {
    mt: '1rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
  label: { fontSize: '13.5px', fontWeight: 500, mb: '0.2rem' },
  field: {
    width: '100%',
    borderBottom: '1px solid #bdbdbd',
    marginBottom: '0.35rem',
  },
  lastField: {
    width: '100%',
    marginBottom: '0.35rem',
  },
  remarks: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    mb: '0.25rem',
  },
}
