import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import {
  dayInMillis,
  ErrorAlertTimeout,
  Status,
  SuccessAlertTimeout,
  UserStatus,
} from 'core/constants/enum'
import baseTheme from 'core/theme/base'
import {
  defaultEditActivityTimeError,
  setEditActivityTimeErrorAction,
} from 'features/dashboard/reducers/dashboard'
import { editTimeSchema } from 'features/dashboard/schema'
import {
  deleteUserActivity,
  deleteUserInterval,
  editUserActivity,
  editUserBrbActivity,
} from 'features/dashboard/thunks/dashboard'
import {
  checkForTimeConflictsInUserActivity,
  checkForTimeConflictsInUserInterval,
  timestampToDateTime,
  timestampToYMDFormat,
} from 'features/dashboard/utils/dashboard'
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type ActionProps = {
  action: string
  index: number
  time: number
}

export const UserActivityActions = (props: ActionProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState(false)
  const { user } = useAppSelector(state => state.auth)
  const { userActivity, isLoading } = useAppSelector(state => state.dashboard)
  const { editActivityTimeError, attendanceDate } = useAppSelector(
    state => state.dashboard,
  )

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const openModal = () => setIsEditModalOpen(true)
  const closeModal = () => {
    setIsEditModalOpen(false)
    dispatch(setEditActivityTimeErrorAction(defaultEditActivityTimeError))
  }

  if (editActivityTimeError.message && !isLoading) {
    setTimeout(
      () => {
        const status = editActivityTimeError.severity
        dispatch(setEditActivityTimeErrorAction(defaultEditActivityTimeError))
        if (status === Status.Success) closeModal()
      },
      editActivityTimeError.severity === Status.Error
        ? ErrorAlertTimeout
        : SuccessAlertTimeout,
    )
  }

  const handleDeleteUserActivity = async () => {
    setLoader(true)
    if (props.action === UserStatus.Brb) {
      await dispatch(
        deleteUserInterval({
          uid: user?.uid ?? '',
          index: props.index,
          docId: userActivity?.id ?? '',
          date: attendanceDate,
        }),
      )
    } else {
      await dispatch(
        deleteUserActivity({
          uid: user?.uid ?? '',
          activity: props.action,
          docId: userActivity?.id ?? '',
          date: attendanceDate,
        }),
      )
    }
    setLoader(false)
  }
  const handleSubmit = async (values: { time: string }) => {
    if (props.action === UserStatus.Brb) {
      const conflicts = checkForTimeConflictsInUserInterval(
        userActivity,
        moment(values.time).valueOf(),
        props.action,
        props.index,
      )
      dispatch(setEditActivityTimeErrorAction(conflicts))
      if (conflicts.severity === Status.Success) {
        await dispatch(
          editUserBrbActivity({
            uid: user?.uid ?? '',
            time: new Date(values.time).getTime(),
            index: props.index,
            date: attendanceDate,
            docId: userActivity?.id ?? '',
          }),
        )
      }
    } else {
      const conflicts = checkForTimeConflictsInUserActivity(
        userActivity,
        moment(values.time).valueOf(),
        props.action,
      )

      dispatch(setEditActivityTimeErrorAction(conflicts))

      if (conflicts.severity === Status.Success) {
        await dispatch(
          editUserActivity({
            uid: user?.uid ?? '',
            time: new Date(values.time).getTime(),
            activity: props.action,
            docId: userActivity?.id ?? '',
            date: attendanceDate,
          }),
        )
      }
    }
  }

  return (
    <Box sx={styles.actionBox}>
      <Tooltip title="Delete activity">
        <Box>
          <IconButton
            disabled={loader}
            sx={
              props.action === UserStatus.In
                ? { display: 'none' }
                : { '&:hover': { color: theme.palette.error.main } }
            }
            onClick={handleDeleteUserActivity}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Edit activity">
        <Box>
          <IconButton disabled={loader} sx={styles.editBtn} onClick={openModal}>
            <EditOutlined />
          </IconButton>
        </Box>
      </Tooltip>
      <Dialog maxWidth={'xs'} open={isEditModalOpen}>
        <DialogTitle component={'h2'} mb={3} variant="h6">
          Edit Activity
        </DialogTitle>
        <DialogContent>
          <Box>
            {!isLoading && editActivityTimeError.message && (
              <Alert
                severity={
                  editActivityTimeError.severity as
                    | Status.Success
                    | Status.Error
                }
                sx={styles.alert}
              >
                {editActivityTimeError.message}
              </Alert>
            )}
            <Formik
              initialValues={{
                time: timestampToDateTime(props.time),
              }}
              validationSchema={editTimeSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box sx={styles.editTime}>
                    <Typography
                      color="text.secondary"
                      mr={2}
                      variant="subtitle2"
                    >
                      Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        maxDate={
                          props.action === UserStatus.In
                            ? timestampToYMDFormat(attendanceDate)
                            : timestampToYMDFormat(attendanceDate + dayInMillis)
                        }
                        minDate={timestampToYMDFormat(attendanceDate)}
                        renderInput={params => (
                          <FormikTextField
                            name="time"
                            readonly={true}
                            {...params}
                          />
                        )}
                        value={values.time}
                        onChange={newValue => {
                          setFieldValue('time', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={styles.modalBtn}>
                    <Button
                      color="inherit"
                      disabled={isLoading}
                      variant="text"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={isLoading}
                      sx={styles.saveBtn}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export const styles = {
  actionBox: {
    ml: 'auto',
    display: 'flex',
  },
  editTime: {
    mb: 6,
    px: { xs: 1, sm: 5 },
    display: 'flex',
    alignItems: 'center',
  },
  modalBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  saveBtn: {
    ml: 1.5,
  },
  alert: {
    mb: 2,
  },
  editBtn: {
    '&:hover': { color: baseTheme.palette.primary.main },
  },
}
