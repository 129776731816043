import { firestore } from 'core/config/firebase'
import { Collection } from 'core/constants/collection'
import {
  dateFormat,
  dayAndDateFormat,
  ReportType,
  WorkLocation,
  WorkRequestStatus,
  WorkRequestType,
  YMDFormat,
} from 'core/constants/enum'
import {
  GazettedHoliday,
  Holiday,
  Report,
  TimesheetLog,
  User,
  UserActivityUpdated,
  UserLeave,
  UserTimesheetLog,
  UserWorkRequest,
} from 'core/service'
import {
  milliSecondsToHMFormat,
  milliSecondsToHours,
  timestampToHMFormat,
} from 'features/dashboard/utils/dashboard'
import { getBreakDuration } from 'features/members/utils/members'
import {
  calculateNoTFoundShifts,
  getUniqueObjectsWithinArray,
  getUpdatedReports,
  UUID,
} from 'features/reports/utils'
import {
  and,
  collection,
  doc,
  getDocs,
  or,
  orderBy,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, round, sortBy } from 'lodash'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import { toast } from 'react-toastify'
import ReportService from './index'
import { getTimesheetLogs } from '../thunks/reports'

// @ts-ignore
const Moment_Range = extendMoment(moment)

const getFormattedDateRange = (from: number, to: number) => {
  const filterRange = Moment_Range.range(moment(from), moment(to))
  return Array.from(filterRange.by('day')).map(date => date.format(YMDFormat))
}

const getKeyBasedTotalDuration = (
  userShifts: Report[],
  key: keyof Report,
): number =>
  userShifts.reduce((sum: number, obj: Report) => {
    const value: string | number | boolean = obj[key] ?? 0
    if (typeof value === 'number') {
      return sum + value
    }
    return sum
  }, 0)

const getFilteredBasedLeaveRequests = (
  requests: UserWorkRequest[],
  start: number,
  to: number,
): UserWorkRequest[] => {
  const startFilter = moment(start)
  const toFilter = moment(to)
  return filter(requests, request => {
    const fromDate = moment(request.fromDate)
    const toDate = moment(request.toDate)
    return (
      fromDate.isBetween(startFilter, toFilter, null, '[]') ||
      toDate.isBetween(startFilter, toFilter, null, '[]')
    )
  })
}

const getWeekendReports = (from: number, to: number) => {
  const filterRange = Moment_Range.range(moment(from), moment(to))
  const weekends = Array.from(filterRange.by('day')).filter(date => {
    const dayOfWeek = date.day()
    return dayOfWeek === 6 || dayOfWeek === 0
  })
  return weekends.map(date => ({
    date: date.format(dayAndDateFormat),
    isWeekend: true,
    shift: date.day() === 6 ? 'Saturday' : 'Sunday',
  }))
}

class Reports implements ReportService {
  async getMultipleUsersShifts(
    uids: string[],
    from: number,
    to: number,
    workLocation: string,
  ): Promise<Report[]> {
    const collRef = collection(firestore, Collection.Shifts)

    const shifts = [] as UserActivityUpdated[]
    const commonFromClause = where('shiftDate', '>=', from)
    const commonEndClause = where('shiftDate', '<=', to)
    const orderClause = orderBy('shiftDate', 'asc')
    if (uids.length < 11) {
      const userClause = where('userId', 'in', uids)
      const queryRef = query(
        collRef,
        userClause,
        commonFromClause,
        commonEndClause,
        orderClause,
      )
      const queryWithLocation = query(
        collRef,
        userClause,
        commonFromClause,
        commonEndClause,
        where('workLocation', '==', workLocation),
        orderClause,
      )
      const collSnap = await getDocs(
        workLocation !== 'All' ? queryWithLocation : queryRef,
      )
      const shiftsData = collSnap.docs.map(
        document =>
          ({ id: document.id, ...document.data() } as UserActivityUpdated),
      )
      shifts.push(...shiftsData)
    } else {
      const batchSize = 10
      const batchedUids = chunk(uids, batchSize)
      for (const batchUids of batchedUids) {
        const queryRef = query(
          collRef,
          commonFromClause,
          commonEndClause,
          where('userId', 'in', batchUids),
          orderClause,
        )
        const queryWithLocation = query(
          collRef,
          commonFromClause,
          commonEndClause,
          where('userId', 'in', batchUids),
          where('workLocation', '==', workLocation),
          orderClause,
        )
        const collSnap = await getDocs(
          workLocation !== 'All' ? queryWithLocation : queryRef,
        )
        const shiftsData = collSnap.docs.map(
          document =>
            ({ id: document.id, ...document.data() } as UserActivityUpdated),
        )
        shiftsData?.length && shifts.push(...shiftsData)
      }
    }

    const shiftReports = [] as Report[]

    shifts.forEach(activity => {
      let workDuration: string | number = '',
        workDurationInHrs = 0,
        totalDuration: string | number = '',
        shift = ''
      if (activity.start === 0) {
        workDuration = totalDuration = 'Work not Started'
        shift = 'Not Started'
      } else if (activity.start !== 0 && activity.end === 0) {
        workDuration = totalDuration = 'Work in Progress'
        shift = `${timestampToHMFormat(activity?.start ?? 0)} - Present`
      } else {
        const workInMilliseconds = (activity?.end ?? 0) - (activity?.start ?? 0)
        const breakInMilliseconds = getBreakDuration(activity?.intervals ?? [])
        workDuration = workInMilliseconds - breakInMilliseconds
        workDurationInHrs = workInMilliseconds - breakInMilliseconds
        totalDuration = workInMilliseconds
        shift = `${timestampToHMFormat(
          activity?.start ?? 0,
        )} - ${timestampToHMFormat(activity.end ?? 0)}`
      }
      shiftReports.push({
        date: moment(activity?.shiftDate).format(dayAndDateFormat),
        shift,
        wfh: activity.workLocation,
        workDuration,
        workDurationInHrs,
        breakDuration: getBreakDuration(activity?.intervals ?? []),
        totalDuration,
        shiftId: activity.id,
        userId: activity.userId,
      })
    })
    return shiftReports
  }

  async getMultipleUsersTimesheetLogs(
    uids: string[],
    from: number,
    to: number,
  ): Promise<Report[]> {
    const collRef = collection(firestore, Collection.TimesheetLogs)
    const timesheetLogs = [] as UserTimesheetLog[]

    const commonFromClause = where('logDate', '>=', from)
    const commonEndClause = where('logDate', '<=', to)
    if (uids.length < 11) {
      const userClause = where('userId', 'in', uids)
      const queryRef = query(
        collRef,
        userClause,
        commonFromClause,
        commonEndClause,
      )
      const collSnap = await getDocs(queryRef)
      const logsData = collSnap?.docs?.map(
        document =>
          ({ id: document.id, ...document.data() } as UserTimesheetLog),
      )
      logsData?.length && timesheetLogs.push(...logsData)
    } else {
      const batchSize = 10
      const batchedUids = chunk(uids, batchSize)
      for (const batchUids of batchedUids) {
        const queryRef = query(
          collRef,
          commonFromClause,
          commonEndClause,
          where('userId', 'in', batchUids),
        )
        const collSnap = await getDocs(queryRef)
        const logsData = collSnap.docs.map(
          document =>
            ({ id: document.id, ...document.data() } as UserTimesheetLog),
        )
        logsData?.length && timesheetLogs.push(...logsData)
      }
    }

    return timesheetLogs?.map(
      log =>
        ({
          date: moment(log?.logDate).format(dayAndDateFormat),
          devlogHours: round(log.totalHours, 2),
          leaveHours: round(log.leaveHours, 2),
          outageHours: round(log.outageHours, 2),
          logId: log.id,
          userId: log.userId,
        } as Report),
    )
  }

  async getGazettedHolidaysReports(
    from: number,
    to: number,
  ): Promise<Report[]> {
    const filterRange = Moment_Range.range(moment(from), moment(to))
    const dateRange = getFormattedDateRange(from, to)
    const gazettedHolidays = [] as GazettedHoliday[]
    const holidayColRef = collection(firestore, Collection.GazettedHolidays)
    if (dateRange?.length < 16) {
      const holidayQueryRef = query(
        holidayColRef,
        or(
          where('startDate', 'in', dateRange),
          where('endDate', 'in', dateRange),
        ),
      )
      const holidaysSnap = await getDocs(holidayQueryRef)
      const holidaysData = holidaysSnap.docs.map(
        holidayDoc =>
          ({
            id: holidayDoc.id,
            ...holidayDoc.data(),
          } as GazettedHoliday),
      )
      holidaysData?.length && gazettedHolidays.push(...holidaysData)
    } else {
      const batchSize = 15
      const batchedFilterDates = chunk(dateRange, batchSize)
      for (const batchFilterDates of batchedFilterDates) {
        const holidayQueryRef = query(
          holidayColRef,
          or(
            where('startDate', 'in', batchFilterDates),
            where('endDate', 'in', batchFilterDates),
          ),
        )
        const holidayCollSnap = await getDocs(holidayQueryRef)
        const holidaysData = holidayCollSnap?.docs.map(reqDocument => ({
          id: reqDocument.id,
          ...reqDocument.data(),
        })) as GazettedHoliday[]
        holidaysData?.length && gazettedHolidays.push(...holidaysData)
      }
    }

    const uniqueHolidays = getUniqueObjectsWithinArray(gazettedHolidays, 'id')
    const holidaysDates = [] as Holiday[]
    uniqueHolidays.forEach(holiday => {
      const range = Moment_Range.range(
        moment(holiday.startDate),
        moment(holiday.endDate),
      )
      const dates = Array.from(range.by('day')).map(date => ({
        date: date.format(YMDFormat),
        title: holiday.holidayTitle,
      }))
      holidaysDates.push(...dates)
    })

    const filteredHolidays = holidaysDates.filter(({ date }) =>
      filterRange.contains(moment(date)),
    )

    return filteredHolidays.map(({ date, title }) => ({
      date: moment(date).format(dayAndDateFormat),
      isHoliday: true,
      shift: title,
    }))
  }

  async getUserLeavesReports(
    uids: string[],
    from: number,
    to: number,
  ): Promise<Report[]> {
    const filterRange = Moment_Range.range(moment(from), moment(to))
    const collRef = collection(firestore, Collection.WorkRequests)
    const leaveRequests = [] as UserWorkRequest[]
    if (uids?.length < 11) {
      const reqQueryRef = query(
        collRef,
        and(
          where('userId', 'in', uids),
          where('type', '==', WorkRequestType.Leave),
          or(
            where('status', '==', WorkRequestStatus.Approved),
            where('status', '==', WorkRequestStatus.Open),
          ),
        ),
      )
      const collSnap = await getDocs(reqQueryRef)
      const data = collSnap?.docs.map(reqDoc => ({
        id: reqDoc.id,
        ...reqDoc.data(),
      })) as UserWorkRequest[]
      data?.length && leaveRequests.push(...data)
    } else {
      const batchSize = 10
      const batchedUids = chunk(uids, batchSize)
      for (const batchUids of batchedUids) {
        const reqQueryRef = query(
          collRef,
          and(
            where('userId', 'in', batchUids),
            where('type', '==', WorkRequestType.Leave),
            or(
              where('status', '==', WorkRequestStatus.Approved),
              where('status', '==', WorkRequestStatus.Open),
            ),
          ),
        )
        const collSnap = await getDocs(reqQueryRef)
        const data = collSnap?.docs.map(reqDoc => ({
          id: reqDoc.id,
          ...reqDoc.data(),
        })) as UserWorkRequest[]
        data?.length && leaveRequests.push(...data)
      }
    }

    const leaves = getFilteredBasedLeaveRequests(leaveRequests, from, to)
    const uniqueRequests = getUniqueObjectsWithinArray(leaves, 'id')
    const userLeaves = [] as UserLeave[]
    uniqueRequests.forEach(req => {
      const range = Moment_Range.range(moment(req.fromDate), moment(req.toDate))
      const leaveDatesPerUser = Array.from(range.by('day')).map(date => ({
        date: date.format(dateFormat),
        leaveType: req.leaveType,
        status: req.status,
        userId: req.userId,
      }))
      userLeaves.push(...leaveDatesPerUser)
    })
    const filteredLeaves = userLeaves?.filter(({ date }) =>
      filterRange.contains(moment(date)),
    )
    return filteredLeaves.map(({ date, status, userId }) => ({
      date: moment(date).format(dayAndDateFormat),
      isLeave: true,
      isLeavePending: status === WorkRequestStatus.Open,
      shift: `${
        status === WorkRequestStatus.Open
          ? 'Leave - Pending For Approval'
          : 'On Leave'
      } `,
      userId,
    }))
  }
  async getReports(
    uids: string[],
    fromDate: number,
    toDate: number,
    reportType: string,
    workLocation: string,
  ): Promise<Array<Report>> {
    const userCollRef = query(
      collection(firestore, 'users'),
      where('isActive', '==', true),
      orderBy('name', 'asc'),
    )
    const usersSnap = await getDocs(userCollRef)
    const users = usersSnap.docs
      .filter(user => uids.indexOf(user.id) > -1)
      .map(user => ({ uid: user.id, ...user.data() } as User))

    const filterRange = Moment_Range.range(moment(fromDate), moment(toDate))
    const weekOffDates = getWeekendReports(fromDate, toDate)
    const holidaysReports = await this.getGazettedHolidaysReports(
      fromDate,
      toDate,
    )
    const shifts = await this.getMultipleUsersShifts(
      uids,
      fromDate,
      toDate,
      workLocation,
    )
    const leaves = await this.getUserLeavesReports(uids, fromDate, toDate)
    const timesheetLogs = await this.getMultipleUsersTimesheetLogs(
      uids,
      fromDate,
      toDate,
    )

    let userReports = [] as Report[]

    users.forEach(user => {
      let userReport = [] as Report[]
      const userShifts = shifts
        .filter(shift => shift.userId === user.uid)
        .map(shift => ({ name: user.name, ...shift }))

      const userLeaves = leaves
        .filter(leave => leave.userId === user.uid)
        .map(leave => ({ name: user.name, ...leave }))

      const userTimesheetLogs = timesheetLogs
        .filter(log => log.userId === user.uid)
        .map(log => ({ name: user.name, ...log }))

      userShifts.forEach(shift => {
        userTimesheetLogs.forEach(devlog => {
          if (shift.date === devlog.date) {
            shift.devlogHours = devlog.devlogHours
            shift.leaveHours = devlog.leaveHours
            shift.outageHours = devlog.outageHours
            shift.netDevlogHrs =
              (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0)
            shift.deviationHours = round(
              (devlog?.devlogHours ?? 0) -
                (devlog?.leaveHours ?? 0) -
                milliSecondsToHours(shift?.workDurationInHrs ?? 0),
              2,
            )
          }
        })
      })

      userLeaves.forEach(leave => {
        userTimesheetLogs.forEach(devlog => {
          if (leave.date === devlog.date) {
            leave.devlogHours = devlog.devlogHours
            leave.leaveHours = devlog.leaveHours
            leave.outageHours = devlog.outageHours
            leave.netDevlogHrs =
              (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0)
            leave.deviationHours = round(
              (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0),
              2,
            )
          }
        })
      })
      userReport.push(...userShifts)

      if (reportType === ReportType.User) {
        const datesToExclude = userReport.map(obj =>
          moment(obj.date).format(dayAndDateFormat),
        )
        const updatedWeekendDates = weekOffDates
          .filter(({ date }) => !datesToExclude.includes(date))
          .map(weekend => ({ name: user.name, ...weekend }))
        const updatedHolidaysReports = holidaysReports
          .filter(
            ({ date }) =>
              ![
                ...datesToExclude,
                ...updatedWeekendDates.map(weekend => weekend.date),
              ].includes(date),
          )
          .map(holiday => ({ name: user.name, ...holiday }))
        const updatedLeaveReports = userLeaves.filter(
          ({ date }) => !datesToExclude.includes(date),
        )

        const noShifts: Report[] = calculateNoTFoundShifts(
          [
            ...updatedWeekendDates,
            ...updatedHolidaysReports,
            ...userReport,
            ...updatedLeaveReports,
          ],
          filterRange,
          user.name,
        )

        noShifts.forEach(noShift => {
          userTimesheetLogs.forEach(devlog => {
            if (noShift.date === devlog.date) {
              noShift.devlogHours = devlog.devlogHours
              noShift.leaveHours = devlog.leaveHours
              noShift.outageHours = devlog.outageHours
              noShift.netDevlogHrs =
                (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0)
              noShift.deviationHours = round(
                (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0),
                2,
              )
            }
          })
        })

        userReport = sortBy(
          [
            ...noShifts,
            ...userReport,
            ...updatedLeaveReports,
            ...updatedWeekendDates,
            ...updatedHolidaysReports,
          ],
          obj => moment(obj.date),
        )
        const wfoCount = userReport.filter(
          report => report.wfh === WorkLocation.Office,
        ).length
        const wfhCount = userReport.filter(
          report => report.wfh === WorkLocation.Home,
        ).length

        const WorkDuration = getKeyBasedTotalDuration(
          userShifts,
          'workDuration',
        )

        const TotalBreak = getKeyBasedTotalDuration(userShifts, 'breakDuration')
        const TotalDuration = getKeyBasedTotalDuration(
          userShifts,
          'totalDuration',
        )
        const TotalDevLogHours = getKeyBasedTotalDuration(
          [...userShifts, ...userLeaves],
          'devlogHours',
        )
        const TotalLeaveHours = getKeyBasedTotalDuration(
          [...userShifts, ...userLeaves],
          'leaveHours',
        )
        const TotalOutageHours = getKeyBasedTotalDuration(
          [...userShifts, ...userLeaves],
          'outageHours',
        )
        const TotalDeviationHrs = getKeyBasedTotalDuration(
          [...userShifts, ...userLeaves],
          'deviationHours',
        )

        userReport.push({
          date: '',
          name: '',
          shift: `WFO Count: ${wfoCount} -- WFH Count: ${wfhCount} -- Leave Count: ${userLeaves.length}`,
          wfh: '',
          workDuration: milliSecondsToHMFormat(WorkDuration),
          workDurationInHrs: WorkDuration ?? 0,
          breakDuration: milliSecondsToHMFormat(TotalBreak) ?? '',
          totalDuration: milliSecondsToHMFormat(TotalDuration) ?? '',
          devlogHours: round(TotalDevLogHours, 2),
          leaveHours: round(TotalLeaveHours, 2),
          outageHours: round(TotalOutageHours, 2),
          netDevlogHrs: round(TotalDevLogHours - TotalLeaveHours, 2),
          deviationHours: round(TotalDeviationHrs, 2),
        })
      } else {
        const noShifts: Report[] = calculateNoTFoundShifts(
          [...weekOffDates, ...userLeaves, ...userReport, ...holidaysReports],
          filterRange,
          user.name,
        )
        noShifts.forEach(noShift => {
          userTimesheetLogs.forEach(devlog => {
            if (noShift.date === devlog.date) {
              noShift.devlogHours = devlog.devlogHours
              noShift.leaveHours = devlog.leaveHours
              noShift.outageHours = devlog.outageHours
              noShift.netDevlogHrs =
                (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0)
              noShift.deviationHours = round(
                (devlog?.devlogHours ?? 0) - (devlog?.leaveHours ?? 0),
                2,
              )
            }
          })
        })
        userReport.push(...noShifts, ...userLeaves)
      }
      userReports = userReports.concat(userReport)
    })

    if (reportType === ReportType.Date) {
      const weekends = weekOffDates.map(weekend => weekend.date)
      const updatedHolidaysReports = holidaysReports.filter(
        ({ date }) => !weekends.includes(date),
      )

      userReports = sortBy(
        [...weekOffDates, ...updatedHolidaysReports, ...userReports],
        [obj => moment(obj.date), 'name'],
      )
    }
    return getUpdatedReports(userReports)
  }

  async importTimesheetData(startDate: string, endDate: string): Promise<void> {
    const timesheetData = await getTimesheetLogs(startDate, endDate)
    if (!timesheetData?.length) {
      toast.error('No data found for selected date.')
      return
    }

    const userCollRef = query(
      collection(firestore, 'users'),
      where('isActive', '==', true),
    )
    const usersSnap = await getDocs(userCollRef)
    const users = usersSnap.docs.map(
      row => ({ uid: row.id, ...row.data() } as User),
    )

    const UsersMap = new Map()
    users.forEach(user => {
      UsersMap.set(user.email, user.uid)
    })
    const uids = users.map(user => user.uid)

    const timesheetLogs = []
    const batchedUids = chunk(uids, 10)
    for (const batchUids of batchedUids) {
      const timesheetLogsQuery = query(
        collection(firestore, 'timesheet-logs'),
        where('logDate', '>=', moment(startDate, YMDFormat).valueOf()),
        where('logDate', '<=', moment(endDate, YMDFormat).valueOf()),
        where('userId', 'in', batchUids),
      )
      const timesheetLogsSnap = await getDocs(timesheetLogsQuery)
      const logsData = timesheetLogsSnap.docs.map(
        document =>
          ({
            id: document.id,
            ...document.data(),
          } as TimesheetLog),
      )
      logsData?.length && timesheetLogs.push(...logsData)
    }

    const LogsUserMap = new Map()
    timesheetLogs.forEach(log => {
      LogsUserMap.set(`${log.userId}___${log.logDate}`, log.id)
    })

    const updatedLogsData = timesheetData.map(row => ({
      logDate: new Date(row.TS_date).getTime(),
      outageHours: row.outageHours ?? 0,
      leaveHours: row.leaveHours ?? 0,
      totalHours:
        (row.projectHours ?? 0) +
        (row.outageHours ?? 0) +
        (row.leaveHours ?? 0),
      userEmail: row.employeeEmail,
      userId: UsersMap.get(row.employeeEmail) ?? '',
    }))

    const batchSize = 500
    const dataChunks = chunk(updatedLogsData, batchSize)

    const promises = dataChunks.map(dataChunk => {
      const batch = writeBatch(firestore)
      dataChunk.forEach(row => {
        const idToSearchInMap = `${row.userId}___${row.logDate}`
        if (LogsUserMap.get(idToSearchInMap)) {
          const docId = LogsUserMap.get(idToSearchInMap)
          const docRef = doc(firestore, 'timesheet-logs', docId)
          batch.update(docRef, row)
        } else {
          const docRef = doc(firestore, `timesheet-logs`, UUID())
          batch.set(docRef, row)
        }
      })
      return batch.commit()
    })
    await Promise.all(promises)
    toast.success('Data imported successfully.')
  }
}

export const reportService = new Reports()
