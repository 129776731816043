import { createSlice } from '@reduxjs/toolkit'
import { User } from 'core/service'
import {
  createUser,
  getAllUsers,
  updateUser,
} from 'features/users-management/thunks/users-management'
import { UsersManagementState } from 'features/users-management/types'
import { orderBy } from 'lodash'
import { toast } from 'react-toastify'

const initialState = {
  isLoading: true,
  users: [],
} as UsersManagementState

const UsersManagementSlice = createSlice({
  name: 'UsersManagementSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.users.push(action.payload.data)
      state.users = orderBy(state.users, e => e.name.toLowerCase())
      state.isLoading = false
      toast.success('User created successfully.')
    })
    builder.addCase(createUser.rejected, state => {
      state.isLoading = false
      toast.error('Something went wrong, try again.')
    })

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const updatedUser = action.payload.data as User
      state.users.map(user => {
        if (user.uid === updatedUser.uid) {
          user.name = updatedUser.name
          user.email = updatedUser.email
          user.role = updatedUser.role
          user.managerId = updatedUser.managerId
          user.isActive = updatedUser.isActive
          user.managerId = updatedUser.managerId
          user.userCanCreateWFHRequest = updatedUser.userCanCreateWFHRequest
        }
        return true
      })
      state.users = orderBy(state.users, e => e.name.toLowerCase())
      state.isLoading = false
      toast.success('User updated successfully.')
    })

    builder.addCase(updateUser.rejected, state => {
      state.isLoading = false
      toast.error('Something went wrong, try again.')
    })

    builder.addCase(getAllUsers.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.isLoading = false
      state.users = action.payload
      state.users = orderBy(state.users, e => e.name.toLowerCase())
    })
    builder.addCase(getAllUsers.rejected, state => {
      state.isLoading = false
    })
  },
})

export default UsersManagementSlice
