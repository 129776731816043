import { createAsyncThunk } from '@reduxjs/toolkit'
import { service } from 'core/service/firebase'
import { editMemberActivityParams } from 'features/dashboard/service'
import { dashboardService } from 'features/dashboard/service/service'

export const editProfile = createAsyncThunk(
  'members/editProfile',
  async (
    values: {
      uid: string
      name: string
      phone: string
      workingHoursStart: string
      workingHoursEnd: string
    },
    { rejectWithValue },
  ) => {
    try {
      return await service.editProfile(
        values.uid,
        values.name,
        values.phone,
        values.workingHoursStart,
        values.workingHoursEnd,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editProfileImage = createAsyncThunk(
  'members/editProfileImage',
  (values: { uid: string; file: File }, { rejectWithValue }) => {
    try {
      return service.editProfileImage(values.uid, values.file)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteProfileImage = createAsyncThunk(
  'members/deleteProfileImage',
  (values: { uid: string; fileName: string }, { rejectWithValue }) => {
    try {
      return service.deleteProfileImage(values.uid, values.fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchMemberAttendance = createAsyncThunk(
  'members/fetchMemberAttendance',
  (
    {
      uid,
      fromDate,
      toDate,
    }: { uid: string; fromDate: number; toDate: number },
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.getMemberShifts({ uid, fromDate, toDate })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editMemberActivity = createAsyncThunk(
  'members/editMemberActivity',
  (
    {
      role,
      start,
      end,
      workLocation,
      interval,
      docId,
    }: editMemberActivityParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.editMemberActivity({
        role,
        start,
        end,
        workLocation,
        interval,
        docId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
