/* eslint-disable */
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, onMessage, MessagePayload } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'

const firebaseApiKey =
  process.env.REACT_APP_apiKey === undefined
    ? 'api-key'
    : process.env.REACT_APP_apiKey

export const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
}

const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)

export const storage = getStorage(app)

export const auth = getAuth(app)

export const messaging = getMessaging(app)

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, (payload: MessagePayload) => {
      resolve(payload)
    })
  })
