import { checkLeadingAndTrailingSpaces } from 'features/users-management/utils/users-management'
import { string, object } from 'yup'

export const CreateOrUpdateUserSchema = object().shape({
  name: string().trim().label('Full Name').required(),
  email: string().email().label('Email').required(),
  password: string().when('isUpdating', {
    is: true,
    then: string().label('Password').nullable(),
    otherwise: string()
      .min(8)
      .label('Password')
      .required()
      .test(
        'checkEditPass',
        'Password must be at least 8 characters',
        value => !(!value || value.length < 8),
      )
      .test(
        'checkEditPass',
        'Password cannot contain leading/trailing spaces',
        value => !(!value || checkLeadingAndTrailingSpaces(value)),
      ),
  }),
  newPassword: string()
    .label('New Password')
    .test(
      'checkEditPass',
      'New Password must be at least 8 characters',
      value => !(value && value.length < 8),
    )
    .test(
      'checkEditPass',
      'New Password cannot contain leading/trailing spaces',
      value => !(value && checkLeadingAndTrailingSpaces(value)),
    ),
  role: string().label('Role').required(),
  managerId: string().label('Reports to').required(),
})
