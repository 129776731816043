import { defaultUsername } from 'core/constants/enum'
import { Interval } from 'core/service'
import { first, last } from 'lodash'
import * as yup from 'yup'
import { parsedDate } from './utils/members'

export const EditUserActivitySchema = (intervals: Interval[]) =>
  yup.object().shape({
    workLocation: yup.string().oneOf(['Home', 'Office']).required(),
    inTime: yup
      .string()
      .required('In time is required')
      .test(
        'test',
        "In Time shouldn't be greater than current datetime",
        value => parsedDate(value ?? '') <= new Date().getTime(),
      )
      .test(
        'Break Conflict',
        'In Time is conflicting with a break duration',
        value =>
          intervals.length
            ? parsedDate(value ?? '') <= (first(intervals)?.start ?? 0)
            : true,
      ),
    outTime: yup
      .string()
      .test(
        'test',
        "Out Time shouldn't be greater than current datetime",
        value => (value ? parsedDate(value) <= new Date().getTime() : true),
      )
      .test(
        'In time Conflict',
        'Out Time must be greater than In Time',
        (value, context) => {
          const start = context.parent.inTime
          return value ? parsedDate(value ?? '') > parsedDate(start) : true
        },
      )
      .test(
        'Break Conflict',
        'Out Time is conflicting with a break duration',
        (value, context) => {
          if (value && intervals.length && last(intervals)?.end !== 0) {
            return parsedDate(value ?? '') > (last(intervals)?.end ?? 0)
          } else if (value && intervals.length && last(intervals)?.end === 0) {
            return (
              parsedDate(value ?? '') > parsedDate(context.parent.breakEndTime)
            )
          }
          return true
        },
      ),
    isPendingInterval: yup.boolean().required(),
    breakEndTime: yup.string().when('isPendingInterval', {
      is: true,
      then: yup
        .string()
        .required('Break End Time is required')
        .test(
          'test',
          'Break End Time must be less than current datetime',
          value => parsedDate(value ?? '') <= new Date().getTime(),
        )
        .test(
          'break Conflict',
          'Break End Time must be greater than Break Start Time',
          (value, context) =>
            parsedDate(value ?? '') > parsedDate(context.parent.breakStartTime),
        ),
      otherwise: yup.string(),
    }),
  })

const editProfileSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(30, 'Name cannot be greater than 30 characters')
    .test(
      'test',
      'This name is not allowed',
      value => value !== defaultUsername,
    ),
  phone: yup
    .string()
    .matches(
      /^((\+92)?(0)?)(3)(([0-9]{9})?([0-9]{2}(( )?)[0-9]{7})?)$/,
      'Phone number is invalid. Try format: +923xxxxxxxxx or 03xxxxxxxxx or 03xx xxxxxxx',
    ),
  workingHoursStart: yup.string(),
  workingHoursEnd: yup
    .string()
    .test(
      'test',
      'Ending time must be greater than starting time',
      (value, context) => {
        const data = value ? value.split(':') : ''
        if (!context.parent.workingHoursStart) return true
        const startValue = context.parent.workingHoursStart
          ? context.parent.workingHoursStart.split(':')
          : ''
        return (
          new Date(
            new Date().setHours(
              Number.parseInt(data[0]),
              Number.parseInt(data[1]),
              0,
              0,
            ),
          ).getTime() >
          new Date(
            new Date().setHours(
              Number.parseInt(startValue[0]),
              Number.parseInt(startValue[1]),
              0,
              0,
            ),
          ).getTime()
        )
      },
    ),
})

export default editProfileSchema
