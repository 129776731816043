import { Add } from '@mui/icons-material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Container, Box, Tabs, Tab, Typography, Button } from '@mui/material'
import WorkRequestIcon from 'core/assets/work-request-28x28.svg'
import colors from 'core/constants/color'
import {
  LeaveRequestType,
  RequestType,
  Role,
  WorkRequestType,
} from 'core/constants/enum'
import Path from 'core/navigation/path'
import ApproveOrRejectRequestForm from 'features/work-request/components/ApproveOrRejectRequestForm'
import CreateOrUpdateRequestForm from 'features/work-request/components/CreateOrUpdateRequestForm'
import MyRequestsTable from 'features/work-request/components/MyRequestsTable'
import RequestModal from 'features/work-request/components/RequestModal'
import WorkRequestsTable from 'features/work-request/components/WorkRequestsTable'
import { EditedInitialValues } from 'features/work-request/types'
import React, { SyntheticEvent, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import CreateMemberLeaveRequestForm from '../components/CreateMemberLeaveRequestForm'

const initialValues: EditedInitialValues = {
  id: '',
  fromDate: '',
  toDate: '',
  reason: '',
  createdAt: 0,
  type: WorkRequestType.WFH,
  leaveType: LeaveRequestType.Annual,
  medicalDoc: null,
  remarks: [],
  requestedBy: '',
  userId: '',
}
const WorkRequest = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get('type')
  const [value, setValue] = useState(searchParams.get('type'))

  const [openWFHRequestModal, setOpenWFHRequestModal] = useState(false)
  const [openLeaveRequestModal, setOpenLeaveRequestModal] = useState(false)

  const [openLeaveModal, setOpenLeaveModal] = useState(false)
  const [openApprovalModal, setOpenApprovalModal] = useState(false)
  const [editId, setEditId] = useState<string | undefined>('')
  const [initValues, setInitValues] =
    useState<EditedInitialValues>(initialValues)
  const { user } = useAppSelector(state => state.auth)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
    searchParams.set('type', newValue)
    setSearchParams(searchParams)
  }

  const handleOpenWFHRequestModal = () => setOpenWFHRequestModal(true)
  const handleCloseWFHRequestModal = () => {
    setEditId('')
    setOpenWFHRequestModal(false)
    setInitValues(initialValues)
  }
  const handleOpenLeaveRequestModal = () => setOpenLeaveRequestModal(true)
  const handleCloseLeaveRequestModal = () => {
    setEditId('')
    setOpenLeaveRequestModal(false)
    setInitValues(initialValues)
  }

  const handleOpenLeaveModal = () => {
    setOpenLeaveModal(true)
  }
  const handleCloseLeaveModal = () => {
    setOpenLeaveModal(false)
  }

  const handleOpenApprovalModal = (values: EditedInitialValues) => {
    setOpenApprovalModal(true)
    setInitValues(values)
    setEditId(values.id)
  }

  const handleCloseApprovalModal = () => {
    setOpenApprovalModal(false)
    setEditId('')
    if (!openApprovalModal) {
      setInitValues(initialValues)
    }
  }

  const handleOpenModalForEdit = (values: EditedInitialValues) => {
    if (values.type === WorkRequestType.WFH) {
      setOpenWFHRequestModal(true)
    } else setOpenLeaveRequestModal(true)
    setInitValues(values)
    setEditId(values.id)
  }

  return (
    <Container sx={styles.root}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box alignItems="center" display="flex" gap={2}>
            <img
              alt="work-request-icon"
              height="28px"
              src={WorkRequestIcon}
              width="28px"
            />
            <Typography variant="h4">Work Requests</Typography>
          </Box>
        </Box>
        <Box sx={styles.btnGroup}>
          {type === RequestType.TeamRequests && user?.role === Role.Admin ? (
            <Button
              color="error"
              variant="contained"
              onClick={handleOpenLeaveModal}
            >
              Member Leave Request
            </Button>
          ) : (
            <>
              {user?.userCanCreateWFHRequest && (
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  onClick={handleOpenWFHRequestModal}
                >
                  New WFH Request
                </Button>
              )}
              <Button
                color="error"
                startIcon={<Add />}
                variant="contained"
                onClick={handleOpenLeaveRequestModal}
              >
                New Leave Request
              </Button>
            </>
          )}
          <Button
            startIcon={<CalendarMonthIcon />}
            sx={styles.calendarBtn}
            variant="contained"
            onClick={() => navigate(Path.CalendarView)}
          >
            Calendar View
          </Button>
        </Box>
      </Box>
      <RequestModal
        Component={
          <CreateOrUpdateRequestForm
            editable={!!editId}
            editedInitialValues={initValues}
            editedRequestId={editId}
            type={WorkRequestType.WFH}
            onClose={handleCloseWFHRequestModal}
          />
        }
        open={openWFHRequestModal}
        title={editId ? 'Edit WFH Request' : 'Create WFH Request'}
        onClose={handleCloseWFHRequestModal}
      />
      <RequestModal
        Component={
          <CreateOrUpdateRequestForm
            editable={!!editId}
            editedInitialValues={initValues}
            editedRequestId={editId}
            type={WorkRequestType.Leave}
            onClose={handleCloseLeaveRequestModal}
          />
        }
        open={openLeaveRequestModal}
        title={editId ? 'Edit Leave Request' : 'Create Leave Request'}
        onClose={handleCloseLeaveRequestModal}
      />
      <RequestModal
        Component={
          <CreateMemberLeaveRequestForm onClose={handleCloseLeaveModal} />
        }
        open={openLeaveModal}
        title="Create Member Leave Request"
        onClose={handleCloseLeaveModal}
      />
      <RequestModal
        Component={
          <ApproveOrRejectRequestForm
            editedInitialValues={initValues}
            editedRequestId={editId}
            onClose={handleCloseApprovalModal}
          />
        }
        open={openApprovalModal}
        title={`Work Request`}
        onClose={handleCloseApprovalModal}
      />
      {user?.role !== Role.Member ? (
        <Box>
          <Tabs
            sx={styles.tabs}
            value={value}
            variant="fullWidth"
            onChange={handleChange}
          >
            <Tab
              label="My Requests"
              sx={styles.tab}
              value={RequestType.MyRequests}
            />

            <Tab
              label="Team Requests"
              sx={styles.tab}
              value={RequestType.TeamRequests}
            />
          </Tabs>

          {value === RequestType.MyRequests && (
            <MyRequestsTable
              openApprovalModal={handleOpenApprovalModal}
              openEditModal={handleOpenModalForEdit}
            />
          )}
          {value === RequestType.TeamRequests && (
            <WorkRequestsTable
              openApprovalModal={handleOpenApprovalModal}
              openEditModal={handleOpenModalForEdit}
            />
          )}
        </Box>
      ) : (
        <MyRequestsTable
          openApprovalModal={handleOpenApprovalModal}
          openEditModal={handleOpenModalForEdit}
        />
      )}
    </Container>
  )
}

const styles = {
  root: { mt: 2.5, p: 0 },
  tabs: { py: 1, px: 0, width: '300px' },
  tab: {
    maxWidth: '9rem',
    px: 0.25,
  },
  btnGroup: {
    display: 'flex',
    gap: '0.9rem',
    alignItems: 'center',
  },
  calendarBtn: {
    color: '#424242',
    backgroundColor: colors.yellow,
    '&:hover': {
      backgroundColor: colors.darkYellow,
    },
  },
}

export default WorkRequest
