import * as yup from 'yup'

export const reportSchema = yup.object().shape({
  startDate: yup
    .date()
    .required('Start date is required')
    .test(
      'test',
      "Start date shouldn't be greater than current date",
      value =>
        new Date(value?.toString() || '').getTime() <=
        new Date().setHours(0, 0, 0, 0),
    ),
  endDate: yup
    .date()
    .required('End date is required')
    .test(
      'test',
      "End date shouldn't be greater than current date",
      value =>
        new Date(value?.toString() || '').getTime() <=
        new Date().setHours(0, 0, 0, 0),
    )
    .test(
      'less than start time',
      'End date must be greater than or equal to start date',
      (value, context) => {
        const startDate = context.parent.startDate
        return (
          new Date(startDate).setHours(0, 0, 0, 0) <=
          new Date(value || '').setHours(0, 0, 0, 0)
        )
      },
    ),
})
