import { createAsyncThunk } from '@reduxjs/toolkit'

import { service } from 'core/service/firebase'

export const saveEmailConfigSetting = createAsyncThunk(
  'settings/saveEmailConfigSetting',
  async (
    {
      docId,
      smtpEmail,
      smtpPassword,
      smtpServer,
      smtpPort,
      emailRecipients,
    }: {
      docId: string
      smtpEmail: string
      smtpPassword: string
      smtpServer: string
      smtpPort: number | string
      emailRecipients: string[]
    },
    { rejectWithValue },
  ) => {
    try {
      return await service.saveEmailConfigSetting(
        docId,
        smtpEmail,
        smtpPassword,
        smtpServer,
        smtpPort,
        emailRecipients,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getEmailConfigSetting = createAsyncThunk(
  'settings/getEmailConfigSetting',
  async (userType: string, { rejectWithValue }) => {
    try {
      return await service.getEmailConfigSetting(userType)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const addGazettedHoliday = createAsyncThunk(
  'settings/addGazettedHoliday',
  (
    {
      startDate,
      endDate,
      holidayTitle,
    }: { startDate: string; endDate: string; holidayTitle: string },
    { rejectWithValue },
  ) => {
    try {
      return service.addGazettedHoliday(startDate, endDate, holidayTitle)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const updateGazettedHoliday = createAsyncThunk(
  'settings/updateGazettedHoliday',
  (
    {
      id,
      startDate,
      endDate,
      holidayTitle,
    }: { id: string; startDate: string; endDate: string; holidayTitle: string },
    { rejectWithValue },
  ) => {
    try {
      return service.updateGazettedHoliday(id, startDate, endDate, holidayTitle)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteGazettedHoliday = createAsyncThunk(
  'settings/deleteGazettedHoliday',
  (id: string, { rejectWithValue }) => {
    try {
      return service.deleteGazettedHoliday(id)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const getGazettedHolidays = createAsyncThunk(
  'settings/getGazettedHolidays',
  (_, { rejectWithValue }) => {
    try {
      return service.getGazettedHolidays()
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
