import { Box, Container, Drawer, Toolbar } from '@mui/material'
import { Sidebar } from 'core/components/Sidebar'
import { drawerWidth, Topbar } from 'core/components/Topbar'
import { setMobileDrawerOpen } from 'features/app/reducers/app'
import React, { ReactElement, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

interface DrawerProps {
  selected: string
  children: ReactElement
}

const Template = ({ selected, children }: DrawerProps) => {
  const dispatch = useAppDispatch()
  const { mobileDrawerOpen } = useAppSelector(state => state.app)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 600 && mobileDrawerOpen)
        dispatch(setMobileDrawerOpen(false))
    }
    window.addEventListener('resize', handleResize)
  })

  return (
    <Container maxWidth="xl">
      <Topbar />
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        open={mobileDrawerOpen}
        sx={styles.mobileDrawer}
        variant="temporary"
        onClose={() => dispatch(setMobileDrawerOpen(false))}
      >
        <Sidebar selected={selected} />
      </Drawer>
      <Drawer open sx={styles.fullDrawer} variant="permanent">
        <Sidebar selected={selected} />
      </Drawer>
      <Box component="main" sx={styles.main}>
        <Toolbar />
        {children}
      </Box>
    </Container>
  )
}

const styles = {
  fullDrawer: {
    width: drawerWidth,
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
  main: {
    flexGrow: 1,
    p: 0,
    ml: { xs: 0, sm: `${drawerWidth}px` },
  },
  mobileDrawer: {
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
}

export default Template
