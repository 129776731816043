import { defaultUsername } from 'core/constants/enum'
import * as yup from 'yup'

export const onboardSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(30, 'Name cannot be greater than 30 characters')
    .test(
      'test',
      'This name is not allowed',
      value => value !== defaultUsername,
    ),
})

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email, e.g. johndoe@gmail.com')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Enter your password'),
})

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email, e.g. johndoe@gmail.com')
    .required('Email is required'),
})

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(8, 'Current password must contain at least 8 characters')
    .max(30, 'Current password must contain at most 30 characters')
    .required('Enter your current password'),
  password: yup
    .string()
    .min(8, 'New password must contain at least 8 characters')
    .max(30, 'New password must contain at most 30 characters')
    .required('Enter your new password')
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'Your new password must contain atleast an uppercase letter, a lowercase letter, a number and a special character',
    ),
  confirmPassword: yup
    .string()
    .min(8, 'Confirm password must contain at least 8 characters')
    .required('Enter your confirm password')
    .oneOf(
      [yup.ref('password'), null],
      'New password and confirm password must match',
    ),
})

export default loginSchema
