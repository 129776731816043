import { createSlice } from '@reduxjs/toolkit'
import { Status } from 'core/constants/enum'
import { Interval } from 'core/service'
import {
  editUserActivity,
  editUserBrbActivity,
  editUserInterval,
  getMembersData,
  getUserActivities,
  deleteUserActivity,
  deleteUserInterval,
  getUserPrevDayActivities,
  updateUserWorkLocation,
  punchIn,
  punchOut,
  punchBreak,
  punchBack,
} from 'features/dashboard/thunks/dashboard'
import { DashboardState } from 'features/dashboard/types'
import moment from 'moment/moment'
import { toast } from 'react-toastify'

export const defaultUserActivity = {
  end: 0,
  intervals: [],
  start: 0,
}
export const defaultEditActivityTimeError = {
  message: '',
  severity: Status.Success,
}

const initialState = {
  userActivity: defaultUserActivity,
  prevUserActivity: defaultUserActivity,
  totalBreak: 0,
  editActivityTimeError: defaultEditActivityTimeError,
  membersData: [],
  attendanceDate: 0,
  isLoading: false,
  isMemberFetched: false,
  isUserPrevDayActivity: false,
  lastUpdatedMembers: '',
} as DashboardState

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setAttendanceDateAction: (state, action) => {
      state.attendanceDate = action.payload
    },
    setUserActivityAction: (state, action) => {
      state.userActivity = action.payload
    },
    setPrevUserActivityAction: (state, action) => {
      state.prevUserActivity = action.payload
    },
    setTotalBreakAction: (state, action) => {
      state.totalBreak = action.payload
    },
    setEditActivityTimeErrorAction: (state, action) => {
      state.editActivityTimeError = action.payload
    },
    setMembersDataAction: (state, action) => {
      state.membersData = action.payload
    },
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
    setLastUpdatedMembersAction: state => {
      state.lastUpdatedMembers = `${moment(new Date()).format('hh:mm A')}`
    },
    setCurrentUserLocationInitially: (state, action) => {
      const response = action.payload
      state.userActivity.workLocation = response.workLocation
      state.membersData.map(member => {
        if (response.uid === member.uid) {
          member.workLocation = response.workLocation
        }
        return true
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(editUserActivity.pending, state => {
      state.isLoading = true
    })
    builder.addCase(editUserActivity.fulfilled, (state, action) => {
      state.userActivity = action.payload
      state.isLoading = false
    })
    builder.addCase(editUserActivity.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(editUserBrbActivity.pending, state => {
      state.isLoading = true
    })
    builder.addCase(editUserBrbActivity.fulfilled, (state, action) => {
      state.userActivity = action.payload
      state.isLoading = false
    })
    builder.addCase(editUserBrbActivity.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(editUserInterval.pending, state => {
      state.isLoading = true
    })
    builder.addCase(editUserInterval.fulfilled, (state, action) => {
      state.userActivity = action.payload
      state.isLoading = false
    })
    builder.addCase(editUserInterval.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(deleteUserActivity.fulfilled, (state, action) => {
      const response = action.payload
      state.userActivity = response
      state.membersData.forEach(member => {
        if (member.uid === response.userId) {
          member.currentStatus = response.status
        }
      })
    })
    builder.addCase(deleteUserInterval.fulfilled, (state, action) => {
      const response = action.payload
      state.userActivity = response
      state.membersData.forEach(member => {
        if (member.uid === response.userId) {
          member.currentStatus = response.status
        }
      })
    })
    builder.addCase(getMembersData.pending, state => {
      state.isMemberFetched = false
      state.isLoading = true
    })
    builder.addCase(getMembersData.fulfilled, (state, action) => {
      state.membersData = action.payload
      state.isMemberFetched = true
      state.isLoading = false
    })
    builder.addCase(getUserActivities.fulfilled, (state, action) => {
      state.userActivity = action.payload
    })
    builder.addCase(getUserPrevDayActivities.fulfilled, (state, action) => {
      const response = action.payload
      if (response.start !== 0) {
        state.isUserPrevDayActivity = true
      }
    })

    builder.addCase(punchIn.fulfilled, (state, action) => {
      const response = action.payload
      state.membersData.forEach(member => {
        if (response.userId === member.uid) {
          member.currentStatus = response.status
        }
      })
      state.userActivity = response
    })

    builder.addCase(punchOut.fulfilled, (state, action) => {
      const response = action.payload
      state.membersData.forEach(member => {
        if (response.userId === member.uid) {
          member.currentStatus = response.status
        }
      })
      state.userActivity.status = response.status
      state.userActivity.end = response.end
    })

    builder.addCase(punchBreak.fulfilled, (state, action) => {
      const response = action.payload
      state.membersData.forEach(member => {
        if (response.userId === member.uid) {
          member.currentStatus = response.status
        }
      })
      state.userActivity.status = response.status
      state.userActivity?.intervals?.push(response?.breakInterval as Interval)
    })

    builder.addCase(punchBack.fulfilled, (state, action) => {
      const response = action.payload
      state.membersData.forEach(member => {
        if (response.userId === member.uid) {
          member.currentStatus = response.status
        }
      })
      state.userActivity = response
    })

    builder.addCase(updateUserWorkLocation.fulfilled, (state, action) => {
      const response = action.payload
      state.membersData.forEach(data => {
        if (data.shiftId === response.shiftId) {
          data.workLocation = response.location
          toast.success('User Work Location updated successfully.')
        }
      })
    })
  },
})

export const {
  setUserActivityAction,
  setTotalBreakAction,
  setEditActivityTimeErrorAction,
  setMembersDataAction,
  setAttendanceDateAction,
  setPrevUserActivityAction,
  setIsLoadingAction,
  setLastUpdatedMembersAction,
  setCurrentUserLocationInitially,
} = dashboardSlice.actions

export default dashboardSlice
