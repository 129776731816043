import { Role } from './constants/enum'
import { adminRoutes, managerRoutes, memberRoutes } from './navigation'

export const getRoutesBasedOnUserRole = (role: string) => {
  if (role === Role.Admin) {
    return adminRoutes
  } else if (role === Role.Manager) {
    return managerRoutes
  }
  return memberRoutes
}
