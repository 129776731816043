import { DynamicFeed } from '@mui/icons-material'
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import colors from 'core/constants/color'
import { User } from 'core/service'
import { timestampToHMFormat } from 'features/dashboard/utils/dashboard'
import React, { CSSProperties, FC, Key, useEffect, useState } from 'react'
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  CellMeasurer as _CellMeasurer,
  CellMeasurerCache,
  CellMeasurerProps,
  List as _List,
  ListProps,
} from 'react-virtualized'
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer'
import { useAppSelector } from 'store/hooks'

type VirtualizedListProps = {
  users: Array<User>
}

export const VirtualizedList = (props: VirtualizedListProps) => {
  const { users } = props
  const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>
  const List = _List as unknown as FC<ListProps>
  const CellMeasurer = _CellMeasurer as unknown as FC<CellMeasurerProps>
  const [cache, setCache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  )

  const { activityFeed } = useAppSelector(state => state.activityFeed)
  const { isDarkMode } = useAppSelector(state => state.app)

  const activeUsers = users.filter(user => user.isActive)
  const UsersMap = new Map<string, User>()
  activeUsers.forEach(user => {
    const userInfo = { ...user } as User
    delete userInfo.uid
    UsersMap.set(user?.uid ?? '', userInfo as User)
  })

  useEffect(() => {
    setCache(
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
      }),
    )
  }, [activityFeed])

  const rowRenderer = ({
    key,
    parent,
    index,
    style,
  }: {
    key: Key
    parent: MeasuredCellParent
    index: number
    style: CSSProperties
  }) => (
    <CellMeasurer key={key} cache={cache} index={index} parent={parent}>
      <div style={style}>
        {activityFeed[index].date !== undefined && (
          <Box display={'flex'} justifyContent="center">
            <Typography
              border={isDarkMode ? '0.5px solid grey' : '0.5px solid lightGrey'}
              boxShadow={'none'}
              component={Paper}
              mb={1}
              mt={3}
              px={1.5}
              py={0.5}
              variant="subtitle2"
            >
              {activityFeed[index].date}
            </Typography>
          </Box>
        )}
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={UsersMap.get(activityFeed[index].uid)?.name || ''}
              src={UsersMap.get(activityFeed[index].uid)?.photoUrl || '#'}
              sx={{ backgroundColor: colors.tealBlue, width: 48, height: 48 }}
              variant="circular"
            />
          </ListItemAvatar>
          <ListItemText sx={{ ml: 1 }}>
            <Box sx={styles.listItemText}>
              <Typography fontWeight={'bold'}>
                {UsersMap.get(activityFeed[index].uid)?.name || ''}
              </Typography>
              <Typography ml={1} textTransform="uppercase" variant="body2">
                {timestampToHMFormat(activityFeed[index].createdAt)}
              </Typography>
            </Box>
            <Typography>{activityFeed[index].message}</Typography>
          </ListItemText>
        </ListItem>
      </div>
    </CellMeasurer>
  )

  return activityFeed.length > 0 ? (
    <div style={styles.root}>
      {cache && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              deferredMeasurementCache={cache}
              height={height}
              rowCount={activityFeed.length}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </div>
  ) : (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={'column'}
      justifyContent="center"
      sx={{ pb: 10, ...styles.root }}
    >
      <DynamicFeed color="secondary" sx={styles.noUser} />
      <Typography color="secondary" variant="subtitle2">
        No Activity
      </Typography>
    </Box>
  )
}

const styles = {
  root: {
    width: '100%',
    height: '70vh',
  },
  listItemText: {
    display: 'flex',
    alignItems: 'baseline',
  },
  noUser: {
    width: { xs: '2.5rem', lg: '2.25rem' },
    height: { xs: '2.75rem', lg: '2.5rem' },
  },
}
