import { Edit } from '@mui/icons-material'
import {
  Tooltip,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { getMyTeam } from 'features/my-team/thunks/my-team'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import UpdateTeamMemberInfo from './UpdateTeamMemberInfo'

interface TableRowStyle {
  '&:hover'?: Object
  '&:last-child td, &:last-child th': Object
}
const tableRowStyle: TableRowStyle = {
  '&:last-child td, &:last-child th': { border: 0 },
}

const MyTeamTable = () => {
  const rowsPerPage = 10
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { myTeam, isLoading } = useAppSelector(state => state.myTeam)
  const { isDarkMode } = useAppSelector(state => state.app)
  const [page, setPage] = useState(0)
  const [editedUserId, setEditedUserId] = useState('')

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (user?.uid) dispatch(getMyTeam(user?.uid ?? ''))
  }, [dispatch, user?.uid])

  if (isLoading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress size={35} />
        <Typography>Loading...</Typography>
      </Box>
    )
  }

  return (
    <Box sx={styles.root}>
      <Paper
        sx={isDarkMode ? styles.tableDark : styles.tableLight}
        variant="outlined"
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Name</TableCell>
                <TableCell sx={styles.tableHeader}>Email Address</TableCell>
                <TableCell sx={styles.tableHeader}>Role</TableCell>
                <TableCell sx={styles.tableHeader}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myTeam
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(row => (
                  <TableRow key={row.uid} sx={tableRowStyle as SxProps}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <Typography sx={styles.capitalizedText} variant="inherit">
                        {row.role}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit Details">
                        <IconButton
                          sx={styles.iconBtn}
                          onClick={() => setEditedUserId(row?.uid ?? '')}
                        >
                          <Edit sx={styles.icon} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {myTeam?.length > rowsPerPage && (
            <TablePagination
              component="div"
              count={myTeam?.length ?? 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              onPageChange={handleChangePage}
            />
          )}
        </TableContainer>
        <UpdateTeamMemberInfo
          open={!!editedUserId}
          user={
            myTeam?.find(teamMember => teamMember.uid === editedUserId) ?? null
          }
          onClose={() => setEditedUserId('')}
        />
      </Paper>
    </Box>
  )
}

export default MyTeamTable

export const styles = {
  root: { py: 0.5, mt: 3 },
  tableDark: {
    border: '0.031rem solid rgba(255, 255, 255, 10%)',
  },
  tableLight: {
    border: '0.031rem solid rgba(0, 0, 0, 10%)',
  },
  tableHeader: {
    fontSize: '0.9rem',
  },
  capitalizedText: { textTransform: 'capitalize' },
  loader: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    alignItems: 'center',
  },
  iconBtn: {
    padding: '4px',
  },
  icon: {
    fontSize: '22px',
  },
}
