import {
  Beenhere,
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  FilterListTwoTone,
  TodayOutlined,
  WeekendOutlined,
  EditOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@mui/material'
import {
  DateFilterLabelForMembers,
  dateFormat,
  Role,
} from 'core/constants/enum'
import { UserActivityUpdated } from 'core/service'
import {
  milliSecondsToHMFormat,
  timestampToHMFormat,
} from 'features/dashboard/utils/dashboard'
import { styles } from 'features/members/components/MembersTable'
import { fetchMemberAttendance } from 'features/members/thunks/members'
import {
  getBreakDuration,
  getFilterBasedDateRange,
} from 'features/members/utils/members'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import ActivityForm from './ActivityForm'

export const Attendance = () => {
  const dispatch = useAppDispatch()
  const rowsPerPage = 10
  const { user } = useAppSelector(state => state.auth)
  const { isDarkMode, serverDate } = useAppSelector(state => state.app)
  const { memberAttendanceData, attendanceLoading, memberData } =
    useAppSelector(state => state.members)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterLabel, setFilterLabelState] = useState(
    DateFilterLabelForMembers.Default,
  )
  const [page, setPage] = useState(0)
  const [editedId, setEditedId] = useState<string>('')
  const [userActivity, setUserActivity] = useState<UserActivityUpdated | null>(
    null,
  )

  const openEditModal = (activity: UserActivityUpdated) => {
    setEditedId(activity?.id ?? '')
    setUserActivity(activity)
  }
  const closeEditModal = () => {
    setEditedId('')
    !editedId && setUserActivity(null)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const dateRange = getFilterBasedDateRange(serverDate, filterLabel)
    dispatch(
      fetchMemberAttendance({
        uid: memberData?.uid ?? '',
        fromDate: dateRange.start,
        toDate: dateRange.end,
      }),
    )
  }, [dispatch, filterLabel, memberData?.uid, serverDate])

  return (
    <Box mt={8}>
      <Box display="flex" justifyContent="space-between" mx={1} my={2}>
        <Box alignItems="center" display="flex" gap={1}>
          <Beenhere color="action" />
          <Typography color="textSecondary" variant="h6">
            Attendance
          </Typography>
        </Box>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleMenu}
        >
          Filter by {filterLabel}
        </Button>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          id="menu-filter"
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setFilterLabelState(DateFilterLabelForMembers.Today)
              handleClose()
            }}
          >
            <CalendarTodayOutlined sx={stylesAtt.filterMenuIcon} />
            <Typography variant="body2">Today</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFilterLabelState(DateFilterLabelForMembers.ThisWeek)
              handleClose()
            }}
          >
            <TodayOutlined sx={stylesAtt.filterMenuIcon} />
            <Typography variant="body2">This Week</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFilterLabelState(DateFilterLabelForMembers.LastWeek)
              handleClose()
            }}
          >
            <WeekendOutlined sx={stylesAtt.filterMenuIcon} />
            <Typography variant="body2">Last Week</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose()
              setFilterLabelState(DateFilterLabelForMembers.ThisMonth)
            }}
          >
            <CalendarMonthOutlined sx={stylesAtt.filterMenuIcon} />
            <Typography variant="body2">This Month</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose()
              setFilterLabelState(DateFilterLabelForMembers.LastMonth)
            }}
          >
            <CalendarMonthOutlined sx={stylesAtt.filterMenuIcon} />
            <Typography variant="body2">Last Month</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Paper
        sx={isDarkMode ? styles.tableDark : styles.tableLight}
        variant="outlined"
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Date</TableCell>
                <TableCell sx={styles.tableHeader}>Work Location</TableCell>
                <TableCell sx={styles.tableHeader}>In Time</TableCell>
                <TableCell sx={styles.tableHeader}>Out Time</TableCell>
                <TableCell sx={styles.tableHeader}>Break Duration</TableCell>
                <TableCell sx={styles.tableHeader}>Work Duration</TableCell>
                <TableCell sx={styles.tableHeader}>Total Duration</TableCell>
                {user?.role === Role.Admin && (
                  <TableCell sx={styles.tableHeader}>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            {!attendanceLoading ? (
              <TableBody>
                {memberAttendanceData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.id} sx={styles.tableRow}>
                      <TableCell>
                        {moment(row.shiftDate).local().format(dateFormat)}
                      </TableCell>
                      <TableCell>
                        {row?.workLocation ? row?.workLocation : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {row.start !== 0
                          ? timestampToHMFormat(row?.start ?? 0)
                          : 'Not started'}
                      </TableCell>
                      <TableCell>
                        {row.start === 0 && 'Not started'}
                        {row.start !== 0 &&
                          row.end !== 0 &&
                          timestampToHMFormat(row?.end ?? 0)}
                        {row.start !== 0 && row.end === 0 && 'In progress'}
                      </TableCell>
                      <TableCell>
                        {milliSecondsToHMFormat(
                          getBreakDuration(row?.intervals ?? []),
                        )}
                      </TableCell>
                      <TableCell>
                        {row.start === 0 && 'Work not started'}
                        {row.start !== 0 && row.end === 0 && 'Work in progress'}
                        {row.end !== 0 &&
                          milliSecondsToHMFormat(
                            (row?.end ?? 0) -
                              (row?.start ?? 0) -
                              getBreakDuration(row?.intervals ?? []),
                          )}
                      </TableCell>
                      <TableCell>
                        {row.start === 0 && 'Work not started'}
                        {row.start !== 0 && row.end === 0 && 'Work in progress'}
                        {row.end !== 0 &&
                          milliSecondsToHMFormat(
                            (row?.end ?? 0) - (row?.start ?? 0),
                          )}
                      </TableCell>
                      {user?.role === Role.Admin && (
                        <TableCell>
                          <Box component="span">
                            <Tooltip title="Edit">
                              <IconButton onClick={() => openEditModal(row)}>
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow sx={stylesAtt.emptyTableRow}>
                  <TableCell sx={{ py: 6 }}>
                    <Box component="span" sx={stylesAtt.loadingWrapper}>
                      <Box component="span" sx={stylesAtt.loader}>
                        <CircularProgress size={25} />
                        <Typography>Loading...</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {memberAttendanceData.length > rowsPerPage && (
            <TablePagination
              component="div"
              count={memberAttendanceData.length}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              onPageChange={handleChangePage}
            />
          )}
        </TableContainer>
      </Paper>
      <Dialog fullWidth maxWidth="sm" open={Boolean(editedId)}>
        <DialogTitle component="h2" mb={1} variant="h6">
          Edit Activity
        </DialogTitle>
        <DialogContent>
          <ActivityForm
            closeModal={closeEditModal}
            userActivity={userActivity}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const stylesAtt = {
  filterIcon: {
    p: 0,
  },
  filterMenuIcon: {
    mr: 1.5,
    my: 0.5,
    width: '1rem',
    height: '1rem',
  },
  loader: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    py: 4,
    justifyContent: 'center',
  },
  loadingWrapper: {
    position: 'absolute',
    display: 'flex',
    gap: '8px',
    top: '0px',
    left: '45%',
  },
  emptyTableRow: {
    position: 'relative',
    minHeight: '100px',
    '&:last-child td, &:last-child th': { border: 0 },
  },
}
