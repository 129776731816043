import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  deleteUserActivityParams,
  deleteUserIntervalParams,
  editUserActivityParams,
  editUserBrbActivityParams,
  editUserIntervalParams,
  getUserActivitiesParams,
  punchBackParams,
  punchInParams,
  punchOutParams,
  startBreakParams,
} from '../service'
import { dashboardService } from '../service/service'

export const punchIn = createAsyncThunk(
  'dashboard/punchIn',
  ({ uid, date }: punchInParams, { rejectWithValue }) => {
    try {
      return dashboardService.punchIn({ uid, date })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const punchOut = createAsyncThunk(
  'dashboard/punchOut',
  ({ uid, docId }: punchOutParams, { rejectWithValue }) => {
    try {
      return dashboardService.punchOut({ uid, docId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const punchBreak = createAsyncThunk(
  'dashboard/punchBreak',
  ({ uid, docId }: startBreakParams, { rejectWithValue }) => {
    try {
      return dashboardService.startBreak({ uid, docId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const punchBack = createAsyncThunk(
  'dashboard/punchBack',
  ({ uid, docId }: punchBackParams, { rejectWithValue }) => {
    try {
      return dashboardService.punchBack({ uid, docId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteUserActivity = createAsyncThunk(
  'dashboard/deleteUserActivity',
  (
    { uid, activity, docId, date }: deleteUserActivityParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.deleteUserActivity({ uid, activity, docId, date })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteUserInterval = createAsyncThunk(
  'dashboard/deleteUserInterval',
  (
    { uid, index, docId, date }: deleteUserIntervalParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.deleteUserInterval({ uid, index, docId, date })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editUserActivity = createAsyncThunk(
  'dashboard/editUserActivity',
  (
    { uid, time, activity, docId, date }: editUserActivityParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.editUserActivity({
        uid,
        time,
        activity,
        docId,
        date,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editUserBrbActivity = createAsyncThunk(
  'dashboard/editUserBrbActivity',
  (
    { uid, time, index, date, docId }: editUserBrbActivityParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.editUserBrbActivity({
        uid,
        date,
        time,
        index,
        docId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editUserInterval = createAsyncThunk(
  'dashboard/editUserInterval',
  (
    { uid, startTime, endTime, index, date, docId }: editUserIntervalParams,
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.editUserInterval({
        uid,
        date,
        startTime,
        endTime,
        index,
        docId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getMembersData = createAsyncThunk(
  'dashboard/getMembersData',
  (date: number, { rejectWithValue }) => {
    try {
      return dashboardService.getMembersData(date)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getUserActivities = createAsyncThunk(
  'dashboard/getUserActivities',
  ({ uid, date }: getUserActivitiesParams, { rejectWithValue }) => {
    try {
      return dashboardService.getUserActivities({ uid, date })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getUserPrevDayActivities = createAsyncThunk(
  'dashboard/getUserPrevDayActivities',
  ({ uid, date }: getUserActivitiesParams, { rejectWithValue }) => {
    try {
      return dashboardService.getUserActivities({ uid, date })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserWorkLocation = createAsyncThunk(
  'dashboard/updateUserWorkLocation',
  (
    { docId, location }: { docId: string; location: string },
    { rejectWithValue },
  ) => {
    try {
      return dashboardService.updateWorkLocation(docId, location)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const sendNotificationToManager = createAsyncThunk(
  'notifications/sendNotificationToManager',
  (
    { userId, action }: { userId: string; action: string },
    { rejectWithValue },
  ) => {
    try {
      return axios.post(
        process.env.REACT_APP_SEND_NOTIFICATION_TO_MANAGER ?? '',
        {
          userId,
          action,
        },
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const saveFcmToken = createAsyncThunk(
  'notifications/saveFcmToken',
  (
    { userId, fcmToken }: { userId: string; fcmToken: string },
    { rejectWithValue },
  ) => {
    try {
      return axios.post(process.env.REACT_APP_SAVE_FCM_TOKEN ?? '', {
        userId,
        fcmToken,
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
