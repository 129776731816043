import axios from 'axios'
import moment from 'moment'

export const getServerDate = async () => {
  const response = await axios.get(
    process.env.REACT_APP_GET_SERVER_TIME
      ? process.env.REACT_APP_GET_SERVER_TIME
      : '',
  )
  return new Date(response.data.datetime).setHours(0, 0, 0, 0)
}

export const getServerTime = async () => {
  const response = await axios.get(
    process.env.REACT_APP_GET_SERVER_TIME
      ? process.env.REACT_APP_GET_SERVER_TIME
      : '',
  )
  return new Date(response.data.datetime).getTime()
}

export const getServerDateAndTime = async (): Promise<{
  date: number
  time: number
}> => {
  const response = await axios.get(
    process.env.REACT_APP_GET_SERVER_TIME
      ? process.env.REACT_APP_GET_SERVER_TIME
      : '',
  )
  return {
    date: new Date(response.data.datetime).setHours(0, 0, 0, 0),
    time: moment(response.data.datetime).valueOf(),
  }
}
