import { NotificationsActive, Clear } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { messaging } from 'core/config/firebase'
import { User } from 'core/service'
import { saveFcmToken } from 'features/dashboard/thunks/dashboard'
import {
  checkIfMemberRequestAlreadyCreatedForToday,
  disableUserToCreateWorkRequestForToday,
  enableUserToCreateWorkRequestForToday,
  updateMemberNotifications,
} from 'features/my-team/thunks/my-team'
import { getToken } from 'firebase/messaging'
import moment from 'moment'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'store/hooks'

type UpdateTeamMemberInfoProps = {
  open: boolean
  onClose: () => void
  user: User | null
}

const UpdateTeamMemberInfo = (props: UpdateTeamMemberInfoProps) => {
  const dispatch = useAppDispatch()
  const { open, onClose, user } = props
  const [toggle, setToggle] = React.useState(false)
  const [notificationsToggle, setNotificationsToggle] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)

  useEffect(() => {
    if (user) {
      const today = moment().format('DD/MM/YYYY')
      if (user?.allowedWorkRequestDates?.includes(today)) {
        setToggle(true)
      } else setToggle(false)

      if (user?.notificationsEnabled) {
        setNotificationsToggle(true)
      } else setNotificationsToggle(false)
    }
  }, [user])

  const handleNotifications = async () => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
        if (token && user?.uid) {
          await dispatch(
            saveFcmToken({ userId: user?.uid ?? '', fcmToken: token }),
          )
        }
      }
    }
  }

  const handleSave = async () => {
    setIsUpdating(true)
    let warningMessage = ''
    let successMessage = ''
    const initialNotificationToggle = user?.notificationsEnabled || false
    if (initialNotificationToggle !== notificationsToggle) {
      await dispatch(
        updateMemberNotifications({
          uid: user?.uid ?? '',
          notificationsEnabled: notificationsToggle,
        }),
      )
      successMessage = 'Member settings updated successfully.'
    }
    const today = moment().format('DD/MM/YYYY')
    const initialToggle =
      user?.allowedWorkRequestDates?.includes(today) || false
    if (initialToggle !== toggle) {
      if (toggle) {
        await dispatch(
          enableUserToCreateWorkRequestForToday({
            uid: user?.uid ?? '',
            date: today,
          }),
        )
        successMessage = 'Member settings updated successfully.'
      } else {
        const isRequestAlreadyCreated = await dispatch(
          checkIfMemberRequestAlreadyCreatedForToday({
            uid: user?.uid ?? '',
            date: today,
          }),
        )
        warningMessage = isRequestAlreadyCreated
          ? 'Member has already created work request for today.'
          : ''
        if (!isRequestAlreadyCreated) {
          await dispatch(
            disableUserToCreateWorkRequestForToday({
              uid: user?.uid ?? '',
              date: today,
            }),
          )
          successMessage = 'Member settings updated successfully.'
        }
      }
    }
    setIsUpdating(false)
    onClose()
    if (warningMessage) toast.warning(warningMessage)
    if (successMessage) toast.success(successMessage)
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle component="h2" sx={styles.modalTitle} variant="h6">
        Update Team Member Info
        <Tooltip title="close">
          <IconButton onClick={onClose}>
            <Clear fontSize="small" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mt: '1rem' }}
        >
          <Box sx={styles.field}>
            <Typography sx={styles.label}>User Name</Typography>
            <Typography>{user?.name}</Typography>
          </Box>
          <Box sx={styles.field}>
            <Typography sx={styles.label}>Email Address</Typography>
            <Typography>{user?.email}</Typography>
          </Box>
        </Box>

        <Grid container sx={{ mt: '0.8rem' }}>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={notificationsToggle}
                  onChange={async event => {
                    setNotificationsToggle(event.target.checked)
                    if (event.target.checked) {
                      await handleNotifications()
                    }
                  }}
                />
              }
              label={
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                >
                  <NotificationsActive sx={{ fontSize: '24px' }} />
                  <Typography sx={styles.question}>
                    Turn on notifications for this team member?
                  </Typography>
                </Box>
              }
              labelPlacement="start"
              sx={{ flexDirection: 'row-reverse', mx: 0, gap: '0.25rem' }}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={toggle}
                  onChange={event => setToggle(event.target.checked)}
                />
              }
              label={
                <Typography sx={styles.question}>
                  Enable user to create work request for today (
                  {moment().format('DD/MM/YYYY')})?
                </Typography>
              }
              labelPlacement="start"
              sx={{ flexDirection: 'row-reverse', mx: 0, gap: '0.25rem' }}
            />
          </Grid>
          <Grid item mt="1rem" xs={12}>
            <Typography sx={styles.infoLabel}>
              Allowed work request dates:
            </Typography>
            {user?.allowedWorkRequestDates?.length ? (
              user?.allowedWorkRequestDates?.map(date => (
                <Typography key={date}>{date}</Typography>
              ))
            ) : (
              <Typography>No allowed dates</Typography>
            )}
          </Grid>
          <Grid item sx={styles.btnBox} xs={12}>
            <Button
              color="inherit"
              disabled={isUpdating}
              variant="text"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isUpdating}
              startIcon={isUpdating && <CircularProgress size={18} />}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateTeamMemberInfo

export const styles = {
  modalTitle: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 1,
    pb: 0,
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
    mb: '0.175rem',
  },
  field: {
    width: '100%',
    borderBottom: '1px solid #bdbdbd',
    marginBottom: '0.35rem',
  },
  question: {
    fontSize: '15px',
    fontWeight: 600,
    mb: '0.175rem',
  },
  infoLabel: {
    fontSize: '15px',
    fontWeight: 600,
    mb: '0.175rem',
    color: (theme: Theme) => theme.palette.primary.main,
  },
  btnBox: {
    mt: '1.5rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
}
