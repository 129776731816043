import { firestore, storage } from 'core/config/firebase'
import { getServerDate } from 'core/config/server'
import { Role, Status } from 'core/constants/enum'
import DataService, {
  Activity,
  AlertStatus,
  EmailConfigSetting,
  GazettedHoliday,
  User,
} from 'core/service'
import { FirebaseError } from 'firebase/app'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  User as FirebaseUser,
} from 'firebase/auth'
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  documentId,
  and,
} from 'firebase/firestore'
import {
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage'

class FirebaseDataService implements DataService {
  async getUserData(email: string, uid: string): Promise<User> {
    const docRef = doc(firestore, `users/${uid}`)
    const docSnap = await getDoc(docRef)
    return { uid: docSnap.id, ...docSnap.data() } as User
  }

  async editProfile(
    uid: string,
    name: string,
    phone: string,
    workingHoursStart: string,
    workingHoursEnd: string,
  ): Promise<void> {
    const docRef = doc(firestore, `users/${uid}`)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        name,
        phone,
        workingHoursStart,
        workingHoursEnd,
      })
    }
  }

  async editProfileImage(
    uid: string,
    file: File,
  ): Promise<{ fileName: string; photoUrl: string }> {
    const timestamp = new Date().getTime()
    const fileName = `${timestamp}_${file.name}`
    const storageRef = ref(storage, `/files/${fileName}`)
    const uploadTask = await uploadBytesResumable(storageRef, file)

    let photoUrl = ''
    getDownloadURL(uploadTask.ref).then(async url => {
      photoUrl = url
      const docRef = doc(firestore, `users/${uid}`)
      await updateDoc(docRef, {
        profilePicture: fileName,
        photoUrl,
      })
    })
    return { fileName, photoUrl }
  }

  async deleteProfileImage(uid: string, fileName: string): Promise<void> {
    const storageRef = ref(storage, `/files/${fileName}`)
    await deleteObject(storageRef)
    const docRef = doc(firestore, `users/${uid}`)
    await updateDoc(docRef, {
      profilePicture: null,
      photoUrl: null,
    })
  }

  async getMemberData(uid: string, callback: (data: User | null) => void) {
    const docRef = doc(firestore, `users/${uid}`)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data() as User
      data.uid = docSnap.id
      callback(data)
    } else callback(null)
  }

  async getActivityFeed(
    users: string[],
    fromDate: number,
    toDate: number,
  ): Promise<Array<Activity>> {
    try {
      const collectionRef = collection(firestore, 'activity-feed')
      const queryRef = query(
        collectionRef,
        and(
          where(documentId(), '>=', fromDate.toString()),
          where(documentId(), '<=', toDate.toString()),
        ),
      )
      const collSnap = await getDocs(queryRef)

      let arrData: Array<Activity> = []
      for (const document of collSnap.docs) {
        const unfilteredActivities = document.get(
          'activities',
        ) as Array<Activity>
        const activities =
          users.length === 0
            ? unfilteredActivities
            : unfilteredActivities.filter(
                activity => users.indexOf(activity.uid) > -1,
              )
        let data: Array<Activity> = []
        if (activities.length > 0) {
          data = activities
          data.sort(
            (activityA, activityB) => activityA.createdAt - activityB.createdAt,
          )
          const date = new Date(data[data.length - 1].createdAt)
          const serverDate = await getServerDate()
          data[data.length - 1].date =
            date.setHours(0, 0, 0, 0) === serverDate
              ? 'Today'
              : date.toDateString()
          arrData = arrData.concat(data)
        }
      }
      arrData.reverse()
      return arrData
    } catch (err) {
      console.error(err)
      return []
    }
  }

  async addActivityToFeed(activity: Activity): Promise<void> {
    const docRef = doc(firestore, `activity-feed/${activity?.date}`)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) await setDoc(docRef, { activities: [] })
    delete activity.date
    await updateDoc(docRef, {
      activities: arrayUnion(activity),
    })
  }

  async changePassword(
    user: FirebaseUser | null,
    currentPassword: string,
    password: string,
  ): Promise<AlertStatus> {
    if (user) {
      try {
        const credentials = EmailAuthProvider.credential(
          user.email || '',
          currentPassword,
        )
        await reauthenticateWithCredential(user, credentials)
        await updatePassword(user, password)
        return {
          message: 'Your password is changed successfully',
          severity: Status.Success,
        }
      } catch (err) {
        const firebaseError = err as FirebaseError
        if (firebaseError.code === 'auth/wrong-password')
          return {
            message: 'Your current password is incorrect',
            severity: Status.Error,
          }
        if (firebaseError.code === 'auth/requires-recent-login')
          return {
            message: 'You must login again before changing your password',
            severity: Status.Error,
          }
        return {
          message: 'An error occurred while changing your password. Try again',
          severity: Status.Error,
        }
      }
    }
    return {
      message: 'User is undefined',
      severity: Status.Error,
    }
  }

  async getManagerTeam(uid: string, role: string): Promise<Array<User>> {
    const userColRef = collection(firestore, 'users')
    const queryRef =
      role === Role.Admin
        ? query(
            userColRef,
            where('isActive', '==', true),
            orderBy('name', 'asc'),
          )
        : query(
            userColRef,
            where('isActive', '==', true),
            where('managerId', '==', uid),
            orderBy('name', 'asc'),
          )

    const userColSnap = await getDocs(queryRef)
    const users: Array<User> = userColSnap.docs.map(
      document =>
        ({
          uid: document.id,
          ...document.data(),
        } as User),
    )
    if (role === Role.Admin) {
      return users.filter(user => user.uid !== uid)
    }
    return users
  }

  async saveEmailConfigSetting(
    docId: string,
    smtpEmail: string,
    smtpPassword: string,
    smtpServer: string,
    smtpPort: number | string,
    emailRecipients: string[],
  ): Promise<EmailConfigSetting> {
    const requestData = {
      smtpEmail,
      smtpPassword,
      smtpServer,
      smtpPort,
      emailRecipients,
    }

    if (docId) {
      const docRef = doc(firestore, `configs/${docId}`)
      await setDoc(docRef, requestData)
      return { id: docId, ...requestData }
    }
    const colRef = collection(firestore, `configs`)
    const response = await addDoc(colRef, requestData)
    return { id: response.id, ...requestData }
  }

  async getEmailConfigSetting(userType: string): Promise<EmailConfigSetting> {
    if (userType === Role.Admin) {
      const colRef = collection(firestore, `configs`)
      const colSnap = await getDocs(colRef)

      const configs: Array<EmailConfigSetting> = []

      colSnap.docs.map(aDoc => {
        const data = { id: aDoc.id, ...aDoc.data() } as EmailConfigSetting
        configs.push(data)
        return true
      })

      return configs[0]
    }
    return {} as EmailConfigSetting
  }

  async getAllUsers(userType: string): Promise<Array<User>> {
    if (userType === Role.Admin) {
      const colRef = collection(firestore, 'users')
      const colSnap = await getDocs(colRef)
      const users: Array<User> = colSnap.docs.map(
        docSnap => ({ uid: docSnap.id, ...docSnap.data() } as User),
      )
      return users
    }
    return [] as User[]
  }

  async addGazettedHoliday(
    startDate: string,
    endDate: string,
    holidayTitle: string,
  ): Promise<GazettedHoliday> {
    const holidayData = {
      holidayTitle,
      startDate,
      endDate,
    }
    const collRef = collection(firestore, '/gazetted-holiday')
    const response = await addDoc(collRef, holidayData)
    return { id: response.id, ...holidayData }
  }

  async updateGazettedHoliday(
    id: string,
    startDate: string,
    endDate: string,
    holidayTitle: string,
  ): Promise<GazettedHoliday> {
    const holidayData = {
      holidayTitle,
      startDate,
      endDate,
    }
    const docRef = doc(firestore, `/gazetted-holiday/${id}`)
    await setDoc(docRef, holidayData)
    return { id, ...holidayData }
  }

  async deleteGazettedHoliday(id: string): Promise<string> {
    const docRef = doc(firestore, `/gazetted-holiday/${id}`)
    await deleteDoc(docRef)
    return id
  }

  async getGazettedHolidays(): Promise<Array<GazettedHoliday>> {
    const collRef = collection(firestore, '/gazetted-holiday')
    const queryRef = query(collRef, orderBy('startDate', 'desc'))
    const response = await getDocs(queryRef)
    return response.docs.map(
      holiday => ({ id: holiday.id, ...holiday.data() } as GazettedHoliday),
    )
  }
}

export const service = new FirebaseDataService()
