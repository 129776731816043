import { FilterListTwoTone } from '@mui/icons-material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ConfirmModal from 'core/components/ConfirmModal'
import { Spinner } from 'core/components/Spinner'
import {
  CustomDaysFilterLabel,
  dateFormat,
  Role,
  WorkRequestStatus,
} from 'core/constants/enum'
import { User, UserWorkRequest } from 'core/service'
import baseTheme from 'core/theme/base'
import {
  setMembersRequests,
  setStatusFilter,
} from 'features/work-request/reducers/work-request'
import {
  deleteUserWorkRequest,
  getManagerTeam,
  getMembersWorkRequests,
  getSpecificMemberWorkRequests,
} from 'features/work-request/thunks/work-request'
import { EditedInitialValues } from 'features/work-request/types'
import {
  getChipColorForStatus,
  getCountOfDaysWithoutWeekends,
  getDateRangeBasedOnDateFilter,
} from 'features/work-request/utils/work-request'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DateFilter } from '../service'

type All = {
  uid: string
  name: string
}
type RequestTableProps = {
  openApprovalModal: (values: EditedInitialValues) => void
  openEditModal: (values: EditedInitialValues) => void
}
const WorkRequestsTable: React.FC<RequestTableProps> = props => {
  const dispatch = useAppDispatch()
  const { openApprovalModal } = props
  const { isDarkMode } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const { membersRequests, managerTeam, isLoading, statusFilter } =
    useAppSelector(state => state.workRequests)
  const [page, setPage] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [deletedId, setDeletedId] = useState('')
  const [selectedMember, setSelectedMember] = useState<User | All | null>({
    uid: 'all',
    name: 'All',
  })
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false)
  const [anchorElForDateFilter, setAnchorElForDateFilter] =
    useState<null | HTMLElement>(null)
  const [filterDateLabel, setFilterDateLabel] = useState(
    CustomDaysFilterLabel.ThisMonth,
  )
  const [dateRange, setDateRange] = useState<DateFilter>(
    getDateRangeBasedOnDateFilter(CustomDaysFilterLabel.ThisMonth),
  )
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    if (filterDateLabel) {
      setDateRange(getDateRangeBasedOnDateFilter(filterDateLabel))
    }
  }, [filterDateLabel])

  const StatusList = {
    All: 'All',
    ...WorkRequestStatus,
  }

  const workRequestsWithCount = membersRequests.reduce(
    (newReq: UserWorkRequest[], prevReq: UserWorkRequest) => {
      const count = getCountOfDaysWithoutWeekends(
        prevReq.fromDate,
        prevReq.toDate,
      )
      newReq.push({ ...prevReq, count })
      return newReq
    },
    [],
  )

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const userId = user?.uid ?? ''
    const role = user?.role ?? ''
    if (!selectedMember) {
      dispatch(setMembersRequests([]))
    }
    dispatch(getManagerTeam({ uid: userId, role }))
    if (selectedMember?.uid !== 'all') {
      dispatch(
        getSpecificMemberWorkRequests({
          uid: selectedMember?.uid ?? '',
          statusFilter,
          dateFilter: dateRange,
        }),
      )
    } else {
      dispatch(
        getMembersWorkRequests({
          uid: userId,
          role,
          statusFilter,
          dateFilter: dateRange,
        }),
      )
    }
    return () => {
      dispatch(setMembersRequests([]))
    }
  }, [dispatch, user, selectedMember, statusFilter, dateRange])

  const handleOpenDateFilterMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElForDateFilter(event.currentTarget)
  }

  const handleCloseDateFilterMenu = () => {
    setAnchorElForDateFilter(null)
  }
  const handleCloseDeleteConfirmModal = () => {
    setDeletedId('')
    setOpenDeleteConfirmModal(false)
  }

  const handleConfirmDelete = async () => {
    await dispatch(deleteUserWorkRequest(deletedId))
  }

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  if (isLoading)
    return (
      <Box sx={{ mt: '1rem' }}>
        <Spinner />
      </Box>
    )

  return (
    <Box sx={{ paddingBottom: '2rem' }}>
      <Box sx={styles.filtersRow}>
        <Box>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <Autocomplete
              getOptionLabel={option => option.name}
              options={[{ uid: 'all', name: 'All' }, ...managerTeam]}
              renderInput={params => (
                <TextField {...params} label="Team Member" variant="outlined" />
              )}
              renderOption={(params, option) => (
                <Box component="li" {...params}>
                  {option.name}
                </Box>
              )}
              sx={{ width: '300px' }}
              value={selectedMember}
              onChange={(_event, newValue: User | All | null) => {
                setSelectedMember(newValue)
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.35rem' }}>
          <Box>
            <Button
              color="inherit"
              startIcon={<FilterListTwoTone />}
              onClick={handleOpenDateFilterMenu}
            >
              Filter by {filterDateLabel}
            </Button>
            <Menu
              keepMounted
              anchorEl={anchorElForDateFilter}
              id="menu-date-filter"
              open={Boolean(anchorElForDateFilter)}
              onClose={handleCloseDateFilterMenu}
            >
              {Object.values(CustomDaysFilterLabel).map(value => (
                <MenuItem
                  key={value}
                  onClick={() => {
                    setFilterDateLabel(value)
                    setPage(0)
                    handleCloseDateFilterMenu()
                  }}
                >
                  <Typography variant="body2">{value}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box>
            <Button
              color="inherit"
              startIcon={<FilterListTwoTone />}
              onClick={handleMenu}
            >
              Filter by {statusFilter}
            </Button>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="menu-filter"
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {Object.values(StatusList).map(value => (
                <MenuItem
                  key={value}
                  onClick={() => {
                    dispatch(setStatusFilter(value))
                    setPage(0)
                    handleClose()
                  }}
                >
                  <Typography variant="body2">{value}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Box>
      {workRequestsWithCount.length && !isLoading ? (
        <Paper
          sx={isDarkMode ? styles.tableDark : styles.tableLight}
          variant="outlined"
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeader}>Requested By</TableCell>
                  <TableCell sx={styles.tableHeader}>From Date</TableCell>
                  <TableCell sx={styles.tableHeader}>To Date</TableCell>
                  <TableCell sx={styles.tableHeader}>Days Requested</TableCell>
                  <TableCell sx={styles.tableHeader}>Type</TableCell>
                  <TableCell sx={styles.tableHeader}>Reason</TableCell>
                  <TableCell sx={styles.tableHeader}>Remarks</TableCell>
                  <TableCell sx={styles.tableHeader}>Status</TableCell>
                  <TableCell sx={styles.tableHeader}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workRequestsWithCount
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{ maxWidth: '115px', whiteSpace: 'no-wrap' }}
                      >
                        {row.requestedBy}
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: '115px', whiteSpace: 'no-wrap' }}
                      >
                        {moment(new Date(row.fromDate)).format(dateFormat)}
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: '115px', whiteSpace: 'no-wrap' }}
                      >
                        {moment(new Date(row.toDate)).format(dateFormat)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {row.count}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>
                        <Typography sx={styles.shortText} title={row.reason}>
                          {row.reason}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={styles.shortText}
                          title={
                            row.remarks?.length
                              ? row.remarks[row.remarks.length - 1].remarks
                              : ''
                          }
                        >
                          {row.remarks?.length
                            ? row.remarks[row.remarks.length - 1].remarks
                            : 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.status}
                          sx={getChipColorForStatus(
                            row?.status ? row?.status : '',
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {row?.userId &&
                          user?.uid === row.userId &&
                          row?.status !== WorkRequestStatus.Open && (
                            <Tooltip title="view request">
                              <IconButton
                                onClick={() => {
                                  openApprovalModal(row)
                                }}
                              >
                                <RemoveRedEyeOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        {user?.role !== Role.Member &&
                          row?.userId &&
                          user?.uid !== row.userId && (
                            <Tooltip title="view request">
                              <IconButton
                                onClick={() => {
                                  openApprovalModal(row)
                                }}
                              >
                                <RemoveRedEyeOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box sx={styles.borderedBox}>
          <Typography sx={styles.noRequestFound} variant="h6">
            No Request Found
          </Typography>
        </Box>
      )}
      {!isLoading && workRequestsWithCount.length > 10 && (
        <TablePagination
          component="div"
          count={workRequestsWithCount.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <ConfirmModal
        closeModal={handleCloseDeleteConfirmModal}
        dialogText="Do you really want to delete this request?"
        dialogTitle="Delete Work Request"
        handleConfirm={handleConfirmDelete}
        open={openDeleteConfirmModal}
      />
    </Box>
  )
}

export default WorkRequestsTable

const styles = {
  tableDark: {
    border: '0.031rem solid rgba(255, 255, 255, 10%)',
  },
  tableLight: {
    border: '0.031rem solid rgba(0, 0, 0, 10%)',
  },
  tableHeader: {
    fontSize: '0.9rem',
  },
  shortText: {
    display: 'inline-block',
    width: '140px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  editBtn: {
    '&:hover': { color: baseTheme.palette.primary.main },
  },
  noRequestFound: {
    display: 'flex',
    justifyContent: 'center',
  },
  filtersRow: {
    mb: 2,
    display: 'flex',
    justifyContent: 'end',
    gap: '0.75rem',
    alignItems: 'center',
  },
  borderedBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #bdbdbd',
    mt: '1.5rem',
    p: '1.5rem',
  },
}
