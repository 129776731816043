import { Avatar, SxProps, Theme } from '@mui/material'
import { storage } from 'core/config/firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import React, { useEffect, useState } from 'react'

type ProfileAvatarProps = {
  imageName: string
  alt: string
  variant: 'rounded' | 'circular' | 'square'
  styles?: SxProps<Theme>
  previewImage?: string | null
  profileUrl: string
}

const ProfileAvatar = (props: ProfileAvatarProps) => {
  const { imageName, profileUrl, alt, variant, styles, previewImage } = props
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (imageName && !profileUrl) {
      const imageRef = ref(storage, `/files/${imageName}`)
      getDownloadURL(imageRef)
        .then(url => {
          setImageUrl(url)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [imageName, profileUrl])

  let imageSrc = '#'
  if (previewImage) {
    imageSrc = previewImage
  } else if (profileUrl) {
    imageSrc = profileUrl
  } else if (imageUrl) {
    imageSrc = imageUrl
  }

  return <Avatar alt={alt} src={imageSrc} sx={styles} variant={variant} />
}

export default ProfileAvatar

ProfileAvatar.defaultProps = {
  styles: undefined,
  previewImage: null,
}
