import { Edit, ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  Badge,
  Theme,
  CircularProgress,
} from '@mui/material'
import ProfileAvatar from 'core/components/ProfileAvatar'
import colors from 'core/constants/color'
import { Role } from 'core/constants/enum'
import editProfileSchema from 'features/members/schema'
import {
  deleteProfileImage,
  editProfile,
  editProfileImage,
} from 'features/members/thunks/members'
import { Form, Formik } from 'formik'
import React, { ChangeEvent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type ProfileFormValues = {
  name: string
  phone: string
  workingHoursStart: string
  workingHoursEnd: string
}

export const Profile = () => {
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const { memberData, managerData } = useAppSelector(state => state.members)
  const { user } = useAppSelector(state => state.auth)

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const openModal = () => setIsEditModalOpen(true)

  const [file, setFile] = useState<File | null>(null)
  const [previewImage, setPreviewImage] = useState<string | null>(null)

  const closeModal = () => {
    setIsEditModalOpen(false)
    setPreviewImage(null)
    setFile(null)
  }

  const initialValues: ProfileFormValues = {
    name: memberData?.name ?? '',
    phone: memberData?.phone ?? '',
    workingHoursStart: memberData?.workingHoursStart ?? '',
    workingHoursEnd: memberData?.workingHoursEnd ?? '',
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const files = [...Object.values(target.files!)]
    const image = files[0] ? files[0] : null
    if (image) {
      setFile(image)
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => {
        setPreviewImage(reader.result as string)
      }
    }
  }

  const handleSubmit = async (values: ProfileFormValues) => {
    setIsUpdating(true)
    if (file) {
      user?.profilePicture &&
        (await dispatch(
          deleteProfileImage({
            uid: user?.uid ?? '',
            fileName: user?.profilePicture ?? '',
          }),
        ))
      await dispatch(editProfileImage({ uid: user?.uid ?? '', file }))
    }
    await dispatch(
      editProfile({
        uid: user?.uid ?? '',
        name: values.name || '',
        phone: values.phone || '',
        workingHoursStart: values.workingHoursStart || '',
        workingHoursEnd: values.workingHoursEnd || '',
      }),
    )
    setIsUpdating(false)
    closeModal()
    toast.success('Your profile updated successfully.')
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item alignItems="center" display="flex" gap={2} py={1}>
          {user?.role === Role.Admin && (
            <Tooltip title="Back">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          )}
          <ProfileAvatar
            alt={memberData?.name ?? ''}
            imageName={memberData?.profilePicture ?? ''}
            profileUrl={memberData?.photoUrl ?? ''}
            styles={styles.avatar}
            variant="rounded"
          />
          <Box>
            <Typography
              sx={{ fontSize: { xs: '1.1rem', sm: '1.393rem' } }}
              variant={'h5'}
            >
              {memberData?.name}
            </Typography>
            <Typography
              color="secondary"
              mb={0.5}
              mt={0.25}
              sx={{ overflowWrap: 'anywhere' }}
              variant="body2"
            >
              {memberData?.email}
            </Typography>
            <Typography
              color="textSecondary"
              textTransform={'capitalize'}
              variant="body2"
            >
              <span style={styles.role}>Role:</span> {memberData?.role}
            </Typography>
            <Typography
              color="textSecondary"
              textTransform={'capitalize'}
              variant="body2"
            >
              <span style={styles.role}>Reports To:</span> {managerData?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item py={1}>
          <Box>
            {(user ? user.uid : '') === memberData?.uid && (
              <Button variant="contained" onClick={openModal}>
                Edit Profile
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog maxWidth={'xs'} open={isEditModalOpen}>
        <DialogTitle component={'h2'} variant="h6">
          Edit Profile
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box sx={styles.editImageSection}>
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <IconButton
                    sx={styles.editIconBtn}
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click()
                      }
                    }}
                  >
                    <input
                      ref={fileInputRef}
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                    />
                    <Edit />
                  </IconButton>
                }
                overlap="circular"
              >
                <ProfileAvatar
                  alt={memberData?.name ?? ''}
                  imageName={memberData?.profilePicture ?? ''}
                  previewImage={previewImage}
                  profileUrl={user?.photoUrl ?? ''}
                  styles={styles.editAvatar}
                  variant="circular"
                />
              </Badge>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={editProfileSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange }) => (
                <Form>
                  <Box sx={styles.editProfile}>
                    <TextField
                      fullWidth
                      error={!!(touched.name && errors.name)}
                      helperText={
                        touched.name && errors.name ? errors.name : ''
                      }
                      id="name"
                      label="Name"
                      margin="normal"
                      type="string"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      error={!!(touched.phone && errors.phone)}
                      helperText={
                        touched.phone && errors.phone ? errors.phone : ''
                      }
                      id="phone"
                      label="Phone Number"
                      margin="normal"
                      type="string"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    <Box mt={1}>
                      <Typography
                        color="inherit"
                        fontWeight={'regular'}
                        variant="subtitle2"
                      >
                        Working Hours
                      </Typography>
                      <Grid container>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            color="inherit"
                            fontWeight={'regular'}
                            sx={styles.startTime}
                            variant="caption"
                          >
                            Start Time
                          </Typography>
                          <TextField
                            fullWidth
                            error={
                              !!(
                                touched.workingHoursStart &&
                                errors.workingHoursStart
                              )
                            }
                            helperText={
                              touched.workingHoursStart &&
                              errors.workingHoursStart
                                ? errors.workingHoursStart
                                : ''
                            }
                            id="workingHoursStart"
                            size="small"
                            sx={styles.startTimePicker}
                            type="time"
                            value={values.workingHoursStart}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item pt={{ xs: 0.5, sm: 0 }} sm={6} xs={12}>
                          <Typography
                            color="inherit"
                            fontWeight={'regular'}
                            sx={styles.endTime}
                            variant="caption"
                          >
                            End Time
                          </Typography>
                          <TextField
                            fullWidth
                            error={
                              !!(
                                touched.workingHoursEnd &&
                                errors.workingHoursEnd
                              )
                            }
                            helperText={
                              touched.workingHoursEnd && errors.workingHoursEnd
                                ? errors.workingHoursEnd
                                : ''
                            }
                            id="workingHoursEnd"
                            size="small"
                            sx={styles.endTimePicker}
                            type="time"
                            value={values.workingHoursEnd}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={styles.modalBtn}>
                    <Button
                      color="inherit"
                      disabled={isUpdating}
                      variant="text"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={isUpdating}
                      startIcon={
                        isUpdating ? <CircularProgress size={17} /> : null
                      }
                      sx={styles.saveBtn}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

const styles = {
  role: { fontWeight: 'bold' },
  root: { p: 0 },
  startTime: { pl: 1 },
  endTime: { pl: { xs: 1, sm: 2 } },
  email: { mb: 2 },
  editAvatar: {
    border: '2px solid #c2c2c2',
    width: 150,
    height: 150,
    fontSize: '4rem',
  },
  avatar: {
    width: 100,
    height: 100,
    fontSize: '2rem',
    backgroundColor: colors.tealBlue,
  },
  username: {},
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    py: 6,
    boxShadow: 1,
  },
  startTimePicker: {
    pr: { xs: 0, sm: 1 },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
  },
  endTimePicker: {
    pl: { xs: 0, sm: 1 },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
  },
  editProfile: { px: { xs: 1, sm: 3 } },
  modalBtn: {
    mt: 6,
    display: 'flex',
    justifyContent: 'end',
  },
  saveBtn: {
    ml: 1.5,
  },
  alert: {
    mb: 2,
  },
  previewImage: { objectFit: 'cover' },
  editImageSection: {
    mb: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  chooseImage: {
    ml: 2,
    display: 'flex',
  },
  uploadBtn: {
    ml: 2,
    mt: 2,
  },
  textInfo: { p: 1 },
  profilePicActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    marginTop: '8px',
  },
  editIconBtn: {
    color: 'white',
    borderRadius: '50%',
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette.primary.main,
    },
  },
}
