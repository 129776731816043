import moment from 'moment/moment'
import { string, object, array, number, date } from 'yup'

export const EmailConfigurationsSchema = object().shape({
  smtpEmail: string().email().label('SMTP Email').required(),
  smtpPassword: string().trim().label('SMTP Password').required(),
  smtpServer: string().label('SMTP Server').required(),
  smtpPort: number()
    .typeError('You must specify a number')
    .label('SMTP Port')
    .required(),
  emailRecipients: array().of(string()).label('Email Recipients').min(1),
})

export const GazettedHolidayForm = object().shape({
  holidayTitle: string().required('This is required field.'),
  startDate: date().required('This is required field.'),
  endDate: date()
    .test(
      'endDate should after startDate check',
      'End Date must be same or after the Start Date',
      function (value): boolean {
        const fromDate = moment(this.parent.startDate)
        const toDate = moment(value)
        return toDate.isSameOrAfter(fromDate, 'date')
      },
    )
    .required('This is required field.'),
})
