import {
  AssessmentOutlined,
  DynamicFeedOutlined,
  Timer,
  Groups,
} from '@mui/icons-material'
import GroupIcon from '@mui/icons-material/Group'
import SettingsIcon from '@mui/icons-material/Settings'
import WorkRequestIconWithBackground from 'core/assets/14x14-bg-work-request.svg'
import WorkRequestIcon from 'core/assets/work-request-16x16.svg'
import Path from 'core/navigation/path'
import ActivityFeed from 'features/activity-feed/containers/ActivityFeed'
import Dashboard from 'features/dashboard/containers/Dashboard'
import ResetPassword from 'features/landing/components/ResetPassword'
import Landing from 'features/landing/containers/Landing'
import { MemberDetails } from 'features/members/components/MemberDetails'
import MyTeam from 'features/my-team/containers/MyTeam'
import Reports from 'features/reports/containers/Reports'
import Settings from 'features/settings/containers/Settings'
import UsersManagement from 'features/users-management/containers/UsersManagement'
import RequestsCalender from 'features/work-request/components/RequestsCalender'
import WorkRequest from 'features/work-request/containers/WorkRequest'
import { Fragment } from 'react'

const commonRoutes = [
  {
    path: Path.Root,
    component: Landing,
    isPrivate: false,
    isSidebarItem: false,
    icon: Fragment,
  },
  {
    path: Path.ResetPassword,
    component: ResetPassword,
    isPrivate: false,
    isSidebarItem: false,
    icon: Fragment,
  },
  {
    path: Path.MemberDetails,
    component: MemberDetails,
    isPrivate: true,
    isSidebarItem: false,
    icon: Fragment,
  },
  {
    path: Path.Dashboard,
    component: Dashboard,
    isPrivate: true,
    isSidebarItem: true,
    title: 'Dashboard',
    icon: Timer,
  },
  {
    path: Path.WorkRequest,
    component: WorkRequest,
    isSidebarItem: true,
    isPrivate: true,
    icon: WorkRequestIcon,
    icon2: WorkRequestIconWithBackground,
    title: 'Work Requests',
    isCustomIcon: true,
  },
  {
    path: Path.CalendarView,
    component: RequestsCalender,
    isPrivate: true,
    isSidebarItem: false,
    title: '',
    icon: Timer,
  },
]

export const memberRoutes = [...commonRoutes]
export const managerRoutes = [
  ...commonRoutes,
  {
    path: Path.MyTeam,
    component: MyTeam,
    isSidebarItem: true,
    isPrivate: true,
    icon: Groups,
    title: 'My Team',
  },
  {
    path: Path.ActivityFeed,
    component: ActivityFeed,
    isPrivate: true,
    isSidebarItem: true,
    title: 'Activity Feed',
    icon: DynamicFeedOutlined,
  },
]
export const adminRoutes = [
  ...commonRoutes,
  {
    path: Path.MyTeam,
    component: MyTeam,
    isSidebarItem: true,
    isPrivate: true,
    icon: Groups,
    title: 'My Team',
  },
  {
    path: Path.ActivityFeed,
    component: ActivityFeed,
    isPrivate: true,
    isSidebarItem: true,
    title: 'Activity Feed',
    icon: DynamicFeedOutlined,
  },
  {
    path: Path.Reports,
    component: Reports,
    isPrivate: true,
    isSidebarItem: true,
    title: 'Reports',
    icon: AssessmentOutlined,
  },
  {
    path: Path.UsersManagement,
    component: UsersManagement,
    isSidebarItem: true,
    isPrivate: true,
    icon: GroupIcon,
    title: 'Users Management',
  },
  {
    path: Path.Settings,
    component: Settings,
    isSidebarItem: true,
    isPrivate: true,
    icon: SettingsIcon,
    title: 'Settings',
  },
]
