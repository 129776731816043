import { createSlice } from '@reduxjs/toolkit'
import { Status } from 'core/constants/enum'
import {
  changePassword,
  getUserData,
  resetPassword,
  signin,
} from 'features/landing/thunks/auth'
import { AuthState } from 'features/landing/types'
import { editProfileImage } from 'features/members/thunks/members'
import { FirebaseError } from 'firebase/app'

export const defaultChangePasswordMessage = {
  message: '',
  severity: Status.Success,
}

const initialState = {
  user: null,
  loginError: '',
  isLoginSubmitting: false,
  resetPasswordError: '',
  isResetPasswordSubmitting: false,
  changePasswordMessage: defaultChangePasswordMessage,
  onboardingMessage: defaultChangePasswordMessage,
} as AuthState

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUserAction: (state, action) => {
      state.user = action.payload
    },
    setLoginErrorAction: (state, action) => {
      state.loginError = action.payload
    },
    setIsLoginSubmittingAction: (state, action) => {
      state.isLoginSubmitting = action.payload
    },
    setResetPasswordErrorAction: (state, action) => {
      state.resetPasswordError = action.payload
    },
    setIsResetPasswordSubmittingAction: (state, action) => {
      state.isResetPasswordSubmitting = action.payload
    },
    setChangePasswordMessageAction: (state, action) => {
      state.changePasswordMessage = action.payload
    },
    setOnboardMessageAction: (state, action) => {
      state.onboardingMessage = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(signin.fulfilled, state => {
      state.loginError = ''
      state.isLoginSubmitting = false
    })
    builder.addCase(signin.rejected, (state, action) => {
      const err = <FirebaseError>action.payload
      switch (err.code) {
        case 'auth/user-not-found':
          state.loginError = 'The user does not exist'
          break
        case 'auth/wrong-password':
          state.loginError = 'Your password is incorrect'
          break
        case 'auth/network-request-failed':
          state.loginError = 'You are not connected to the Internet'
          break
        default:
          state.loginError = 'Something went wrong'
          break
      }
      state.isLoginSubmitting = false
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      if (action.payload > 0) state.resetPasswordError = 'false'
      else state.resetPasswordError = 'The user does not exist'
      state.isResetPasswordSubmitting = false
    })
    builder.addCase(editProfileImage.fulfilled, (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          profilePicture: action.payload.fileName,
          photoUrl: action.payload.photoUrl,
        }
      }
    })

    builder.addCase(resetPassword.rejected, (state, action) => {
      const err = <FirebaseError>action.payload
      switch (err.code) {
        case 'auth/network-request-failed':
          state.resetPasswordError = 'You are not connected to the Internet'
          break
        default:
          state.resetPasswordError = 'Something went wrong'
          break
      }
      state.isResetPasswordSubmitting = false
    })
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.isLoginSubmitting = false
      state.user = action.payload
    })
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changePasswordMessage = action.payload
    })
  },
})

export const {
  setUserAction,
  setLoginErrorAction,
  setIsLoginSubmittingAction,
  setResetPasswordErrorAction,
  setIsResetPasswordSubmittingAction,
  setChangePasswordMessageAction,
  setOnboardMessageAction,
} = authSlice.actions

export default authSlice
