import {
  Brightness4,
  Brightness7,
  CalendarMonthOutlined,
  MenuOutlined,
} from '@mui/icons-material'
import { AppBar, Box, IconButton, Tooltip, Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import colors from 'core/constants/color'
import {
  setDarkModeAction,
  setMobileDrawerOpen,
} from 'features/app/reducers/app'
import { timestampToWMDYFormat } from 'features/dashboard/utils/dashboard'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const drawerWidth = 85

export const Topbar = () => {
  const dispatch = useAppDispatch()
  const { isDarkMode, mobileDrawerOpen, serverDate } = useAppSelector(
    state => state.app,
  )

  const handleDrawer = () => {
    dispatch(setMobileDrawerOpen(!mobileDrawerOpen))
  }

  return (
    <AppBar
      position="fixed"
      sx={isDarkMode ? styles.appbarDark : styles.appbarLight}
    >
      <Toolbar>
        <IconButton color="default" sx={styles.menuIcon} onClick={handleDrawer}>
          <MenuOutlined />
        </IconButton>
        <Box sx={styles.topbarItems}>
          <Tooltip
            title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
          >
            <IconButton
              color="default"
              sx={styles.themeToggler}
              onClick={() => {
                window.localStorage.setItem(
                  'isDarkMode',
                  JSON.stringify(!isDarkMode),
                )
                dispatch(setDarkModeAction(!isDarkMode))
              }}
            >
              {isDarkMode ? <Brightness4 /> : <Brightness7 />}
            </IconButton>
          </Tooltip>
          <CalendarMonthOutlined
            sx={isDarkMode ? {} : styles.calendarIconLight}
          />
          <Typography
            fontWeight="bold"
            sx={isDarkMode ? styles.calendarDate : styles.calendarDateLight}
          >
            {timestampToWMDYFormat(serverDate)}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const styles = {
  menuIcon: { display: { sm: 'none', xs: 'flex' } },
  appbarLight: {
    width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
    ml: { sm: `${drawerWidth}px`, xs: 0 },
    boxShadow: 0,
    background: 'white',
  },
  appbarDark: {
    width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
    ml: { sm: `${drawerWidth}px`, xs: 0 },
    boxShadow: 0,
    background: colors.githubDark,
  },
  topbarItems: { ml: 'auto', display: 'flex', alignItems: 'center' },
  themeToggler: {
    mr: 2,
  },
  calendarIconLight: {
    color: colors.grey,
  },
  calendarDate: {
    mr: 1,
    ml: 1,
  },
  calendarDateLight: {
    ml: 1,
    mr: 1,
    color: colors.grey,
  },
}
