export const getStartOfThisMonth = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(date.getFullYear(), date.getMonth(), 1).getTime()
}

export const getEndOfThisMonth = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
}

export const getStartOfPreviousMonth = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(date.getFullYear(), date.getMonth() - 1, 1).getTime()
}

export const getEndOfPreviousMonth = (timestamp: number) => {
  const date = new Date(timestamp)
  date.setDate(1)
  date.setMilliseconds(-1)
  return date.getTime()
}

export const getStartOfThisWeek = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(
    date.setDate(
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1),
    ),
  ).setHours(0, 0, 0, 0)
}

export const getEndOfThisWeek = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(
    date.setDate(
      date.getDate() - date.getDay() + (date.getDay() === 0 ? 0 : 7),
    ),
  ).setHours(0, 0, 0, 0)
}

export const getStartOfLastWeek = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(
    date.setDate(
      date.getDate() - date.getDay() - (date.getDay() === 0 ? 13 : 6),
    ),
  ).setHours(0, 0, 0, 0)
}

export const getEndOfLastWeek = (timestamp: number) => {
  const date = new Date(timestamp)
  return new Date(
    date.setDate(
      date.getDate() - date.getDay() - (date.getDay() === 0 ? 7 : 0),
    ),
  ).setHours(0, 0, 0, 0)
}
