import { Card, Box, Typography, TextField, Divider } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { dayInMillis } from 'core/constants/enum'
import { UserActivity } from 'features/dashboard/components/UserActivity'
import {
  setAttendanceDateAction,
  setTotalBreakAction,
} from 'features/dashboard/reducers/dashboard'
import {
  getTotalBreak,
  timestampToYMDFormat,
} from 'features/dashboard/utils/dashboard'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const Attendance = () => {
  const dispatch = useAppDispatch()
  const { serverDate } = useAppSelector(state => state.app)
  const { attendanceDate, isUserPrevDayActivity, userActivity } =
    useAppSelector(state => state.dashboard)

  const handleDateChange = (value: string | null) => {
    if (value) {
      const timestamp = new Date(value).setHours(0, 0, 0, 0)
      dispatch(setAttendanceDateAction(timestamp))
    }
  }

  useEffect(() => {
    const intervals = userActivity?.intervals?.length
      ? userActivity?.intervals
      : []
    const breakTime = getTotalBreak(intervals)
    dispatch(setTotalBreakAction(breakTime))
  }, [dispatch, userActivity?.intervals])

  return (
    <Card sx={styles.card} variant="outlined">
      <Box justifyContent="space-between" px={2} sx={styles.cardContent}>
        <Typography color="textSecondary" sx={{ fontWeight: 500 }}>
          ATTENDANCE
        </Typography>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              '& .MuiInput-input': {
                maxWidth: '100px',
              },
            }}
          >
            <DesktopDatePicker
              disableFuture={true}
              InputProps={{ disableUnderline: true }}
              maxDate={timestampToYMDFormat(serverDate)}
              minDate={
                isUserPrevDayActivity
                  ? timestampToYMDFormat(serverDate - dayInMillis)
                  : timestampToYMDFormat(serverDate)
              }
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  inputProps={{
                    ...inputProps,
                    readOnly: true,
                  }}
                  size="small"
                  variant="standard"
                  {...rest}
                />
              )}
              value={timestampToYMDFormat(attendanceDate)}
              onChange={value => handleDateChange(value)}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Divider />
      <UserActivity />
    </Card>
  )
}

const styles = {
  card: {
    pt: '0.5rem',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    py: '0.25rem',
    mb: '0.35rem',
  },
}
