import { Typography, Container, Link } from '@mui/material'
import colors from 'core/constants/color'
import React from 'react'

export const NotFound = () => (
  <Container maxWidth="xs" sx={styles.root}>
    <Typography variant="h4">Oops!</Typography>
    <Typography my={2}>
      We can&#39;t seem to find the page you are looking for.
    </Typography>
    <Typography mb={2} sx={styles.error}>
      Error Code: 404
    </Typography>
    <Link href="/" variant="body1">
      Home
    </Link>
  </Container>
)

const styles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  error: {
    color: colors.grey,
  },
}
