import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import {
  dayInMillis,
  ErrorAlertTimeout,
  Status,
  SuccessAlertTimeout,
  UserStatus,
} from 'core/constants/enum'
import { Interval } from 'core/service'
import { styles as extStyles } from 'features/dashboard/components/UserActivityActions'
import {
  defaultEditActivityTimeError,
  setEditActivityTimeErrorAction,
} from 'features/dashboard/reducers/dashboard'
import { editBreakTimeSchema } from 'features/dashboard/schema'
import {
  deleteUserInterval,
  editUserInterval,
} from 'features/dashboard/thunks/dashboard'
import {
  checkForTimeConflictsInUserInterval,
  timestampToDateTime,
  timestampToYMDFormat,
} from 'features/dashboard/utils/dashboard'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type ActionProps = {
  endTime: number
  index: number
  startTime: number
}

export const UserBreakActivityActions = (props: ActionProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState(false)
  const { user } = useAppSelector(state => state.auth)
  const { userActivity, isLoading } = useAppSelector(state => state.dashboard)
  const { editActivityTimeError, attendanceDate } = useAppSelector(
    state => state.dashboard,
  )

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const openModal = () => setIsEditModalOpen(true)
  const closeModal = () => {
    setIsEditModalOpen(false)
    dispatch(setEditActivityTimeErrorAction(defaultEditActivityTimeError))
  }

  if (editActivityTimeError.message && !isLoading) {
    setTimeout(
      () => {
        const status = editActivityTimeError.severity
        dispatch(setEditActivityTimeErrorAction(defaultEditActivityTimeError))
        if (status === Status.Success) closeModal()
      },
      editActivityTimeError.severity === Status.Error
        ? ErrorAlertTimeout
        : SuccessAlertTimeout,
    )
  }

  const handleDeleteUserInterval = async () => {
    setLoader(true)
    await dispatch(
      deleteUserInterval({
        uid: user?.uid ?? '',
        index: props.index,
        docId: userActivity?.id ?? '',
        date: attendanceDate,
      }),
    )
    setLoader(false)
  }

  const handleSubmit = async (values: {
    startTime: string
    endTime: string
  }) => {
    const start = new Date(values.startTime).getTime()
    const end = new Date(values.endTime).getTime()
    const interval = (userActivity?.intervals?.[props.index] ?? {}) as Interval
    if (interval?.start === start && interval?.end === end) {
      dispatch(
        setEditActivityTimeErrorAction({
          message: 'Selected start and end time are same as previous ones',
          severity: Status.Error,
        }),
      )
      return
    }
    const brbConflicts = checkForTimeConflictsInUserInterval(
      userActivity,
      start,
      UserStatus.Brb,
      props.index,
    )
    dispatch(setEditActivityTimeErrorAction(brbConflicts))
    const backConflicts = checkForTimeConflictsInUserInterval(
      userActivity,
      end,
      UserStatus.Back,
      props.index,
    )
    dispatch(setEditActivityTimeErrorAction(backConflicts))

    if (
      brbConflicts.severity === Status.Success &&
      backConflicts.severity === Status.Success
    ) {
      await dispatch(
        editUserInterval({
          uid: user?.uid ?? '',
          startTime: new Date(values.startTime).getTime(),
          endTime: new Date(values.endTime).getTime(),
          index: props.index,
          date: attendanceDate,
          docId: userActivity?.id ?? '',
        }),
      )
    }
  }

  return (
    <Box sx={styles.actionBox}>
      <Tooltip title="Delete activity">
        <Box>
          <IconButton
            disabled={loader}
            sx={{ '&:hover': { color: theme.palette.error.main } }}
            onClick={handleDeleteUserInterval}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title="Edit activity">
        <Box>
          <IconButton
            disabled={loader}
            sx={extStyles.editBtn}
            onClick={openModal}
          >
            <EditOutlined />
          </IconButton>
        </Box>
      </Tooltip>
      <Dialog maxWidth={'xs'} open={isEditModalOpen}>
        <DialogTitle component={'h2'} mb={3} variant="h6">
          Edit Activity
        </DialogTitle>
        <DialogContent>
          <Box>
            {!isLoading && editActivityTimeError.message && (
              <Alert
                severity={
                  editActivityTimeError.severity as
                    | Status.Success
                    | Status.Error
                }
                sx={extStyles.alert}
              >
                {editActivityTimeError.message}
              </Alert>
            )}
            <Formik
              initialValues={{
                startTime: timestampToDateTime(props.startTime),
                endTime: timestampToDateTime(props.endTime),
              }}
              validationSchema={editBreakTimeSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box sx={styles.editTime}>
                    <Typography
                      color="text.secondary"
                      mr={2}
                      variant="subtitle2"
                    >
                      Start time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        maxDate={timestampToYMDFormat(
                          attendanceDate + dayInMillis,
                        )}
                        minDate={timestampToYMDFormat(attendanceDate)}
                        renderInput={params => (
                          <FormikTextField
                            name="startTime"
                            readonly={true}
                            {...params}
                          />
                        )}
                        value={values.startTime}
                        onChange={newValue => {
                          setFieldValue('startTime', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={extStyles.editTime}>
                    <Typography
                      color="text.secondary"
                      mr={2}
                      variant="subtitle2"
                    >
                      End time&ensp;
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        maxDate={timestampToYMDFormat(
                          attendanceDate + dayInMillis,
                        )}
                        minDate={timestampToYMDFormat(attendanceDate)}
                        renderInput={params => (
                          <FormikTextField
                            name="endTime"
                            readonly={true}
                            {...params}
                          />
                        )}
                        value={values.endTime}
                        onChange={newValue => {
                          setFieldValue('endTime', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={extStyles.modalBtn}>
                    <Button
                      color="inherit"
                      disabled={isLoading}
                      variant="text"
                      onClick={closeModal}
                    >
                      Close
                    </Button>
                    <Button
                      disabled={isLoading}
                      sx={extStyles.saveBtn}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const styles = {
  actionBox: {
    ml: 'auto',
    display: 'flex',
  },
  editTime: {
    mb: 3,
    px: { xs: 1, sm: 5 },
    display: 'flex',
    alignItems: 'center',
  },
}
