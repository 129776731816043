import { Assessment, DownloadOutlined, Timeline } from '@mui/icons-material'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import {
  Typography,
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  CircularProgress,
  IconButton,
  Button,
} from '@mui/material'
import { Role } from 'core/constants/enum'
import Path from 'core/navigation/path'
import { setReportsDataAction } from 'features/reports/reducers/reports'
import { getVarianceCellColor, hoursToHMFormat } from 'features/reports/utils'
import { identity } from 'lodash'
import React, { useState, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import HolidayModal from '../../settings/components/HolidayModal'
import { FilterReport } from '../components/FilterReport'
import TimesheetFilter from '../components/TimesheetFilter'

const EmptyDevLogCell = () => {
  const { isDarkMode } = useAppSelector(state => state.app)
  return <TableCell sx={themeStyles(isDarkMode).timesheetCell} />
}

export type HeaderProps = {
  name: string
  subText?: string
}
const WhoisinTableHeader: React.FC<HeaderProps> = ({ name, subText }) => {
  const { isDarkMode } = useAppSelector(state => state.app)
  return (
    <TableCell sx={themeStyles(isDarkMode).tableHeader}>
      <Box component="span">
        <Typography sx={styles.headerText}>{name}</Typography>
        {subText && (
          <Typography sx={styles.headerSubText}>{subText}</Typography>
        )}
      </Box>
    </TableCell>
  )
}

WhoisinTableHeader.defaultProps = {
  subText: '',
}

const DevLogsTableHeader: React.FC<HeaderProps> = ({ name, subText }) => {
  const { isDarkMode } = useAppSelector(state => state.app)
  return (
    <TableCell sx={themeStyles(isDarkMode).tableHeaderLogs}>
      <Box component="span">
        <Typography sx={styles.headerText}>{name}</Typography>
        {subText && (
          <Typography sx={styles.headerSubText}>{subText}</Typography>
        )}
      </Box>
    </TableCell>
  )
}

DevLogsTableHeader.defaultProps = {
  subText: '',
}

export type FormattedCell = {
  hours: number | undefined
}

const HM_FormattedCell: React.FC<FormattedCell> = ({ hours }) => {
  const { isDarkMode } = useAppSelector(state => state.app)
  return (
    <TableCell sx={themeStyles(isDarkMode).timesheetCell}>
      {hours && `${hoursToHMFormat(hours)} (${hours})`}
    </TableCell>
  )
}

const Deviation_FormattedCell: React.FC<FormattedCell> = ({ hours }) => {
  const { isDarkMode } = useAppSelector(state => state.app)
  return (
    <TableCell sx={getVarianceCellColor(isDarkMode, hours)}>
      {`${hoursToHMFormat(hours ?? 0)} (${hours ?? 0})`}
    </TableCell>
  )
}

const Reports = () => {
  const dispatch = useAppDispatch()
  const { isDarkMode } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const { reportsData, isLoading } = useAppSelector(state => state.reports)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    dispatch(setReportsDataAction([]))
  }, [dispatch])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const tableHeader = [
    { label: 'Date', key: 'date' },
    { label: 'User', key: 'name' },
    { label: 'Shift', key: 'shift' },
    { label: 'Work Location', key: 'wfh' },
    { label: 'Total Time', key: 'totalDuration' },
    { label: 'Break', key: 'breakDuration' },
    { label: 'Net Hours', key: 'workDurationInHrs' },
    { label: 'Dev Log (hrs)', key: 'devlogHours' },
    { label: 'Leave (hrs)', key: 'leaveHours' },
    { label: 'Outage (hrs)', key: 'outageHours' },
    { label: 'Net (hrs)', key: 'netDevlogHrs' },
    { label: 'Deviation (hrs)', key: 'deviationHours' },
  ]

  return user?.role === Role.Admin ? (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box alignItems="center" display="flex" gap={2}>
          <Assessment color="action" fontSize="large" />
          <Typography variant="h4">Reports</Typography>
        </Box>
        <Button
          startIcon={<SystemUpdateAltIcon />}
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          Import Timesheet Data
        </Button>

        <HolidayModal
          Component={<TimesheetFilter onClose={handleCloseModal} />}
          maxWidth="sm"
          open={openModal}
          title="Import Timesheet Data"
          onClose={handleCloseModal}
        />
      </Box>
      <FilterReport resetPage={() => setPage(0)} />
      {!isLoading && reportsData.length > 0 && (
        <Box display={'flex'} m={2} mt={0}>
          <Typography ml="auto" variant="subtitle2">
            Download as excel file :&emsp;
          </Typography>
          <CSVLink
            data={reportsData}
            filename={`Whoisin Report - ${new Date()
              .toLocaleString()
              .split(', ')
              .join(' - ')}.csv`}
            headers={tableHeader}
          >
            <IconButton sx={styles.downloadIcon}>
              <DownloadOutlined />
            </IconButton>
          </CSVLink>
        </Box>
      )}
      {isLoading ? (
        <Box sx={styles.loader}>
          <CircularProgress size={27} />
          <Typography>Loading...</Typography>
        </Box>
      ) : (
        <Box>
          {reportsData.length > 0 ? (
            <Paper sx={themeStyles(isDarkMode).table} variant="outlined">
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <WhoisinTableHeader name="Date" />
                      <WhoisinTableHeader name="User" />
                      <WhoisinTableHeader name="Shift" />
                      <WhoisinTableHeader name="Location" />
                      <WhoisinTableHeader name="Total" />
                      <WhoisinTableHeader name="Break" />
                      <WhoisinTableHeader
                        name="Net Hours"
                        subText="(Total - Break)"
                      />
                      <DevLogsTableHeader name="Dev Log (hrs)" />
                      <DevLogsTableHeader name="Leave (hrs)" />
                      <DevLogsTableHeader name="Outage (hrs)" />
                      <DevLogsTableHeader
                        name="Net"
                        subText="(Total - Leave)"
                      />
                      <DevLogsTableHeader name="Deviation (hrs)" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        if (row.date && row.isWeekend) {
                          return (
                            <TableRow
                              key={identity(
                                `${row.date}-${row.shift}-${index}`,
                              )}
                              sx={themeStyles(isDarkMode).tableRowWeekday}
                            >
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  sx={styles.weekday}
                                >
                                  {row.shift} &#x1F389;
                                </Typography>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                            </TableRow>
                          )
                        } else if (row.date && row.isHoliday) {
                          return (
                            <TableRow
                              key={identity(
                                `${row.date}-${row.shift}-${index}`,
                              )}
                              sx={themeStyles(isDarkMode).tableRowHoliday}
                            >
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  sx={styles.weekday}
                                >
                                  {row.shift}
                                </Typography>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                              <EmptyDevLogCell />
                            </TableRow>
                          )
                        } else if (
                          row.date &&
                          row.isLeave &&
                          !row.isLeavePending
                        ) {
                          return (
                            <TableRow
                              key={`${row.name}-${row.date}`}
                              sx={themeStyles(isDarkMode).tableRowLeave}
                            >
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  sx={styles.weekday}
                                >
                                  {row.shift}
                                </Typography>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <HM_FormattedCell hours={row.devlogHours ?? 0} />
                              <HM_FormattedCell hours={row.leaveHours ?? 0} />
                              <HM_FormattedCell hours={row.outageHours ?? 0} />
                              <HM_FormattedCell hours={row.netDevlogHrs ?? 0} />
                              <Deviation_FormattedCell
                                hours={row.deviationHours}
                              />
                            </TableRow>
                          )
                        } else if (row.date && row.isLeavePending) {
                          return (
                            <TableRow
                              key={`${row.name}-${row.date}`}
                              sx={themeStyles(isDarkMode).rowPendingLeave}
                            >
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  sx={styles.weekday}
                                >
                                  <Timeline />
                                  {row.shift}
                                </Typography>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <HM_FormattedCell hours={row.devlogHours ?? 0} />
                              <HM_FormattedCell hours={row.leaveHours ?? 0} />
                              <HM_FormattedCell hours={row.outageHours ?? 0} />
                              <HM_FormattedCell hours={row.netDevlogHrs ?? 0} />
                              <Deviation_FormattedCell
                                hours={row.deviationHours}
                              />
                            </TableRow>
                          )
                        } else if (row.date && row.isNoShift) {
                          return (
                            <TableRow
                              key={`${row.name}-${row.date}`}
                              sx={themeStyles(isDarkMode).tableRowShift}
                            >
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  sx={styles.weekday}
                                >
                                  {row.shift}
                                </Typography>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <HM_FormattedCell hours={row.devlogHours ?? 0} />
                              <HM_FormattedCell hours={row.leaveHours ?? 0} />
                              <HM_FormattedCell hours={row.outageHours ?? 0} />
                              <HM_FormattedCell hours={row.netDevlogHrs ?? 0} />
                              <Deviation_FormattedCell
                                hours={row.deviationHours}
                              />
                            </TableRow>
                          )
                        } else if (row.date) {
                          return (
                            <TableRow
                              key={`${row.name}-${row.date}`}
                              sx={styles.tableRow}
                            >
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row.date}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row.shift}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row.wfh}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row?.totalDuration}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row?.breakDuration}
                              </TableCell>
                              <TableCell
                                sx={themeStyles(isDarkMode).whoisinCell}
                              >
                                {row.workDurationInHrs
                                  ? `${row.workDuration} (${row?.workDurationInHrs})`
                                  : row.workDuration}
                              </TableCell>
                              <HM_FormattedCell hours={row.devlogHours ?? 0} />
                              <HM_FormattedCell hours={row.leaveHours ?? 0} />
                              <HM_FormattedCell hours={row.outageHours ?? 0} />
                              <HM_FormattedCell hours={row.netDevlogHrs ?? 0} />
                              <Deviation_FormattedCell
                                hours={row.deviationHours}
                              />
                            </TableRow>
                          )
                        }
                        return (
                          <TableRow
                            key={identity(`tableEntry-${index}`)}
                            sx={{
                              ...styles.tableRow,
                              backgroundColor: isDarkMode
                                ? 'rgba(79, 79, 79, 25%)'
                                : 'rgba(79, 79, 79, 7.5%)',
                            }}
                          >
                            <TableCell sx={styles.boldText}>
                              {row.date}
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {row.name}
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              <Typography
                                component="span"
                                sx={styles.shiftCounts}
                              >
                                {row.shift}
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {row.wfh}
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {row.totalDuration}
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {row.breakDuration}
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.workDurationInHrs ?? 0)} (
                              {row.workDurationInHrs})
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.devlogHours ?? 0)} (
                              {row.devlogHours})
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.leaveHours ?? 0)} (
                              {row.leaveHours})
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.outageHours ?? 0)} (
                              {row.outageHours})
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.netDevlogHrs ?? 0)} (
                              {row?.netDevlogHrs})
                            </TableCell>
                            <TableCell sx={styles.boldText}>
                              {hoursToHMFormat(row.deviationHours ?? 0)} (
                              {row.deviationHours})
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
                {!isLoading && reportsData?.length > 10 && (
                  <TablePagination
                    component="div"
                    count={reportsData.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </TableContainer>
            </Paper>
          ) : (
            <Box sx={styles.borderedBox}>
              <Typography sx={styles.notFound} variant="h6">
                No Reports Found
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Navigate to={Path.Root} />
  )
}

const commonTableRowStyle = {
  '&:last-child td, &:last-child th': { border: 0 },
}
const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root: { p: 0.5, mt: 2.5, ml: '1rem', mr: '1rem' },
  boldText: { fontSize: '0.875rem', fontWeight: 'bold' },
  varianceCell: {
    backgroundColor: '#c62828',
  },
  tableRow: {
    position: 'relative',
    ...commonTableRowStyle,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
  downloadIcon: {
    p: 0,
  },
  weekday: {
    position: 'absolute',
    display: 'flex',
    gap: '8px',
    top: '32%',
    left: '30%',
  },
  shiftCounts: {
    fontWeight: 'bold',
    position: 'absolute',
    top: '32%',
    left: '16px',
  },
  borderedBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #bdbdbd',
    mt: '1.5rem',
    p: '1.5rem',
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerText: {
    fontWeight: 'bold',
  },
  headerSubText: {
    fontSize: '11px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}

const themeStyles = (isDarkMode: boolean) => ({
  table: {
    marginBottom: '32px',
    border: '0.031rem solid',
    borderColor: isDarkMode ? 'rgba(255, 255, 255, 10%)' : 'rgba(0, 0, 0, 10%)',
  },
  tableRowWeekday: {
    position: 'relative',
    ...commonTableRowStyle,
    backgroundColor: isDarkMode ? '#989675' : '#fff8e1',
  },
  tableRowLeave: {
    position: 'relative',
    ...commonTableRowStyle,
    backgroundColor: isDarkMode ? '#936368' : '#ffcdd2',
  },
  rowPendingLeave: {
    ...commonTableRowStyle,
    position: 'relative',
    backgroundColor: isDarkMode
      ? 'rgba(218,186,193,0.61)'
      : 'rgba(252,229,232,0.86)',
  },
  tableRowHoliday: {
    ...commonTableRowStyle,
    position: 'relative',
    backgroundColor: isDarkMode ? '#749179' : '#e7ffcd',
  },
  tableRowShift: {
    ...commonTableRowStyle,
    position: 'relative',
    backgroundColor: isDarkMode ? 'rgba(42,42,42,0.26)' : '#fafafa',
  },
  tableHeader: {
    fontSize: '0.9rem',
    backgroundColor: isDarkMode ? 'rgba(91, 153, 185, 0.77)' : '#81d4fa',
  },
  tableHeaderLogs: {
    fontSize: '0.9rem',
    backgroundColor: isDarkMode ? '#888c15' : '#fff176',
  },
  tableHeaderVariance: {
    fontSize: '0.9rem',
    backgroundColor: '#c62828',
    color: 'white',
  },
  whoisinCell: {
    backgroundColor: isDarkMode ? 'rgba(91,153,185,0.77)' : '#81d4fa',
  },
  timesheetCell: {
    backgroundColor: isDarkMode ? '#888c15' : '#fff176',
  },
})

export default Reports
