import { Grid, Button, CircularProgress } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import { dateFormat, YMDFormat } from 'core/constants/enum'
import { reportSchema } from 'features/reports/schema'
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { importTimesheetData } from '../thunks/reports'

type FilterProps = {
  onClose: () => void
}

const TimesheetFilter: React.FC<FilterProps> = props => {
  const { onClose } = props
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { serverDate } = useAppSelector(state => state.app)

  const initialValues = {
    startDate: moment(serverDate).local().format(dateFormat),
    endDate: moment(serverDate).local().format(dateFormat),
  }
  const onSubmit = async (values: { startDate: string; endDate: string }) => {
    const startDate = moment(values.startDate).format(YMDFormat)
    const endDate = moment(values.endDate).format(YMDFormat)
    setLoading(true)
    await dispatch(importTimesheetData({ startDate, endDate }))
    setLoading(false)
    onClose()
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={reportSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container spacing={1.75} sx={styles.form}>
            <Grid item md={12} xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  disableFuture={true}
                  renderInput={params => (
                    <FormikTextField
                      name="startDate"
                      readonly={true}
                      size="medium"
                      textLabel="Start Date"
                      {...params}
                    />
                  )}
                  value={values.startDate}
                  onChange={newVal => {
                    setFieldValue('startDate', newVal)
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  disableFuture={true}
                  renderInput={params => (
                    <FormikTextField
                      name="endDate"
                      readonly={true}
                      size="medium"
                      textLabel="End Date"
                      {...params}
                    />
                  )}
                  value={values.endDate}
                  onChange={newVal => {
                    setFieldValue('endDate', newVal)
                  }}
                  onOpen={() => setFieldValue('endDate', values.startDate)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item sx={styles.button} xs={12}>
              <Button color="secondary" disabled={loading} onClick={onClose}>
                Cancel
              </Button>
              <Button
                disabled={loading}
                size="large"
                startIcon={loading ? <CircularProgress size={12} /> : null}
                type="submit"
                variant="contained"
              >
                {loading ? 'Importing...' : 'Import Data'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default TimesheetFilter

const styles = {
  form: {
    marginTop: '16px',
  },
  button: {
    display: 'flex',
    justifyContent: 'end',
    gap: '12px',
  },
}
