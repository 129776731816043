import { createSlice } from '@reduxjs/toolkit'
import { getActivityFeed } from 'features/activity-feed/thunks/activity-feed'
import { ActivityFeedState } from 'features/activity-feed/types'
import { getMyTeam } from 'features/my-team/thunks/my-team'
import { getAllUsers } from 'features/users-management/thunks/users-management'
import { orderBy } from 'lodash'

const initialState = {
  users: [],
  teamMembers: [],
  activityFeed: [],
  isLoading: false,
  filterUsers: [],
} as ActivityFeedState

const activityFeedSlice = createSlice({
  name: 'activityFeedSlice',
  initialState,
  reducers: {
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
    setActivityFeedDataAction: (state, action) => {
      state.activityFeed = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getActivityFeed.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getActivityFeed.fulfilled, (state, action) => {
      state.activityFeed = action.payload
      state.isLoading = false
    })
    builder.addCase(getActivityFeed.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.users = orderBy(state.users, e => e.name.toLowerCase())
    })
    builder.addCase(getMyTeam.fulfilled, (state, action) => {
      state.teamMembers = orderBy(
        action.payload,
        ({ name }) => name.toLowerCase(),
        'asc',
      )
    })
  },
})

export const { setIsLoadingAction, setActivityFeedDataAction } =
  activityFeedSlice.actions

export default activityFeedSlice
