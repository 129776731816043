import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { service } from 'core/service/firebase'
import {
  ApproveOrRejectWorkRequestParams,
  CreateLeaveRequestByAdminParams,
  CreateWorkRequestsParams,
  DateFilter,
  GetMembersWorkRequestsParams,
  UpdateWorkRequestsParams,
} from 'features/work-request/service'
import { workRequestService } from 'features/work-request/service/service'

export const sendEmailOnRequestCreation = createAsyncThunk(
  'sendEmail/sendEmailOnRequestCreation',
  (
    {
      reqId,
      userId,
      managerId,
    }: { reqId: string; userId: string; managerId: string },
    { rejectWithValue },
  ) => {
    try {
      return axios.post(
        process.env.REACT_APP_SEND_EMAIL_ON_REQUEST_CREATION ?? '',
        {
          reqId,
          userId,
          managerId,
        },
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const sendEmailOnRequestApproval = createAsyncThunk(
  'sendEmail/sendEmailOnRequestApprovedByManager',
  (
    { reqId, userId, role }: { reqId: string; userId: string; role: string },
    { rejectWithValue },
  ) => {
    try {
      return axios.post(
        process.env.REACT_APP_SEND_EMAIL_ON_REQUEST_APPROVAL ?? '',
        {
          reqId,
          userId,
          role,
        },
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createUserWorkRequest = createAsyncThunk(
  'work-request/createUserWorkRequest',
  (
    {
      uid,
      fromDate,
      toDate,
      reason,
      type,
      medicalDoc,
      leaveType,
      requestedBy,
      managerId,
    }: CreateWorkRequestsParams,
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.createUserWorkRequest({
        uid,
        fromDate,
        toDate,
        reason,
        type,
        medicalDoc,
        leaveType,
        requestedBy,
        managerId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const createUserLeaveRequestByAdmin = createAsyncThunk(
  'work-request/createUserLeaveRequestByAdmin',
  (
    {
      uid,
      fromDate,
      toDate,
      reason,
      type,
      leaveType,
      requestedBy,
      approvedBy,
    }: CreateLeaveRequestByAdminParams,
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.createUserLeaveRequestByAdmin({
        uid,
        fromDate,
        toDate,
        reason,
        type,
        leaveType,
        requestedBy,
        approvedBy,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getMembersWorkRequests = createAsyncThunk(
  'work-request/getMembersWorkRequests',
  (
    { uid, role, statusFilter, dateFilter }: GetMembersWorkRequestsParams,
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.getMembersWorkRequests({
        uid,
        role,
        statusFilter,
        dateFilter,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getUserWorkRequests = createAsyncThunk(
  'work-request/getUserWorkRequests',
  (
    {
      uid,
      statusFilter,
      dateFilter,
    }: { uid: string; statusFilter?: string; dateFilter?: DateFilter },
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.getUserWorkRequests({
        uid,
        statusFilter,
        dateFilter,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getSpecificMemberWorkRequests = createAsyncThunk(
  'work-request/getSpecificMemberWorkRequests',
  (
    {
      uid,
      statusFilter,
      dateFilter,
    }: { uid: string; statusFilter?: string; dateFilter?: DateFilter },
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.getUserWorkRequests({
        uid,
        statusFilter,
        dateFilter,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUserWorkRequest = createAsyncThunk(
  'work-request/updateUserWorkRequest',
  (
    {
      uid,
      docId,
      createdAt,
      fromDate,
      toDate,
      reason,
      type,
      medicalDoc,
      leaveType,
      requestedBy,
      remarks,
      managerId,
    }: UpdateWorkRequestsParams,
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.updateUserWorkRequest({
        uid,
        docId,
        createdAt,
        fromDate,
        toDate,
        reason,
        type,
        medicalDoc,
        leaveType,
        requestedBy,
        remarks,
        managerId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteUserWorkRequest = createAsyncThunk(
  'work-request/deleteUserWorkRequest',
  (docId: string, { rejectWithValue }) => {
    try {
      return workRequestService.deleteUserWorkRequest(docId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const approveOrRejectUserWorkRequest = createAsyncThunk(
  'work-request/approveOrRejectUserWorkRequest',
  (
    {
      uid,
      docId,
      status,
      remarks,
      approvedBy,
      userType,
    }: ApproveOrRejectWorkRequestParams,
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.approveOrRejectUserWorkRequest({
        uid,
        userType,
        docId,
        remarks,
        status,
        approvedBy,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const cancelUserWorkRequest = createAsyncThunk(
  'work-request/cancelUserWorkRequest',
  (docId: string, { rejectWithValue }) => {
    try {
      return workRequestService.cancelUserWorkRequest(docId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getMedicalDocDownloadURL = createAsyncThunk(
  'work-request/getMedicalDocDownloadURL',
  (
    { uid, fileName }: { uid: string; fileName: string },
    { rejectWithValue },
  ) => {
    try {
      return workRequestService.getMedicalDocDownloadURL(uid, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getManagerTeam = createAsyncThunk(
  'work-request/getManagerTeam',
  async ({ uid, role }: { uid: string; role: string }, { rejectWithValue }) => {
    try {
      return await service.getManagerTeam(uid, role)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchMemberWorkRequest = (uid: string) =>
  workRequestService.getUserAllWorkRequests(uid)

export const fetchTeamWorkRequests = (uid: string, role: string) =>
  workRequestService.getMembersAllWorkRequests(uid, role)
