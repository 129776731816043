import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  MenuItem,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import { dateTimeFormat, dayInMillis } from 'core/constants/enum'
import { UserActivityUpdated } from 'core/service'
import { editMemberActivityParams } from 'features/dashboard/service'
import {
  timestampToDateTime,
  timestampToYMDFormat,
} from 'features/dashboard/utils/dashboard'
import { editMemberActivity } from 'features/members/thunks/members'
import { Form, Formik } from 'formik'
import { last } from 'lodash'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { EditUserActivitySchema } from '../schema'
import { parsedDate } from '../utils/members'

type ActivityFormProps = {
  userActivity: UserActivityUpdated | null
  closeModal: () => void
}

const ActivityForm: React.FC<ActivityFormProps> = ({
  userActivity,
  closeModal,
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const [loading, setLoading] = useState(false)
  const pendingInterval = userActivity?.intervals?.find(
    interval => interval.end === 0,
  )
  const breakIntervals = userActivity?.intervals
    ?.filter(interval => interval.end !== 0)
    ?.map(interval => ({
      start: moment(interval.start).format(dateTimeFormat),
      end: moment(interval.end).format(dateTimeFormat),
    }))

  const initialValues = {
    workLocation: userActivity?.workLocation ?? '',
    inTime: timestampToDateTime(userActivity?.start ?? new Date().getTime()),
    outTime: userActivity?.end ? timestampToDateTime(userActivity?.end) : '',
    isPendingInterval: Boolean(
      userActivity?.intervals?.length &&
        last(userActivity?.intervals)?.end === 0,
    ),
    breakEndTime: pendingInterval?.end
      ? timestampToDateTime(pendingInterval?.end)
      : '',
    breakStartTime: moment(pendingInterval?.start ?? 0).format(dateTimeFormat),
  }

  const handleSubmit = async (values: {
    workLocation: string
    inTime: string
    outTime: string
    isPendingInterval: boolean
    breakStartTime: string
    breakEndTime: string
  }) => {
    setLoading(true)
    const payload = {
      role: user?.role ?? '',
      start: parsedDate(values.inTime),
      end: values?.outTime ? parsedDate(values.outTime) : 0,
      workLocation: values.workLocation,
      docId: userActivity?.id ?? '',
    } as editMemberActivityParams
    if (values.isPendingInterval && values.breakEndTime) {
      payload.interval = {
        start: parsedDate(values.breakStartTime),
        end: parsedDate(values.breakEndTime),
      }
    }
    await dispatch(editMemberActivity(payload))
    setLoading(false)
    closeModal()
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() =>
        EditUserActivitySchema(userActivity?.intervals ?? [])
      }
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Box sx={styles.editTime}>
            <TextField
              fullWidth
              select
              error={!!(touched.workLocation && errors.workLocation)}
              helperText={
                touched.workLocation && errors.workLocation
                  ? errors.workLocation
                  : ''
              }
              label="Work Location"
              name="workLocation"
              type="text"
              value={values.workLocation}
              onChange={event =>
                setFieldValue('workLocation', event.target.value)
              }
            >
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Office">Office</MenuItem>
            </TextField>
            <Box sx={styles.inputBox}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  maxDate={timestampToYMDFormat(userActivity?.shiftDate ?? 0)}
                  minDate={timestampToYMDFormat(userActivity?.shiftDate ?? 0)}
                  renderInput={params => (
                    <FormikTextField
                      name="inTime"
                      readonly={true}
                      size="medium"
                      textLabel="In Time"
                      {...params}
                    />
                  )}
                  value={values.inTime}
                  onChange={newValue => setFieldValue('inTime', newValue)}
                />

                <DateTimePicker
                  maxDate={timestampToYMDFormat(
                    (userActivity?.shiftDate ?? 0) + dayInMillis,
                  )}
                  minDate={timestampToYMDFormat(userActivity?.shiftDate ?? 0)}
                  renderInput={params => (
                    <FormikTextField
                      name="outTime"
                      readonly={true}
                      size="medium"
                      textLabel="Out Time"
                      {...params}
                    />
                  )}
                  value={values.outTime}
                  onChange={newValue => {
                    setFieldValue('outTime', newValue)
                  }}
                  onOpen={() => setFieldValue('outTime', values.inTime)}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={styles.breakIntervals}>
              <Typography mr={2} sx={styles.break}>
                Break Intervals
              </Typography>

              {!userActivity?.intervals?.length ? (
                <Box sx={{ mt: 1.5 }}>
                  <Divider>
                    <Typography variant="body2">No Breaks Found</Typography>
                  </Divider>
                </Box>
              ) : (
                <>
                  <Box>
                    {breakIntervals?.map(interval => (
                      <Box key={interval.start} sx={styles.textIntervals}>
                        <Typography>{interval.start}</Typography>
                        to
                        <Typography>{interval.end}</Typography>
                      </Box>
                    ))}
                  </Box>

                  {pendingInterval?.start && (
                    <Box sx={styles.textIntervals}>
                      <Typography sx={{ whiteSpace: 'nowrap' }}>
                        {values.breakStartTime}
                      </Typography>
                      <Typography>to</Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={{ width: '210px', mt: '10px' }}>
                          <DateTimePicker
                            maxDate={timestampToYMDFormat(
                              userActivity?.shiftDate ?? 0,
                            )}
                            minDate={timestampToYMDFormat(
                              userActivity?.shiftDate ?? 0,
                            )}
                            renderInput={params => (
                              <FormikTextField
                                name="breakEndTime"
                                readonly={true}
                                size="medium"
                                textLabel="End Time"
                                {...params}
                              />
                            )}
                            value={
                              values.breakEndTime ? values.breakEndTime : ''
                            }
                            onChange={newValue =>
                              setFieldValue(`breakEndTime`, newValue)
                            }
                            onOpen={() =>
                              setFieldValue(
                                'breakEndTime',
                                values.breakStartTime,
                              )
                            }
                          />
                        </Box>
                      </LocalizationProvider>
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box sx={styles.modalBtn}>
              <Button
                color="inherit"
                disabled={loading}
                variant="text"
                onClick={closeModal}
              >
                Close
              </Button>
              <Button
                disabled={loading}
                sx={styles.saveBtn}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default ActivityForm

const styles = {
  actionBox: {
    ml: 'auto',
    display: 'flex',
  },
  inputBox: { display: 'flex', alignItems: 'center', gap: 2, mt: 3 },
  editTime: {
    my: 1,
    px: { xs: 1, sm: 3.5 },
  },
  breakIntervals: {
    mb: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  break: {
    mt: 1.5,
    fontWeight: 500,
    fontSize: '15px',
  },
  modalBtn: {
    mt: 2.5,
    display: 'flex',
    justifyContent: 'end',
  },
  saveBtn: {
    ml: 1.5,
  },
  textIntervals: {
    px: '2rem',
    mt: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}
