import { Person } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import ProfileAvatar from 'core/components/ProfileAvatar'
import colors from 'core/constants/color'
import { Role, UserStatus, WorkLocation } from 'core/constants/enum'
import { User } from 'core/service'
import baseTheme from 'core/theme/base'
import moment from 'moment'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setLastUpdatedMembersAction } from '../reducers/dashboard'
import { getMembersData } from '../thunks/dashboard'
import { MembersType } from '../types'

export type UserListProps = {
  users: Array<User>
  listType?: string
  userRole?: string
  selectedMembersType?: MembersType
}

const UsersList = (props: UserListProps) => {
  const navigate = useNavigate()
  const { user } = useAppSelector(state => state.auth)
  const { users, listType, userRole, selectedMembersType } = props

  return users.length > 0 ? (
    <List sx={styles.usersList}>
      {users.map(member => (
        <div key={member.uid}>
          <ListItem>
            <ListItemAvatar>
              <ProfileAvatar
                alt={member.name ?? ''}
                imageName={member?.profilePicture ?? ''}
                profileUrl={member?.photoUrl ?? ''}
                styles={{ backgroundColor: colors.tealBlue }}
                variant="circular"
              />
            </ListItemAvatar>
            <Tooltip
              arrow
              placement="right"
              title={
                userRole !== 'member' &&
                selectedMembersType === 'team' &&
                listType === UserStatus.In ? (
                  <Typography>
                    In Time: {moment(member.inTime).format('hh:mm A')}
                  </Typography>
                ) : (
                  ''
                )
              }
            >
              <Box
                sx={[
                  styles.userName,
                  user?.role === Role.Admin
                    ? {
                        '&:hover': {
                          cursor: 'pointer',
                          color: baseTheme.palette.primary.main,
                        },
                        ...styles.user,
                      }
                    : styles.user,
                ]}
                onClick={() =>
                  user?.role === Role.Admin && navigate(`/users/${member.uid}`)
                }
              >
                <Typography>{member.name}</Typography>
                {member.workLocation === WorkLocation.Home && (
                  <Tooltip placement="right" title="Working from home">
                    <Avatar alt="home-icon" sx={styles.homeAvatar}>
                      <HomeIcon sx={{ width: 18, height: 18 }} />
                    </Avatar>
                  </Tooltip>
                )}
              </Box>
            </Tooltip>
          </ListItem>
        </div>
      ))}
    </List>
  ) : (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={'column'}
      justifyContent="center"
      sx={{ pb: 11, ...styles.usersList }}
    >
      <Person color="secondary" sx={styles.noUser} />
      <Typography color="secondary" variant="subtitle2">
        No Users
      </Typography>
    </Box>
  )
}

UsersList.defaultProps = {
  listType: '',
  userRole: '',
  selectedMembersType: 'team',
}

export const WhosInOut = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const [value, setValue] = useState(0)
  const [loader, setLoader] = useState(false)
  const [membersType, setMembersType] = useState<MembersType>('team')
  const { membersData, attendanceDate, isMemberFetched, lastUpdatedMembers } =
    useAppSelector(state => state.dashboard)

  const managerTeam =
    user?.role !== Role.Member
      ? membersData?.filter(member => user?.uid === member?.managerId)
      : []

  const handleChangeMembersType = (
    event: React.MouseEvent<HTMLElement>,
    newMemberType: MembersType,
  ) => {
    setMembersType(newMemberType)
  }

  const handleChange = (event: SyntheticEvent, newValue: number) =>
    setValue(newValue)

  useEffect(() => {
    dispatch(setLastUpdatedMembersAction())
  }, [dispatch])

  const members =
    user?.role !== Role.Member && membersType === 'team'
      ? managerTeam
      : membersData

  const inMembers = members?.filter(
    member => member.currentStatus === UserStatus.In,
  )
  const onBreakMembers = members?.filter(
    member => member.currentStatus === UserStatus.Brb,
  )
  const outMembers = members?.filter(
    member => member.currentStatus === UserStatus.Out,
  )
  const notInMembers = members?.filter(member => !member.currentStatus)

  return (
    <Card sx={styles.card} variant="outlined">
      <CardContent sx={styles.cardContent}>
        <Typography color="text.secondary" sx={styles.cardTitle}>
          Members
        </Typography>
        <Box>
          <Box sx={styles.refresh}>
            <Tooltip placement="right" title="Refresh">
              <Box>
                <IconButton
                  disabled={loader}
                  sx={styles.refreshBtn}
                  onClick={async () => {
                    setLoader(true)
                    await dispatch(getMembersData(attendanceDate))
                    dispatch(setLastUpdatedMembersAction())
                    setLoader(false)
                  }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <Typography sx={styles.lastUpdatedTime}>
              Last Updated: {lastUpdatedMembers}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {user?.role === Role.Member && <Divider />}
      {user?.role !== Role.Member && (
        <ToggleButtonGroup
          exclusive
          fullWidth
          color="primary"
          sx={{
            borderRadius: '0 !important',
            '.MuiToggleButton-root': {
              borderRadius: '0 !important',
            },
          }}
          value={membersType}
          onChange={handleChangeMembersType}
        >
          <ToggleButton sx={{ py: '0.3rem' }} value="team">
            My Team
          </ToggleButton>
          <ToggleButton sx={{ py: '0.3rem' }} value="all">
            All
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {isMemberFetched ? (
        <Box>
          <Box display="flex" justifyContent={'center'}>
            <Tabs
              sx={styles.tabs}
              value={value}
              variant="fullWidth"
              onChange={handleChange}
            >
              <Tab
                icon={
                  <Typography fontWeight={'bold'}>
                    {inMembers.length}
                  </Typography>
                }
                label="In"
                sx={styles.tab}
              />
              <Tab
                icon={
                  <Typography fontWeight={'bold'}>
                    {onBreakMembers.length}
                  </Typography>
                }
                label="Break"
                sx={styles.tab}
              />
              <Tab
                icon={
                  <Typography fontWeight={'bold'}>
                    {outMembers.length}
                  </Typography>
                }
                label="Out"
                sx={styles.tab}
              />
              <Tab
                icon={
                  <Typography fontWeight={'bold'}>
                    {notInMembers.length}
                  </Typography>
                }
                label="Not In"
                sx={styles.tab}
              />
            </Tabs>
          </Box>
          {value === 0 && (
            <Box>
              <UsersList
                listType={UserStatus.In}
                selectedMembersType={membersType}
                userRole={user?.role}
                users={inMembers}
              />
            </Box>
          )}
          {value === 1 && (
            <Box>
              <UsersList users={onBreakMembers} />
            </Box>
          )}
          {value === 2 && (
            <Box>
              <UsersList users={outMembers} />
            </Box>
          )}
          {value === 3 && (
            <Box>
              <UsersList users={notInMembers} />
            </Box>
          )}
        </Box>
      ) : (
        <Box py={3} sx={styles.spinner}>
          <CircularProgress size={20} />
        </Box>
      )}
    </Card>
  )
}

const styles = {
  card: {
    pt: '0.5rem',
  },
  cardTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: '0.25rem',
    mb: '0.35rem',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
  },
  lastUpdatedTime: {
    display: 'flex',
    justifyContent: 'right',
    color: '#757575',
    fontSize: '0.6rem',
    mt: '2px',
    fontStyle: 'italic',
  },
  refreshBtn: {
    p: '1px',
    backgroundColor: colors.green1,
    color: 'white',
    '&:hover': {
      backgroundColor: colors.green1,
      color: 'white',
    },
  },
  refreshIcon: {
    bgColor: colors.green1,
  },
  usersList: {
    height: { xs: 400, md: 462 },
    overflow: 'auto',
  },
  user: { my: 0.2 },
  tabs: { py: 1, px: 0 },
  tab: {
    width: '4.25rem',
    minWidth: '4.25rem',
    px: 0.25,
  },
  noUser: {
    width: { xs: '2.75rem', lg: '2.5rem' },
    height: { xs: '2.75rem', lg: '2.5rem' },
  },
  homeAvatar: {
    backgroundColor: 'transparent',
    color: '#7F8081',
  },
  userName: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
