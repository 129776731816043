import {
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  FilterListTwoTone,
  TodayOutlined,
  WeekendOutlined,
} from '@mui/icons-material'
import {
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  FormControl,
  Box,
  Button,
  Grid,
  Divider,
  Menu,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import { DateFilter, DateFilterLabel, dateFormat } from 'core/constants/enum'

import { setIsLoadingAction } from 'features/activity-feed/reducers/activity-feed'
import { getActivityFeed } from 'features/activity-feed/thunks/activity-feed'
import {
  getEndOfLastWeek,
  getStartOfLastWeek,
  getStartOfThisMonth,
  getStartOfThisWeek,
} from 'features/activity-feed/utils/activity-feed'
import { timestampToYMDFormat } from 'features/dashboard/utils/dashboard'
import { styles } from 'features/reports/components/FilterReport'
import { reportSchema } from 'features/reports/schema'
import { Formik } from 'formik'
import { sortBy as _sortBy } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect, MouseEvent } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const FilterFeed = () => {
  const dispatch = useAppDispatch()
  const { isDarkMode, serverDate } = useAppSelector(state => state.app)
  const [startDate, setStartDate] = useState(
    moment(serverDate).local().format(dateFormat),
  )
  const [endDate, setEndDate] = useState(
    moment(serverDate).local().format(dateFormat),
  )
  const { users } = useAppSelector(state => state.usersManagement)
  const [filterUsers, setFilterUsers] = useState<string[]>([])
  const activeUsers = users.filter(row => row.isActive)

  useEffect(() => {
    if (users.length) {
      setFilterUsers(
        users?.filter(row => row.isActive).map(row => row?.uid ?? ''),
      )
    }
  }, [users])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterLabel, setFilterLabelState] = useState(DateFilterLabel.Default)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const sortedUsers = _sortBy(activeUsers, obj => obj?.name?.toLowerCase())

  const handleSelectAllUsers = () => {
    if (filterUsers.length > 0 && filterUsers.length === sortedUsers.length) {
      setFilterUsers([])
    } else {
      setFilterUsers(activeUsers?.map(row => row?.uid ?? ''))
    }
  }

  const handleUsersChange = (event: SelectChangeEvent<typeof filterUsers>) => {
    const {
      target: { value },
    } = event
    setFilterUsers(typeof value === 'string' ? value.split(',') : value)
  }
  const getUserNameById = (uid: string) => {
    let name = ''
    activeUsers.every(row => {
      if (row.uid === uid) {
        name = row.name
        return false
      }
      return true
    })
    return `${name}, `
  }

  const handleFilter = (dateFilter: string) => {
    const date = serverDate
    let startValue = '',
      endValue = ''
    switch (dateFilter) {
      case DateFilter.Today:
        setFilterLabelState(DateFilterLabel.Today)
        startValue = timestampToYMDFormat(date)
        endValue = startValue
        break
      case DateFilter.ThisWeek:
        setFilterLabelState(DateFilterLabel.ThisWeek)
        startValue = timestampToYMDFormat(getStartOfThisWeek(date))
        endValue = timestampToYMDFormat(date)
        break
      case DateFilter.LastWeek:
        setFilterLabelState(DateFilterLabel.LastWeek)
        startValue = timestampToYMDFormat(getStartOfLastWeek(date))
        endValue = timestampToYMDFormat(getEndOfLastWeek(date))
        break
      case DateFilter.ThisMonth:
        setFilterLabelState(DateFilterLabel.ThisMonth)
        startValue = timestampToYMDFormat(getStartOfThisMonth(date))
        endValue = timestampToYMDFormat(date)
        break
      default:
        break
    }
    setStartDate(startValue)
    setEndDate(endValue)
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleMenu}
        >
          Filter by {filterLabel}
        </Button>
      </Box>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        id="menu-filter"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.Today)
          }}
        >
          <CalendarTodayOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Today</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.ThisWeek)
          }}
        >
          <TodayOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">This Week</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.LastWeek)
          }}
        >
          <WeekendOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Last Week</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.ThisMonth)
          }}
        >
          <CalendarMonthOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">This Month</Typography>
        </MenuItem>
      </Menu>
      <Formik
        enableReinitialize
        initialValues={{
          startDate,
          endDate,
        }}
        validationSchema={reportSchema}
        onSubmit={values => {
          dispatch(setIsLoadingAction(true))
          const myUsers =
            filterUsers.length === sortedUsers.length ? [] : filterUsers
          const fromDate = moment(values.startDate).startOf('day').valueOf()
          const toDate = moment(values.endDate).endOf('day').valueOf()
          dispatch(getActivityFeed({ users: myUsers, fromDate, toDate }))
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Box noValidate component="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth sx={styles.selectUsers}>
                  <Typography
                    color="secondary"
                    fontWeight={'regular'}
                    sx={styles.inputLabel}
                    variant="caption"
                  >
                    Select Users
                  </Typography>
                  <Select
                    multiple
                    id="users-select"
                    labelId="users-select-label"
                    MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                    renderValue={selected => {
                      if (selected.length === sortedUsers.length)
                        return 'All users selected'
                      let uids = ''
                      selected.forEach(uid => (uids += getUserNameById(uid)))
                      return uids
                    }}
                    size="small"
                    value={filterUsers}
                    onChange={handleUsersChange}
                  >
                    <Box>
                      {filterUsers.length > 0 &&
                      filterUsers.length === sortedUsers.length ? (
                        <Typography
                          sx={
                            isDarkMode
                              ? {
                                  backgroundColor: 'rgba(68, 138, 255, 0.16)',
                                  ...styles.selectAll,
                                }
                              : {
                                  backgroundColor: 'rgba(68, 138, 255, 0.08)',
                                  ...styles.selectAll,
                                }
                          }
                          variant="body1"
                          onClick={handleSelectAllUsers}
                        >
                          {filterUsers.length > 0 &&
                          filterUsers.length === sortedUsers.length
                            ? 'Unselect All'
                            : 'Select All'}
                        </Typography>
                      ) : (
                        <Typography
                          sx={styles.selectAll}
                          variant="body1"
                          onClick={handleSelectAllUsers}
                        >
                          {filterUsers.length > 0 &&
                          filterUsers.length === sortedUsers.length
                            ? 'Unselect All'
                            : 'Select All'}
                        </Typography>
                      )}
                      <Divider />
                    </Box>
                    {sortedUsers.map(userInfo => (
                      <MenuItem
                        key={userInfo.uid}
                        sx={styles.menuItem}
                        value={userInfo.uid}
                      >
                        <Checkbox
                          checked={
                            filterUsers.indexOf(userInfo?.uid ?? '') > -1
                          }
                        />
                        <Typography variant="body2">{userInfo.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography
                  color="secondary"
                  fontWeight={'regular'}
                  sx={styles.inputLabelStart}
                  variant="caption"
                >
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableFuture={true}
                    renderInput={params => (
                      <FormikTextField
                        name="startDate"
                        readonly={true}
                        {...params}
                      />
                    )}
                    value={values.startDate}
                    onChange={newVal => setFieldValue('startDate', newVal)}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography
                  color="secondary"
                  fontWeight={'regular'}
                  sx={styles.inputLabelEnd}
                  variant="caption"
                >
                  End Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableFuture={true}
                    renderInput={params => (
                      <FormikTextField
                        name="endDate"
                        readonly={true}
                        {...params}
                      />
                    )}
                    value={values.endDate}
                    onChange={newVal => setFieldValue('endDate', newVal)}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Button sx={styles.generateBtn} type="submit" variant="contained">
              Fetch Feed
            </Button>
          </Box>
        )}
      </Formik>
    </>
  )
}
