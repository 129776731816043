import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'
import { service } from 'core/service/firebase'

export const createUser = createAsyncThunk(
  'users-management/createUser',
  async (
    {
      name,
      email,
      password,
      role,
      managerId,
      isActive,
      userCanCreateWFHRequest,
    }: {
      name: string
      email: string
      password: string
      role: string
      managerId: string
      isActive: boolean
      userCanCreateWFHRequest: boolean
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SEND_EMAIL_ON_CREATE_USER
          ? process.env.REACT_APP_SEND_EMAIL_ON_CREATE_USER
          : '',
        {
          name,
          email,
          password,
          role,
          managerId,
          isActive,
          userCanCreateWFHRequest,
        },
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateUser = createAsyncThunk(
  'users-management/updateUser',
  async (
    {
      uid,
      name,
      email,
      newPassword,
      role,
      managerId,
      isActive,
      userCanCreateWFHRequest,
    }: {
      uid: string
      name: string
      email: string
      newPassword: string
      role: string
      managerId: string
      isActive: boolean
      userCanCreateWFHRequest: boolean
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_UPDATE_USER
          ? process.env.REACT_APP_UPDATE_USER
          : '',
        {
          uid,
          name,
          email,
          newPassword,
          role,
          managerId,
          isActive,
          userCanCreateWFHRequest,
        },
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getAllUsers = createAsyncThunk(
  'users-management/getAllUsers',
  async (role: string, { rejectWithValue }) => {
    try {
      return await service.getAllUsers(role)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
