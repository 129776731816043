import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import {
  CustomRequestsFilterLabel,
  dateTimeFormat,
  LeaveRequestType,
  Role,
  WorkRequestStatus,
  WorkRequestType,
  YMDFormat,
} from 'core/constants/enum'
import { Remarks, User, UserWorkRequest } from 'core/service'
import { timestampToYMDFormat } from 'features/dashboard/utils/dashboard'
import {
  setRequestType,
  setStatusFilter,
} from 'features/work-request/reducers/work-request'
import { workRequestSchema } from 'features/work-request/schema'
import {
  createUserWorkRequest,
  getMedicalDocDownloadURL,
  sendEmailOnRequestCreation,
  updateUserWorkRequest,
} from 'features/work-request/thunks/work-request'
import { ErrorMessage, Formik } from 'formik'
import { capitalize } from 'lodash'
import moment from 'moment'
import React, { ChangeEvent, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { EditedInitialValues } from '../types'
import {
  canAddNewRequest,
  canCreateWorkRequestForToday,
  getCountOfDaysWithoutWeekends,
} from '../utils/work-request'

type CreateOrUpdateRequestFormProps = {
  editedRequestId: string | undefined
  editable: boolean
  editedInitialValues: EditedInitialValues
  onClose: () => void
  type: string
}

const CreateOrUpdateRequestForm: React.FC<
  CreateOrUpdateRequestFormProps
> = props => {
  const fileRef = useRef<HTMLInputElement>(null)
  const { onClose, editable, editedRequestId, editedInitialValues, type } =
    props
  const dispatch = useAppDispatch()
  const { serverDate } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const [loading, setLoading] = useState(false)
  const { myRequests } = useAppSelector(state => state.workRequests)

  const initialValues = {
    fromDate:
      (editable ? editedInitialValues.fromDate : null) ??
      timestampToYMDFormat(serverDate),
    toDate:
      (editable ? editedInitialValues.toDate : null) ??
      timestampToYMDFormat(serverDate),
    reason: (editable ? editedInitialValues.reason : null) ?? '',
    leaveType:
      (editable ? editedInitialValues.leaveType : null) ??
      LeaveRequestType.Annual,
    medicalDoc: (editable ? editedInitialValues.medicalDoc : null) ?? null,
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={workRequestSchema}
      onSubmit={async values => {
        const { uid, managerId } = user as User

        if (editable) {
          setLoading(true)

          const filteredRequest = myRequests.filter(
            req =>
              req.id !== editedRequestId &&
              req.status !== WorkRequestStatus.Rejected &&
              req.status !== WorkRequestStatus.Cancelled,
          )
          if (
            !canAddNewRequest(
              filteredRequest,
              values.fromDate,
              values.toDate,
              type,
            )
          ) {
            toast.warning(
              'You have already created work request that overlaps with this date range.',
            )
          } else {
            const result = await dispatch(
              updateUserWorkRequest({
                uid: uid ?? '',
                docId: editedRequestId ? editedRequestId : '',
                createdAt: editedInitialValues.createdAt,
                fromDate: moment(values.fromDate).format(YMDFormat),
                toDate: moment(values.toDate).format(YMDFormat),
                reason: values.reason.trim(),
                type,
                remarks: editedInitialValues?.remarks
                  ? editedInitialValues.remarks
                  : ([] as Remarks[]),
                medicalDoc: values?.medicalDoc ? values?.medicalDoc : '',
                leaveType:
                  type === WorkRequestType.Leave ? values.leaveType : null,
                requestedBy: user?.name ? user?.name : '',
                managerId: managerId ?? '',
              }),
            )

            const response = result.payload as UserWorkRequest
            const reqId = response.id
            setLoading(false)

            if (
              editedInitialValues.status === WorkRequestStatus.Open &&
              editedInitialValues.remarks?.length
            )
              dispatch(
                sendEmailOnRequestCreation({
                  reqId: reqId ?? '',
                  userId: uid ?? '',
                  managerId: managerId ?? '',
                }),
              )
          }
        } else {
          setLoading(true)
          const filteredRequests = myRequests.filter(
            req =>
              req.status !== WorkRequestStatus.Rejected &&
              req.status !== WorkRequestStatus.Cancelled,
          )
          if (
            !canAddNewRequest(
              filteredRequests,
              values.fromDate,
              values.toDate,
              type,
            )
          ) {
            toast.warning(
              'You have already created work request that overlaps with this date range.',
            )
          } else {
            const result = await dispatch(
              createUserWorkRequest({
                uid: uid ?? '',
                managerId: managerId ?? '',
                fromDate: moment(values.fromDate).format(YMDFormat),
                toDate: moment(values.toDate).format(YMDFormat),
                reason: values.reason.trim(),
                type,
                medicalDoc:
                  typeof values.medicalDoc !== 'string'
                    ? values.medicalDoc
                    : null,
                leaveType:
                  type === WorkRequestType.Leave ? values.leaveType : null,
                requestedBy: user?.name ? user?.name : '',
              }),
            )
            const response = result.payload as UserWorkRequest
            const reqId = response.id
            dispatch(setStatusFilter(WorkRequestStatus.Open))
            if (user?.role !== Role.Member) {
              dispatch(setRequestType(CustomRequestsFilterLabel.MyRequests))
            }
            setLoading(false)
            dispatch(
              sendEmailOnRequestCreation({
                reqId: reqId ? reqId : '',
                userId: uid ?? '',
                managerId: managerId ? managerId : '',
              }),
            )
          }
        }
        onClose()
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Box noValidate component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2.5} sx={{ mt: '0rem' }}>
            <Grid item sx={{ pt: '0rem' }} xs={12}>
              {type === WorkRequestType.Leave && (
                <Box sx={styles.radioGroup}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
                    Leave Type
                  </Typography>
                  <RadioGroup
                    row
                    value={values.leaveType}
                    onChange={e => setFieldValue('leaveType', e.target.value)}
                  >
                    {Object.values(LeaveRequestType).map(value => (
                      <FormControlLabel
                        key={value}
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">{value}</Typography>}
                        value={value}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="fromDate"
                      readonly={true}
                      size="medium"
                      textLabel="From Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.fromDate}
                  onChange={newVal => {
                    setFieldValue('fromDate', newVal)
                    if (moment(newVal).isAfter(moment(values.toDate))) {
                      setFieldValue('toDate', newVal)
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="toDate"
                      readonly={true}
                      size="medium"
                      textLabel="To Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.toDate}
                  onChange={newVal => setFieldValue('toDate', newVal)}
                />
              </LocalizationProvider>
            </Grid>

            {!canCreateWorkRequestForToday(
              user?.allowedWorkRequestDates ?? [],
              values.fromDate,
              values.toDate,
            ) && (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  sx={{ fontWeight: 500, fontSize: '15px' }}
                >
                  You are not allowed to create work request for today. Please
                  contact your manager.
                </Alert>
              </Grid>
            )}

            <Grid item md={6} sx={styles.days} xs={12}>
              <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
                Days Requested :
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                {getCountOfDaysWithoutWeekends(values.fromDate, values.toDate)}{' '}
                day(s)
              </Typography>
            </Grid>

            {editable && (
              <Grid item xs={12}>
                {editedInitialValues.remarks?.length &&
                editedInitialValues.status === WorkRequestStatus.Open ? (
                  <Box sx={styles.field}>
                    <Typography sx={styles.label}>Remarks</Typography>
                    {editedInitialValues.remarks.map(
                      ({ createdAt, remarks, remarksBy }) => {
                        if (remarks)
                          return (
                            <Box key={createdAt} sx={styles.remarks}>
                              <Typography variant="body2">
                                {moment(createdAt).format(dateTimeFormat)} -{' '}
                                {capitalize(remarksBy)}:
                              </Typography>
                              <Typography
                                sx={{ whiteSpace: 'pre-wrap' }}
                                variant="body2"
                              >
                                {remarks}
                              </Typography>
                            </Box>
                          )
                        return <></>
                      },
                    )}
                  </Box>
                ) : null}
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                error={!!(touched.reason && errors.reason)}
                helperText={
                  touched.reason && errors.reason ? errors.reason : ''
                }
                id="reason"
                label="Reason"
                rows={6}
                size="medium"
                type="text"
                value={values.reason}
                onChange={handleChange}
              />
            </Grid>
            {type === WorkRequestType.Leave &&
              values.leaveType === LeaveRequestType.Sick && (
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    Medical Document
                  </Typography>
                  <Card elevation={0} sx={styles.borderedBox}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '0.8rem',
                        mb: '0.65rem',
                      }}
                    >
                      <input
                        ref={fileRef}
                        hidden
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const target = e.target as HTMLInputElement
                          const files = [...Object.values(target.files!)]
                          setFieldValue('medicalDoc', files[0])
                        }}
                      />
                      <Button
                        disabled={!!values.medicalDoc}
                        size="small"
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() =>
                          fileRef.current && fileRef.current.click()
                        }
                      >
                        Choose File
                      </Button>
                      {!values.medicalDoc ||
                      (values.medicalDoc && errors.medicalDoc) ? (
                        <Typography variant="body2">
                          Accepted file Types: img, pdf and doc only
                        </Typography>
                      ) : null}
                    </Box>
                    <Box sx={styles.fileName}>
                      <Typography variant="body2">
                        {values.medicalDoc &&
                          typeof values.medicalDoc === 'string' &&
                          values.medicalDoc}
                        {values.medicalDoc
                          ? typeof values.medicalDoc !== 'string' &&
                            values.medicalDoc.name
                          : 'No file chosen'}
                      </Typography>
                      <Box sx={styles.fileActions}>
                        {values?.medicalDoc &&
                          typeof values.medicalDoc === 'string' && (
                            <Tooltip title="Open file in new Tab">
                              <IconButton
                                size="small"
                                onClick={async () => {
                                  const res = await dispatch(
                                    getMedicalDocDownloadURL({
                                      uid: user?.uid ?? '',
                                      fileName:
                                        typeof values.medicalDoc === 'string'
                                          ? values.medicalDoc
                                          : '',
                                    }),
                                  )
                                  const url = res.payload as string
                                  window.open(url)
                                }}
                              >
                                <OpenInNewIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        {values.medicalDoc && (
                          <Tooltip title="Delete file">
                            <IconButton
                              size="small"
                              onClick={() => setFieldValue('medicalDoc', '')}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>

                    <ErrorMessage
                      name="medicalDoc"
                      render={msg => (
                        <Typography color="error" variant="body2">
                          {`Error: ${msg}`}
                        </Typography>
                      )}
                    />
                  </Card>
                </Grid>
              )}
          </Grid>

          <Box sx={styles.btnBox}>
            <Button
              color="inherit"
              disabled={loading}
              variant="text"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              disabled={
                loading ||
                !canCreateWorkRequestForToday(
                  user?.allowedWorkRequestDates ?? [],
                  values.fromDate,
                  values.toDate,
                )
              }
              startIcon={loading ? <CircularProgress size={14} /> : null}
              type="submit"
              variant="contained"
            >
              {editable ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export default CreateOrUpdateRequestForm

const styles = {
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  startDatePicker: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
  },
  endDatePicker: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
  },
  borderedBox: {
    mt: '0.65rem',
    border: '2px dashed #bdbdbd',
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileName: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.15rem',
  },
  btnBox: {
    mt: '1rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
  label: { fontSize: '13.5px', fontWeight: 500, mb: '0.2rem' },
  field: {
    width: '100%',
    marginBottom: '0.35rem',
  },
  remarks: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    mb: '0.25rem',
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
}
