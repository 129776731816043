import { Groups } from '@mui/icons-material'
import { Box, Container, Typography } from '@mui/material'
import { Role } from 'core/constants/enum'
import Path from 'core/navigation/path'
import MyTeamTable from 'features/my-team/components/MyTeamTable'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

const MyTeam = () => {
  const { user } = useAppSelector(state => state.auth)
  return user?.role !== Role.Member ? (
    <Container sx={styles.root}>
      <Box alignItems="center" display="flex" gap={2}>
        <Groups color="action" sx={{ fontSize: '42px' }} />
        <Typography variant="h4">My Team</Typography>
      </Box>
      <MyTeamTable />
    </Container>
  ) : (
    <Navigate to={Path.Root} />
  )
}

export default MyTeam

const styles = {
  root: { mt: 2.5, p: 0 },
}
