import { createAsyncThunk } from '@reduxjs/toolkit'
import { service } from 'core/service/firebase'

export const getActivityFeed = createAsyncThunk(
  'activity-feed/getActivityFeed',
  (
    {
      users,
      fromDate,
      toDate,
    }: {
      users: string[]
      fromDate: number
      toDate: number
    },
    { rejectWithValue },
  ) => {
    try {
      return service.getActivityFeed(users, fromDate, toDate)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
