import {
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  FilterListTwoTone,
  TodayOutlined,
  WeekendOutlined,
  ApartmentOutlined,
  HomeOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import {
  DateFilter,
  DateFilterLabel,
  dateFormat,
  ReportType,
  WorkLocation,
} from 'core/constants/enum'
import {
  getEndOfLastWeek,
  getStartOfLastWeek,
  getStartOfThisMonth,
  getStartOfThisWeek,
} from 'features/activity-feed/utils/activity-feed'
import { timestampToYMDFormat } from 'features/dashboard/utils/dashboard'
import { reportSchema } from 'features/reports/schema'
import { getReports } from 'features/reports/thunks/reports'
import { getAllUsers } from 'features/users-management/thunks/users-management'
import { Form, Formik } from 'formik'
import { sortBy as _sortBy } from 'lodash'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type ComponentProps = {
  resetPage: () => void
}

export const FilterReport: React.FC<ComponentProps> = ({ resetPage }) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { isDarkMode, serverDate } = useAppSelector(state => state.app)
  const [startDate, setStartDate] = useState(timestampToYMDFormat(serverDate))
  const [endDate, setEndDate] = useState(timestampToYMDFormat(serverDate))
  const { users } = useAppSelector(state => state.usersManagement)
  const activeUsers = users?.filter(row => row?.isActive)

  const theme = useTheme()

  useEffect(() => {
    setStartDate(timestampToYMDFormat(serverDate))
    setEndDate(timestampToYMDFormat(serverDate))
    dispatch(getAllUsers(user?.role ?? ''))
  }, [dispatch, serverDate, user?.role])

  const [locationAnchorEl, setLocationAnchorEl] = useState<null | HTMLElement>(
    null,
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterLabel, setFilterLabelState] = useState(DateFilterLabel.Default)
  const [locationFilter, setLocationFilter] = useState('All')

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenLocationMenu = (event: MouseEvent<HTMLElement>) => {
    setLocationAnchorEl(event.currentTarget)
  }
  const handleCloseLocationAnchor = () => {
    setLocationAnchorEl(null)
  }

  const sortedUsers = _sortBy(activeUsers, obj => obj.name?.toLowerCase())

  const [selectUsersError, setSelectUsersError] = useState(false)
  const [filterUsers, setFilterUsers] = useState<string[]>([])
  const [reportType, setReportType] = useState('')

  const handleSelectAllUsers = () => {
    if (filterUsers.length > 0 && filterUsers.length === sortedUsers.length) {
      setFilterUsers([])
      setSelectUsersError(true)
    } else {
      setFilterUsers(activeUsers?.map(row => row?.uid ?? ''))
      setSelectUsersError(false)
    }
  }

  const handleUsersChange = (event: SelectChangeEvent<typeof filterUsers>) => {
    const {
      target: { value },
    } = event
    setFilterUsers(typeof value === 'string' ? value.split(',') : value)
    if (value.length > 0) setSelectUsersError(false)
    else setSelectUsersError(true)
  }

  const handleFilter = (dateFilter: string) => {
    const date = serverDate
    let startValue = '',
      endValue = ''
    switch (dateFilter) {
      case DateFilter.Today:
        setFilterLabelState(DateFilterLabel.Today)
        startValue = timestampToYMDFormat(date)
        endValue = startValue
        break
      case DateFilter.ThisWeek:
        setFilterLabelState(DateFilterLabel.ThisWeek)
        startValue = timestampToYMDFormat(getStartOfThisWeek(date))
        endValue = timestampToYMDFormat(date)
        break
      case DateFilter.LastWeek:
        setFilterLabelState(DateFilterLabel.LastWeek)
        startValue = timestampToYMDFormat(getStartOfLastWeek(date))
        endValue = timestampToYMDFormat(getEndOfLastWeek(date))
        break
      case DateFilter.ThisMonth:
        setFilterLabelState(DateFilterLabel.ThisMonth)
        startValue = timestampToYMDFormat(getStartOfThisMonth(date))
        endValue = timestampToYMDFormat(date)
        break
      default:
        break
    }
    setStartDate(startValue)
    setEndDate(endValue)
  }

  const getUserNameById = (uid: string) => {
    let name = ''
    activeUsers.every(row => {
      if (row.uid === uid) {
        name = row.name
        return false
      }
      return true
    })
    return `${name}, `
  }

  const handleSubmit = (values: { startDate: string; endDate: string }) => {
    if (filterUsers.length < 1) {
      setSelectUsersError(true)
      return
    }
    if (!selectUsersError) {
      resetPage()
      dispatch(
        getReports({
          uids: filterUsers,
          fromDate: moment(values.startDate).startOf('day').valueOf(),
          toDate: moment(values.endDate).endOf('day').valueOf(),
          reportType,
          workLocation: locationFilter,
        }),
      )
    }
  }

  return (
    <>
      <Box display="flex" gap={2} justifyContent="flex-end" mb={1} mt={3}>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleOpenLocationMenu}
        >
          Filter by {locationFilter}
        </Button>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleMenu}
        >
          Filter by {filterLabel}
        </Button>
      </Box>
      <Menu
        keepMounted
        anchorEl={locationAnchorEl}
        id="menu-filter-1"
        open={Boolean(locationAnchorEl)}
        onClose={handleCloseLocationAnchor}
      >
        <MenuItem
          onClick={() => {
            handleCloseLocationAnchor()
            setLocationFilter('All')
          }}
        >
          <Typography variant="body2">All</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseLocationAnchor()
            setLocationFilter(WorkLocation.Office)
          }}
        >
          <ApartmentOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Office</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseLocationAnchor()
            setLocationFilter(WorkLocation.Home)
          }}
        >
          <HomeOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Home</Typography>
        </MenuItem>
      </Menu>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        id="menu-filter"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.Today)
          }}
        >
          <CalendarTodayOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Today</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.ThisWeek)
          }}
        >
          <TodayOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">This Week</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.LastWeek)
          }}
        >
          <WeekendOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">Last Week</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            handleFilter(DateFilter.ThisMonth)
          }}
        >
          <CalendarMonthOutlined sx={styles.filterMenuIcon} />
          <Typography variant="body2">This Month</Typography>
        </MenuItem>
      </Menu>
      <Formik
        enableReinitialize
        initialValues={{
          startDate,
          endDate,
        }}
        validationSchema={reportSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth sx={styles.selectUsers}>
                  <Typography
                    color="secondary"
                    fontWeight={'regular'}
                    sx={styles.inputLabel}
                    variant="caption"
                  >
                    Select Users
                  </Typography>
                  <Select
                    multiple
                    error={selectUsersError}
                    id="users-select"
                    labelId="users-select-label"
                    MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                    renderValue={selected => {
                      if (selected.length === sortedUsers.length)
                        return 'All users selected'
                      let uids = ''
                      selected.forEach(uid => (uids += getUserNameById(uid)))
                      return uids
                    }}
                    size="small"
                    value={filterUsers}
                    onChange={handleUsersChange}
                  >
                    <Box>
                      {filterUsers.length > 0 &&
                      filterUsers.length === sortedUsers.length ? (
                        <Typography
                          sx={
                            isDarkMode
                              ? {
                                  backgroundColor: 'rgba(68, 138, 255, 0.16)',
                                  ...styles.selectAll,
                                }
                              : {
                                  backgroundColor: 'rgba(68, 138, 255, 0.08)',
                                  ...styles.selectAll,
                                }
                          }
                          variant="body1"
                          onClick={handleSelectAllUsers}
                        >
                          {filterUsers.length > 0 &&
                          filterUsers.length === sortedUsers.length
                            ? 'Unselect All'
                            : 'Select All'}
                        </Typography>
                      ) : (
                        <Typography
                          sx={styles.selectAll}
                          variant="body1"
                          onClick={handleSelectAllUsers}
                        >
                          {filterUsers.length > 0 &&
                          filterUsers.length === sortedUsers.length
                            ? 'Unselect All'
                            : 'Select All'}
                        </Typography>
                      )}
                      <Divider />
                    </Box>
                    {sortedUsers.map(userInfo => (
                      <MenuItem
                        key={userInfo.uid}
                        sx={styles.menuItem}
                        value={userInfo.uid}
                      >
                        <Checkbox
                          checked={
                            filterUsers.indexOf(userInfo?.uid ?? '') > -1
                          }
                        />
                        <Typography variant="body2">{userInfo.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  {selectUsersError && (
                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                      At least one user is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography
                  color="secondary"
                  fontWeight={'regular'}
                  sx={styles.inputLabelStart}
                  variant="caption"
                >
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableFuture={true}
                    renderInput={params => (
                      <FormikTextField
                        name="startDate"
                        readonly={true}
                        {...params}
                      />
                    )}
                    value={values.startDate}
                    onChange={newVal => {
                      setFieldValue('startDate', newVal)
                      setStartDate(moment(newVal).format(dateFormat))
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography
                  color="secondary"
                  fontWeight={'regular'}
                  sx={styles.inputLabelEnd}
                  variant="caption"
                >
                  End Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableFuture={true}
                    renderInput={params => (
                      <FormikTextField
                        name="endDate"
                        readonly={true}
                        {...params}
                      />
                    )}
                    value={values.endDate}
                    onChange={newVal => {
                      setFieldValue('endDate', newVal)
                      setEndDate(moment(newVal).format(dateFormat))
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="space-between"
                mb={3.5}
                mt={3}
                xs={12}
              >
                <Button
                  sx={{ mr: 1.5 }}
                  type="button"
                  variant="contained"
                  onClick={async () => {
                    setLocationFilter('All')
                    setReportType(ReportType.Date)
                    setStartDate(timestampToYMDFormat(serverDate))
                    setEndDate(timestampToYMDFormat(serverDate))
                    setFilterUsers(activeUsers?.map(row => row?.uid ?? ''))
                    setSelectUsersError(false)
                    await submitForm()
                  }}
                >
                  All User Report For Today
                </Button>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                  <Button
                    sx={{ mr: 1.5 }}
                    type="submit"
                    variant="contained"
                    onClick={() => setReportType(ReportType.Date)}
                  >
                    Generate Report By Date
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => setReportType(ReportType.User)}
                  >
                    Generate Report By User
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export const styles = {
  selectAll: {
    px: 2.5,
    py: 1.25,
    '&:hover': { cursor: 'pointer', color: 'gray' },
  },
  generateBtn: { float: 'right', mt: 3 },
  startDatePicker: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
    pl: { md: 0, sm: 0.75, xs: 0 },
    pr: { xs: 0.75, sm: 0, md: 1.5 },
  },
  endDatePicker: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
    pl: { xs: 0.75, sm: 0 },
    pr: { md: 0, sm: 0.75, xs: 0 },
  },
  selectUsers: { pt: '0.25rem', pr: { md: 1.5, sm: 0.75, xs: 0 } },
  menuItem: { px: 0.75, py: 0.125 },

  inputLabel: {
    ml: 1,
  },
  inputLabelStart: {
    ml: { xs: 1, sm: 2, md: 1 },
  },
  inputLabelEnd: {
    ml: { xs: 2, sm: 1 },
  },
  filterMenuIcon: {
    mr: 1.5,
    my: 0.5,
    width: '1rem',
    height: '1rem',
  },
}
