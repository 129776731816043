import Path from 'core/navigation/path'
import { User } from 'core/service'
import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

type RouterProps = {
  user: User | null
  children: ReactElement
}

export const PublicRoute = ({ user, children }: RouterProps): ReactElement => {
  if (user) {
    return <Navigate replace to={Path.Dashboard} />
  }
  return children
}
