import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Role } from 'core/constants/enum'
import { EmailConfigurationsSchema } from 'features/settings/schema'
import {
  getEmailConfigSetting,
  saveEmailConfigSetting,
} from 'features/settings/thunks/settings'
import { getAllUsers } from 'features/users-management/thunks/users-management'
import { Formik } from 'formik'
import { find } from 'lodash'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const EmailConfigurationForm = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { users } = useAppSelector(state => state.usersManagement)
  const { config, isLoading } = useAppSelector(state => state.settings)

  const filteredUsers = users.filter(row => row.role === Role.Admin)

  const initialValues = {
    smtpServer: config?.smtpServer ?? '',
    smtpPassword: config?.smtpPassword ?? '',
    smtpEmail: config?.smtpEmail ?? '',
    smtpPort: config?.smtpPort ?? '',
    emailRecipients: config?.emailRecipients ?? ([] as string[]),
  }

  useEffect(() => {
    const role = user?.role ? user?.role : ''
    dispatch(getAllUsers(role))
    dispatch(getEmailConfigSetting(role))
  }, [dispatch, user])

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={EmailConfigurationsSchema}
      onSubmit={values => {
        dispatch(
          saveEmailConfigSetting({
            docId: config?.id ? config?.id : '',
            ...values,
          }),
        )
      }}
    >
      {({
        values,
        touched,
        setFieldValue,
        errors,
        handleSubmit,
        handleChange,
      }) => (
        <Box
          noValidate
          component="form"
          sx={{ mt: '1.5rem' }}
          onSubmit={handleSubmit}
        >
          <Card sx={styles.card} variant="outlined">
            <Typography sx={{ fontWeight: '500' }} variant="body1">
              Email Configuration Details
            </Typography>
            <Grid container spacing={2} sx={{ mt: '0.3rem' }}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.smtpEmail && errors.smtpEmail)}
                  helperText={
                    touched.smtpEmail && errors.smtpEmail
                      ? errors.smtpEmail
                      : ''
                  }
                  label="Sender Email"
                  name="smtpEmail"
                  type="email"
                  value={values.smtpEmail}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.smtpPassword && errors.smtpPassword)}
                  helperText={
                    touched.smtpPassword && errors.smtpPassword
                      ? errors.smtpPassword
                      : ''
                  }
                  label="SMTP Password"
                  name="smtpPassword"
                  type="text"
                  value={values.smtpPassword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.smtpServer && errors.smtpServer)}
                  helperText={
                    touched.smtpServer && errors.smtpServer
                      ? errors.smtpServer
                      : ''
                  }
                  label="SMTP Server"
                  name="smtpServer"
                  type="text"
                  value={values.smtpServer}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={!!(touched.smtpPort && errors.smtpPort)}
                  helperText={
                    touched.smtpPort && errors.smtpPort ? errors.smtpPort : ''
                  }
                  label="SMTP Port"
                  name="smtpPort"
                  type="text"
                  value={values.smtpPort}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: '500' }} variant="body1">
                  Email Recipients
                </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={!!(touched.emailRecipients && errors.emailRecipients)}
                >
                  <InputLabel id="demo-simple-select-error-label">
                    Recipients
                  </InputLabel>
                  <Select
                    multiple
                    id="demo-simple-select-error"
                    label="Recipients"
                    labelId="demo-simple-select-error-label"
                    MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                    renderValue={selected => {
                      let names = ''
                      selected.map(value => {
                        const object = find(filteredUsers, {
                          uid: value,
                        })
                        return (names += object?.name
                          ? object?.name + ', '
                          : '')
                      })
                      return names
                    }}
                    value={values.emailRecipients}
                    onChange={e => {
                      const value = e.target.value
                      setFieldValue(
                        'emailRecipients',
                        typeof value === 'string' ? value.split(',') : value,
                      )
                    }}
                  >
                    {filteredUsers.map(userInfo => (
                      <MenuItem
                        key={userInfo.uid}
                        value={userInfo?.uid ? userInfo?.uid : ''}
                      >
                        <Checkbox
                          checked={
                            values.emailRecipients.indexOf(
                              userInfo?.uid ? userInfo?.uid : '',
                            ) > -1
                          }
                          size="small"
                        />
                        <Typography variant="body2">{`${userInfo.name} [${userInfo.role}]`}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.emailRecipients && errors.emailRecipients && (
                    <FormHelperText>{errors.emailRecipients}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={styles.btnBox}>
              <Button disabled={isLoading} type="submit" variant="contained">
                Save
              </Button>
            </Box>
          </Card>
        </Box>
      )}
    </Formik>
  )
}

export default EmailConfigurationForm

const styles = {
  card: {
    p: '1rem',
  },
  btnBox: {
    mt: '1rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
  field: {
    width: '100%',
  },
  selectAll: {
    px: 2.5,
    py: 1.25,
    '&:hover': { cursor: 'pointer', color: 'gray' },
  },
}
