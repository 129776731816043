import {
  LogoutOutlined,
  MenuOpenOutlined,
  PasswordOutlined,
  PersonOutlineOutlined,
  ShortText,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import {
  List,
  ListItem,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Typography,
  useTheme,
  Alert,
  TextField,
  Button,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import colors from 'core/constants/color'
import {
  ErrorAlertTimeout,
  Role,
  Status,
  SuccessAlertTimeout,
  UserStatus,
} from 'core/constants/enum'
import Path from 'core/navigation/path'
import { getRoutesBasedOnUserRole } from 'core/utls'
import { setMobileDrawerOpen } from 'features/app/reducers/app'
import { setChangePasswordMessageAction } from 'features/landing/reducers/auth'
import { changePasswordSchema } from 'features/landing/schema'
import { changePassword, signout } from 'features/landing/thunks/auth'
import { Formik } from 'formik'
import React, { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import ProfileAvatar from './ProfileAvatar'

export const Sidebar = ({ selected }: { selected: string }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)

  const routes = getRoutesBasedOnUserRole(user?.role ?? '')
  const sidebarRoutes = routes?.filter(
    route => route.isPrivate && route.isSidebarItem,
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { userActivity } = useAppSelector(state => state.dashboard)
  const { changePasswordMessage } = useAppSelector(state => state.auth)
  const [showPassword, setPasswordVisibility] = useState(false)
  const [showConfirmPassword, setConfirmPasswordVisibility] = useState(false)
  const [showCurrentPassword, setCurrentPasswordVisibility] = useState(false)

  const [signingOut, setSigningOut] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const openModal = () => setIsEditModalOpen(true)
  const closeModal = () => setIsEditModalOpen(false)

  if (changePasswordMessage) {
    setTimeout(
      () => {
        const status = changePasswordMessage.severity
        dispatch(setChangePasswordMessageAction(''))
        if (status === Status.Success) closeModal()
      },
      changePasswordMessage.severity === Status.Error
        ? ErrorAlertTimeout
        : SuccessAlertTimeout,
    )
  }

  const handleSignOut = async () => {
    setSigningOut(true)
    dispatch(setMobileDrawerOpen(false))
    await dispatch(signout(user))
    setSigningOut(false)
    navigate(Path.Root)
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        ...styles.sidebarItems,
      }}
    >
      <IconButton
        sx={styles.menuIconForMobile}
        onClick={() => dispatch(setMobileDrawerOpen(false))}
      >
        <MenuOpenOutlined fontSize="large" />
      </IconButton>
      <ShortText sx={styles.menuIcon} />
      <List>
        {sidebarRoutes?.map(route => (
          <ListItem key={route.path} sx={styles.listItem}>
            <Tooltip title={route.title || ''}>
              <IconButton
                color="inherit"
                sx={route.path === selected ? styles.selectedIconButton : {}}
                onClick={() => {
                  dispatch(setMobileDrawerOpen(false))
                  if (
                    route.path === Path.WorkRequest &&
                    user?.role !== Role.Member
                  ) {
                    navigate(`${route.path}?type=my-requests`)
                  } else {
                    navigate(route.path)
                  }
                }}
              >
                {route.isCustomIcon === true ? (
                  <img
                    alt="nav-bar-icon"
                    height="16px"
                    src={route.path === selected ? route.icon2 : route.icon}
                    style={
                      route.path === selected
                        ? styles.selectedSidebarIcon
                        : { ...styles.sidebarIcon, opacity: '100%' }
                    }
                    width="16px"
                  />
                ) : (
                  <route.icon
                    sx={
                      route.path === selected
                        ? styles.selectedSidebarIcon
                        : styles.sidebarIcon
                    }
                  />
                )}
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="user-account"
        color="inherit"
        sx={styles.profileIcon}
        onClick={handleMenu}
      >
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent=" "
          color={
            getStatusBasedColor(userActivity?.status ?? '') as
              | 'success'
              | 'warning'
              | 'error'
              | 'secondary'
          }
          overlap="circular"
          variant="dot"
        >
          <ProfileAvatar
            alt={user?.name ?? ''}
            imageName={user?.profilePicture ?? ''}
            profileUrl={user?.photoUrl ?? ''}
            styles={styles.avatar}
            variant="circular"
          />
        </Badge>
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="menu-appbar"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch(setMobileDrawerOpen(false))
            navigate(`/users/${user ? user.uid : ''}`)
          }}
        >
          <PersonOutlineOutlined sx={styles.sideMenuIcon} />
          <Typography variant="body2">View Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch(setMobileDrawerOpen(false))
            openModal()
          }}
        >
          <PasswordOutlined sx={styles.sideMenuIcon} />
          <Typography variant="body2">Change Password</Typography>
        </MenuItem>
        <Dialog maxWidth={'xs'} open={isEditModalOpen} onClose={closeModal}>
          <DialogTitle component={'h2'} mb={1.5} variant="h6">
            Change Password
          </DialogTitle>
          <DialogContent>
            <Box>
              {changePasswordMessage.message && (
                <Alert
                  severity={
                    changePasswordMessage.severity as 'success' | 'error'
                  }
                  sx={styles.alert}
                >
                  {changePasswordMessage.message}
                </Alert>
              )}
              <Formik
                initialValues={{
                  currentPassword: '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={changePasswordSchema}
                onSubmit={values => {
                  dispatch(setChangePasswordMessageAction(''))
                  dispatch(
                    changePassword({
                      currentPassword: values.currentPassword,
                      password: values.password,
                    }),
                  )
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <Box noValidate component="form" onSubmit={handleSubmit}>
                    <Box sx={styles.editPassword}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        error={
                          !!(touched.currentPassword && errors.currentPassword)
                        }
                        helperText={
                          touched.currentPassword && errors.currentPassword
                            ? errors.currentPassword
                            : ''
                        }
                        id="currentPassword"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={styles.passwordVisibilityIcon}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setCurrentPasswordVisibility(
                                    !showCurrentPassword,
                                  )
                                }
                              >
                                {showCurrentPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="Current Password"
                        margin="normal"
                        type={showCurrentPassword ? 'text' : 'password'}
                        value={values.currentPassword}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        autoComplete="off"
                        error={!!(touched.password && errors.password)}
                        helperText={
                          touched.password && errors.password
                            ? errors.password
                            : ''
                        }
                        id="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={styles.passwordVisibilityIcon}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setPasswordVisibility(!showPassword)
                                }
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="New Password"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange}
                      />
                      <TextField
                        fullWidth
                        autoComplete="off"
                        error={
                          !!(touched.confirmPassword && errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                            ? errors.confirmPassword
                            : ''
                        }
                        id="confirmPassword"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={styles.passwordVisibilityIcon}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setConfirmPasswordVisibility(
                                    !showConfirmPassword,
                                  )
                                }
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        label="Confirm Password"
                        margin="normal"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box sx={styles.modalBtn}>
                      <Button
                        color="inherit"
                        variant="text"
                        onClick={closeModal}
                      >
                        Close
                      </Button>
                      <Button
                        sx={styles.saveBtn}
                        type="submit"
                        variant="contained"
                      >
                        Change
                      </Button>
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
        <MenuItem disabled={signingOut} onClick={handleSignOut}>
          <LogoutOutlined sx={styles.sideMenuIcon} />
          <Typography variant="body2">
            {signingOut ? 'Signing Out...' : 'Sign Out'}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

const styles = {
  sideMenuIcon: {
    mr: 1.5,
    my: 0.5,
    width: '1rem',
    height: '1rem',
  },
  listItem: {
    my: 1,
  },
  welcomeUser: {
    '&:hover': { cursor: 'default', backgroundColor: 'transparent' },
    mb: 1,
  },
  sidebarItems: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editPassword: {
    mb: 6,
    px: { xs: 1, sm: 4 },
  },
  selectedIconButton: {
    background: colors.indigo,
    '&:hover': {
      background: colors.indigo,
    },
  },
  sidebarIcon: {
    width: '1.6rem',
    height: '1.6rem',
    opacity: '50%',
  },
  selectedSidebarIcon: {
    width: '1.6rem',
    height: '1.6rem',
    color: 'white',
  },
  passwordVisibilityIcon: {
    ml: 0,
  },
  modalBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  saveBtn: {
    ml: 1.5,
  },
  alert: {
    mb: 2,
  },
  menuIcon: {
    my: 2.5,
    width: '2rem',
    height: '2rem',
    opacity: '50%',
    display: { xs: 'none', sm: 'flex' },
  },
  menuIconForMobile: {
    my: 2.5,
    display: { xs: 'flex', sm: 'none' },
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
  },
  profileIcon: {
    my: 2.5,
  },
}

const getStatusBasedColor = (status: string) => {
  if (status === UserStatus.In) return 'success'
  else if (status === UserStatus.Brb) return 'warning'
  else if (status === UserStatus.Out) return 'error'
  return 'secondary'
}
