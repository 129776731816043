import { Circle, Timer } from '@mui/icons-material'
import {
  List,
  Paper,
  Card,
  CardContent,
  ListItem,
  Box,
  Typography,
  Divider,
} from '@mui/material'
import colors from 'core/constants/color'
import { UserStatus } from 'core/constants/enum'
import { Interval } from 'core/service'
import { UserActivityActions } from 'features/dashboard/components/UserActivityActions'
import {
  milliSecondsToHMFormat,
  calculateBreakInMilliSeconds,
  timestampToHMFormat,
} from 'features/dashboard/utils/dashboard'
import { identity } from 'lodash'
import React from 'react'
import { useAppSelector } from 'store/hooks'
import { UserBreakActivityActions } from './UserBreakActivityActions'

export const UserActivityList = () => {
  const { userActivity } = useAppSelector(state => state.dashboard)

  return (
    <Card sx={styles.card} variant="outlined">
      <CardContent sx={styles.cardContent}>
        <Typography color="text.secondary" sx={styles.cardTitle}>
          My Activity
        </Typography>
      </CardContent>
      <Divider />
      <Paper square sx={styles.listContainer}>
        {userActivity.start === 0 ? (
          <Box
            alignItems="center"
            display="flex"
            flexDirection={'column'}
            height={'100%'}
            justifyContent="center"
            pb={{ xs: 10, md: 0 }}
          >
            <Timer color="secondary" sx={styles.noUser} />
            <Typography color="secondary" variant="subtitle2">
              No Activity
            </Typography>
          </Box>
        ) : (
          <List>
            {userActivity.start !== 0 && (
              <ListItem>
                <Box sx={styles.listItemText}>
                  <Circle
                    fontSize="inherit"
                    sx={{ ...styles.circleIcon, color: colors.green }}
                  />
                  <Box sx={styles.myStatus}>
                    <Typography fontWeight={'bold'}>In</Typography>
                    <Typography
                      ml={1}
                      textTransform="uppercase"
                      variant="body2"
                    >
                      {timestampToHMFormat(userActivity?.start ?? 0)}
                    </Typography>
                  </Box>
                  <UserActivityActions
                    action={UserStatus.In}
                    index={-1}
                    time={userActivity?.start ?? 0}
                  />
                </Box>
              </ListItem>
            )}
            {userActivity?.intervals?.map((interval, index) => (
              <div key={identity(`interval-${index}`)}>
                <ActivityListItem index={index} interval={interval} />
              </div>
            ))}
            {userActivity.end !== 0 && (
              <div>
                <Divider component="li" />
                <ListItem>
                  <Box sx={styles.listItemText}>
                    <Circle
                      fontSize="inherit"
                      sx={{ ...styles.circleIcon, color: colors.red }}
                    />
                    <Box sx={styles.myStatus}>
                      <Typography fontWeight={'bold'}>Out</Typography>
                      <Typography
                        ml={1}
                        textTransform="uppercase"
                        variant="body2"
                      >
                        {timestampToHMFormat(userActivity?.end ?? 0)}
                      </Typography>
                    </Box>
                    <UserActivityActions
                      action={UserStatus.Out}
                      index={-1}
                      time={userActivity?.end ?? 0}
                    />
                  </Box>
                </ListItem>
              </div>
            )}
          </List>
        )}
      </Paper>
    </Card>
  )
}

const ActivityListItem = ({
  interval,
  index,
}: {
  interval: Interval
  index: number
}) => (
  <div>
    <Divider />
    <ListItem>
      <Box sx={styles.listItemText}>
        <Box py={1}>
          {interval.start !== 0 && (
            <Box sx={styles.listItemText}>
              <Circle
                fontSize="inherit"
                sx={{ ...styles.circleIcon, color: colors.yellow }}
              />
              <Box sx={styles.myStatus}>
                <Typography fontWeight={'bold'}>Will be right back</Typography>
                <Typography ml={1} textTransform="uppercase" variant="body2">
                  {timestampToHMFormat(interval.start)}
                </Typography>
              </Box>
            </Box>
          )}
          {interval.end !== 0 && (
            <Box sx={styles.listItemTextEnd}>
              <Circle
                fontSize="inherit"
                sx={{ ...styles.circleIcon, color: colors.green }}
              />
              <Box sx={styles.myStatus}>
                <Typography fontWeight={'bold'}>
                  Back after{' '}
                  {milliSecondsToHMFormat(
                    calculateBreakInMilliSeconds(interval.start, interval.end),
                  )}
                </Typography>
                <Typography ml={1} textTransform="uppercase" variant="body2">
                  {timestampToHMFormat(interval.end)}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {interval.end === 0 ? (
          <UserActivityActions
            action={UserStatus.Brb}
            index={index}
            time={interval.start}
          />
        ) : (
          <UserBreakActivityActions
            endTime={interval.end}
            index={index}
            startTime={interval.start}
          />
        )}
      </Box>
    </ListItem>
  </div>
)

const styles = {
  breakStart: { pb: 0 },
  breakEnd: { pt: 0 },
  card: {
    pt: '0.5rem',
  },
  cardTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    py: '0.25rem',
    mb: '0.35rem',
  },
  listContainer: {
    height: { xs: 400, md: 550 },
    overflow: 'auto',
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  listItemTextEnd: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    pt: 0.75,
  },
  myStatus: {
    display: 'flex',
    alignItems: 'baseline',
  },
  circleIcon: { mr: 1 },
  noUser: {
    width: { xs: '2.5rem', lg: '2.25rem' },
    height: { xs: '2.75rem', lg: '2.5rem' },
  },
}
