import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import ConfirmModal from 'core/components/ConfirmModal'
import { Spinner } from 'core/components/Spinner'
import { dateFormat } from 'core/constants/enum'
import { GazettedHoliday } from 'core/service'
import baseTheme from 'core/theme/base'
import { getCountOfDaysWithoutWeekends } from 'features/work-request/utils/work-request'
import moment from 'moment'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { deleteGazettedHoliday } from '../thunks/settings'

type TableProps = {
  openEditModal: ({
    id,
    startDate,
    endDate,
    holidayTitle,
  }: GazettedHoliday) => void
}
const GazettedHolidaysTable: React.FC<TableProps> = props => {
  const { openEditModal } = props
  const dispatch = useAppDispatch()
  const [deletedId, setDeletedId] = useState<string>('')
  const [page, setPage] = useState(0)
  const rowsPerPage = 10

  const { isDarkMode } = useAppSelector(state => state.app)
  const { gazettedHolidays, isLoading } = useAppSelector(
    state => state.settings,
  )

  const handleCloseDeleteModal = () => {
    setDeletedId('')
  }

  const handleDelete = () => {
    if (deletedId) dispatch(deleteGazettedHoliday(deletedId))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  if (isLoading)
    return (
      <Box sx={{ mt: '1.5rem' }}>
        <Spinner />
      </Box>
    )

  return (
    <Box sx={{ mt: '2.5rem' }}>
      {gazettedHolidays.length && !isLoading ? (
        <Paper
          sx={isDarkMode ? styles.tableDark : styles.tableLight}
          variant="outlined"
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeader}>Title</TableCell>
                  <TableCell sx={styles.tableHeader}>Total Days</TableCell>
                  <TableCell sx={styles.tableHeader}>Start Date</TableCell>
                  <TableCell sx={styles.tableHeader}>End Date</TableCell>
                  <TableCell sx={styles.tableHeader}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gazettedHolidays
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.holidayTitle}</TableCell>
                      <TableCell>
                        {getCountOfDaysWithoutWeekends(
                          row.startDate,
                          row.endDate,
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(row.startDate).format(dateFormat)}
                      </TableCell>
                      <TableCell>
                        {moment(row.endDate).format(dateFormat)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit Holiday">
                          <IconButton
                            sx={styles.editBtn}
                            onClick={() => openEditModal(row)}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Holiday">
                          <IconButton
                            sx={styles.delBtn}
                            onClick={() => setDeletedId(row.id ?? '')}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box sx={styles.borderedBox}>
          <Typography sx={styles.notFound} variant="h6">
            No Holiday Found
          </Typography>
        </Box>
      )}
      {gazettedHolidays.length > rowsPerPage && (
        <TablePagination
          component="div"
          count={gazettedHolidays.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          onPageChange={handleChangePage}
        />
      )}
      <ConfirmModal
        closeModal={handleCloseDeleteModal}
        dialogText="Do you really want to delete this holiday?"
        dialogTitle="Delete Gazetted Holiday"
        handleConfirm={handleDelete}
        open={Boolean(deletedId)}
      />
    </Box>
  )
}

export default GazettedHolidaysTable

const styles = {
  tableDark: {
    border: '0.031rem solid rgba(255, 255, 255, 10%)',
    mt: '1rem',
  },
  tableLight: {
    border: '0.031rem solid rgba(0, 0, 0, 10%)',
    mt: '1rem',
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
  },
  editBtn: {
    '&:hover': { color: baseTheme.palette.primary.main },
  },
  delBtn: {
    '&:hover': { color: '#d91f07' },
  },
  borderedBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #bdbdbd',
    mt: '2.5rem',
    p: '1.5rem',
  },
  tableHeader: {
    fontSize: '0.9rem',
  },
}
