import { firestore } from 'core/config/firebase'
import { Collection } from 'core/constants/collection'
import { User } from 'core/service'
import MyTeamService from 'features/my-team/service'
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

class TeamService implements MyTeamService {
  async getMyTeam(uid: string): Promise<Array<User>> {
    const collectionRef = collection(firestore, Collection.Users)
    const queryRef = query(
      collectionRef,
      where('managerId', '==', uid),
      where('isActive', '==', true),
    )
    const collectionSnap = await getDocs(queryRef)
    return collectionSnap.docs.map(
      document =>
        ({
          uid: document.id,
          ...document.data(),
        } as User),
    )
  }

  async enableUserToCreateWorkRequestForToday(
    uid: string,
    allowedDate: string,
  ): Promise<User> {
    const collectionRef = collection(firestore, Collection.Users)
    const docRef = doc(collectionRef, uid)
    const docSnap = await getDoc(docRef)
    const user = { uid: docSnap.id, ...docSnap.data() } as User
    const updatedDates = user?.allowedWorkRequestDates?.length
      ? [...user.allowedWorkRequestDates, allowedDate]
      : [allowedDate]
    await updateDoc(docRef, {
      allowedWorkRequestDates: updatedDates,
    })
    return { ...user, allowedWorkRequestDates: updatedDates }
  }

  async checkIfMemberRequestAlreadyCreatedForToday(
    uid: string,
    date: string,
  ): Promise<boolean> {
    const collectionRef = collection(firestore, Collection.WorkRequests)
    const queryRef = query(
      collectionRef,
      and(
        where('userId', '==', uid),
        or(where('fromDate', '==', date), where('toDate', '==', date)),
      ),
    )
    const colSnap = await getDocs(queryRef)
    const requests = colSnap.docs.map(d => ({ id: d.id, ...d.data() }))
    return requests.length > 0
  }

  async disableUserToCreateWorkRequestForToday(
    uid: string,
    date: string,
  ): Promise<User> {
    const collectionRef = collection(firestore, Collection.Users)
    const docRef = doc(collectionRef, uid)
    const docSnap = await getDoc(docRef)
    const user = { uid: docSnap.id, ...docSnap.data() } as User
    const updatedDates = user?.allowedWorkRequestDates?.filter(d => d !== date)
    await updateDoc(docRef, {
      allowedWorkRequestDates: updatedDates,
    })
    return { ...user, allowedWorkRequestDates: updatedDates }
  }

  async updateMemberNotifications(
    uid: string,
    notificationsEnabled: boolean,
  ): Promise<User> {
    const collectionRef = collection(firestore, Collection.Users)
    const docRef = doc(collectionRef, uid)
    const docSnap = await getDoc(docRef)
    const user = { uid: docSnap.id, ...docSnap.data() } as User
    await updateDoc(docRef, {
      notificationsEnabled,
    })
    return { ...user, notificationsEnabled }
  }
}

export const myTeamService = new TeamService()
