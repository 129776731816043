import dayGridPlugin from '@fullcalendar/daygrid'
import momentPlugin from '@fullcalendar/moment'
import FullCalendar from '@fullcalendar/react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Autocomplete,
  Box,
  Container,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import WorkRequestIcon from 'core/assets/work-request-28x28.svg'
import { Spinner } from 'core/components/Spinner'
import {
  Role,
  WorkRequestStatus,
  WorkRequestType,
  YMDFormat,
} from 'core/constants/enum'
import { User, UserWorkRequest } from 'core/service'
import {
  setLoadingAction,
  setMembersRequests,
  setMyRequests,
} from 'features/work-request/reducers/work-request'
import {
  fetchMemberWorkRequest,
  fetchTeamWorkRequests,
  getManagerTeam,
} from 'features/work-request/thunks/work-request'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useEffect, useState } from 'react'
import 'core/assets/styles/calendar.css'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type Request = {
  id?: string
  start: string
  end: string
  title: string
  type: string
  color: string
  textColor: string
}

type All = {
  uid: string
  name: string
}
const RequestsCalender = () => {
  // @ts-ignore
  const Moment_Range = extendMoment(moment)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedMember, setSelectedMember] = useState<User | All | null>({
    uid: 'all',
    name: 'All',
  })
  const { user } = useAppSelector(state => state.auth)
  const { myRequests, membersRequests, managerTeam, isLoading } =
    useAppSelector(state => state.workRequests)

  const requests = (
    user?.role !== Role.Member ? membersRequests : myRequests
  ).reduce((newArr: Request[], curr: UserWorkRequest) => {
    newArr.push({
      id: curr?.id,
      start: moment(curr?.fromDate).format(YMDFormat),
      end: moment(curr?.toDate).add(1, 'days').format(YMDFormat),
      title: curr?.requestedBy ? curr?.requestedBy : '',
      type: curr?.type,
      color: curr?.type === WorkRequestType.Leave ? '#e57373' : '#29b6f6',
      textColor: 'white',
    })
    return newArr
  }, [])

  const filteredReq = requests.filter(request => {
    const start = moment(request.start)
    const end = moment(request.end)
    const range1 = Moment_Range.range(start, end)
    const range2 = Moment_Range.range(moment(startDate), moment(endDate))

    return (
      (start.isSameOrAfter(moment(startDate)) &&
        end.isSameOrBefore(moment(endDate))) ||
      range2.overlaps(range1)
    )
  })

  let leaveDays = 0
  let WFHDays = 0
  filteredReq.map(req => {
    let start = moment(req.start)
    let end = moment(req.end)
    if (start.isBefore(moment(startDate))) {
      start = moment(startDate)
    } else {
      start = moment(req.start)
    }
    if (end.isAfter(moment(endDate))) {
      end = moment(endDate).subtract(1, 'days')
    } else {
      end = moment(req.end).subtract(1, 'days')
    }
    const diffDays = end.diff(start, 'days') + 1

    leaveDays += Array.from({ length: diffDays }, (_, i) =>
      moment(start).add(i, 'days'),
    ).filter(
      currDate =>
        currDate.isoWeekday() < 6 && req.type === WorkRequestType.Leave,
    ).length

    WFHDays += Array.from({ length: diffDays }, (_, i) =>
      moment(start).add(i, 'days'),
    ).filter(
      currDate => currDate.isoWeekday() < 6 && req.type === WorkRequestType.WFH,
    ).length
    return true
  })

  useEffect(() => {
    const userId = user?.uid ?? ''
    const role = user?.role ?? ''

    const getMemberWorkRequests = async (uid: string, userRole: string) => {
      dispatch(setLoadingAction(true))
      const userRequests = await fetchMemberWorkRequest(uid)
      const data = userRequests.filter(
        req => req.status === WorkRequestStatus.Approved,
      )
      userRole === Role.Member
        ? dispatch(setMyRequests(data))
        : dispatch(setMembersRequests(data))
      dispatch(setLoadingAction(false))
    }

    const getTeamWorkRequests = async (uid: string, userRole: string) => {
      dispatch(setLoadingAction(true))
      const userRequests = await fetchTeamWorkRequests(uid, userRole)
      const data = userRequests.filter(
        req => req.status === WorkRequestStatus.Approved,
      )
      dispatch(setMembersRequests(data))
      dispatch(setLoadingAction(false))
    }

    if (!selectedMember) {
      dispatch(setMembersRequests([]))
    }
    if (role !== Role.Member) {
      dispatch(getManagerTeam({ uid: userId, role }))
    }

    if (role === Role.Member) {
      getMemberWorkRequests(userId, role)
    } else if (selectedMember?.uid === 'all') {
      getTeamWorkRequests(userId, role)
    } else {
      getMemberWorkRequests(selectedMember?.uid ?? '', role)
    }
  }, [dispatch, user, selectedMember])

  if (isLoading)
    return (
      <Box sx={{ mt: '3rem' }}>
        <Spinner />
      </Box>
    )

  return (
    <Container sx={styles.root}>
      <Box sx={styles.heading}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Box alignItems="center" display="flex" gap={2}>
          <img
            alt="work-request-icon"
            height="28px"
            src={WorkRequestIcon}
            width="28px"
          />
          <Typography variant="h4">Work Requests</Typography>
        </Box>
      </Box>
      <Box sx={styles.calendarView}>
        <Box
          sx={user?.role !== Role.Member ? styles.autocomplete : styles.boxes}
        >
          {user?.role !== Role.Member && (
            <Autocomplete
              getOptionLabel={option => option.name}
              options={[{ uid: 'all', name: 'All' }, ...managerTeam]}
              renderInput={params => (
                <TextField {...params} label="Team Member" variant="outlined" />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              sx={{ width: '300px' }}
              value={selectedMember}
              onChange={(_event, newValue: User | All | null) => {
                setSelectedMember(newValue)
              }}
            />
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={styles.leaveBox}></Box>
            <Typography sx={styles.legendText}>Leave</Typography>
            <Typography sx={styles.count}>({leaveDays})</Typography>
            <Box sx={styles.WFHBox}></Box>
            <Typography sx={styles.legendText}>WFH</Typography>
            <Typography sx={styles.count}>({WFHDays})</Typography>
          </Box>
        </Box>
        <FullCalendar
          contentHeight="600px"
          datesSet={({ start, end }) => {
            setStartDate(moment(start).format(YMDFormat))
            setEndDate(moment(end).format(YMDFormat))
          }}
          editable={true}
          events={requests}
          headerToolbar={{
            left: '',
            center: 'title',
            right: 'dayGridWeek,dayGridMonth prev,next',
          }}
          initialView="dayGridWeek"
          plugins={[dayGridPlugin, momentPlugin]}
          showNonCurrentDates={false}
          views={{
            dayGridMonth: {
              titleFormat: { month: 'long', year: 'numeric' },
            },
            dayGridWeek: {
              titleFormat: '{{D} MMM}, YYYY',
            },
          }}
          weekends={false}
        />
      </Box>
    </Container>
  )
}

export default RequestsCalender

const styles = {
  root: { mt: 2.5, p: 0 },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
  calendarView: { padding: '1rem 0rem 2rem 0rem', position: 'relative' },
  autocomplete: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    position: 'absolute',
    top: '23px',
  },
  boxes: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    position: 'absolute',
    top: '38px',
    left: '70px',
  },
  leaveBox: {
    width: '18px',
    height: '18px',
    marginRight: '0.5rem',
    backgroundColor: '#e57373',
  },
  WFHBox: {
    width: '18px',
    height: '18px',
    marginRight: '0.5rem',
    backgroundColor: '#29b6f6',
  },
  legendText: {
    marginRight: '0.35rem',
  },
  count: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '0.35rem',
  },
}
