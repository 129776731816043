import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import colors from 'core/constants/color'
import { ErrorAlertTimeout } from 'core/constants/enum'
import Path from 'core/navigation/path'
import baseTheme from 'core/theme/base'
import {
  setIsLoginSubmittingAction,
  setLoginErrorAction,
} from 'features/landing/reducers/auth'
import loginSchema from 'features/landing/schema'
import { signin } from 'features/landing/thunks/auth'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const LoginCardHeader = () => (
  <Box alignItems="center" display={'flex'} mb={3}>
    <img alt="logo" src="/timer.svg" width="50px" />
    <Box ml={2}>
      <Typography variant="h4">Whoisin Tracker</Typography>
      <Typography sx={styles.slogan}>Realtime Attendance Tracker</Typography>
    </Box>
  </Box>
)

const LoginForm = () => {
  const navigate = useNavigate()
  const [showPassword, setPasswordVisibility] = useState(false)
  const dispatch = useAppDispatch()
  const { loginError, isLoginSubmitting } = useAppSelector(state => state.auth)

  if (loginError) {
    setTimeout(() => {
      dispatch(setLoginErrorAction(''))
    }, ErrorAlertTimeout)
  }

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <Card sx={styles.loginCard} variant="outlined">
        <LoginCardHeader />
        {loginError && (
          <Alert severity="error" sx={styles.alert}>
            {loginError}
          </Alert>
        )}
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={values => {
            dispatch(setLoginErrorAction(''))
            dispatch(setIsLoginSubmittingAction(true))
            dispatch(signin(values))
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                autoComplete="email"
                error={!!(touched.email && errors.email)}
                helperText={touched.email && errors.email ? errors.email : ''}
                id="email"
                label="Email Address"
                margin="normal"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                autoComplete="current-password"
                error={!!(touched.password && errors.password)}
                helperText={
                  touched.password && errors.password ? errors.password : ''
                }
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={styles.passwordVisibilityIcon}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => setPasswordVisibility(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange}
              />
              <Button
                fullWidth
                disabled={isLoginSubmitting}
                sx={styles.signInBtn}
                type="submit"
                variant="contained"
              >
                {isLoginSubmitting ? 'Signing in...' : 'Sign In'}
              </Button>
              <Typography
                sx={styles.forgotPassword}
                variant="body2"
                onClick={() => navigate(Path.ResetPassword)}
              >
                Forgot password?
              </Typography>
              <Typography
                color="text.secondary"
                mt={4}
                textAlign="center"
                variant="body2"
              >
                {'Copyright © '}
                Whoisin Tracker {new Date().getFullYear()}
                {'.'}
              </Typography>
            </Box>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

export const styles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  forgotPassword: {
    color: baseTheme.palette.primary.main,
    '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
  },
  loginCard: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '1',
  },
  slogan: {
    color: colors.grey,
  },
  signInBtn: {
    my: 2,
  },
  alert: {
    width: '100%',
    mb: 2,
  },
  passwordVisibilityIcon: {
    ml: 0,
  },
}

export default LoginForm
