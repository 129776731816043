import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const Spinner = () => (
  <Box sx={styles.spinner}>
    <CircularProgress />
  </Box>
)

const styles = {
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
