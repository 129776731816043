import { firestore } from 'core/config/firebase'
import { getServerDateAndTime } from 'core/config/server'
import { pingServerForLocation } from 'core/config/user-location'
import { Collection } from 'core/constants/collection'
import {
  Role,
  Status,
  UserCurrentStatus,
  UserStatus,
} from 'core/constants/enum'
import { Interval, User, UserActivityUpdated, UserLocation } from 'core/service'
import { service } from 'core/service/firebase'
import { defaultUserActivity } from 'features/dashboard/reducers/dashboard'
import DashboardService, {
  deleteUserActivityParams,
  deleteUserIntervalParams,
  EditActivityPayload,
  editMemberActivityParams,
  editUserActivityParams,
  editUserBrbActivityParams,
  editUserIntervalParams,
  getMemberShiftsParams,
  getUserActivitiesParams,
  punchBackParams,
  punchInParams,
  punchOutParams,
  startBreakParams,
} from 'features/dashboard/service'
import {
  getActivityBasedBreakDeleteMessage,
  getActivityBasedDeleteMessage,
  getEditedActivityMessage,
  getEditedBrbActivityMessage,
  getEditedUserIntervalMessage,
  getUserStatusAfterActivityDeletion,
  timestampToUTCTimestamp,
  timeStampWithZeroSeconds,
} from 'features/dashboard/utils/dashboard'
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  query,
  setDoc,
  updateDoc,
  where,
  limit,
  getDocs,
  getDoc,
  arrayRemove,
  deleteDoc,
} from 'firebase/firestore'
import { first, sortBy } from 'lodash'
import moment from 'moment'

const getUserActivityById = async (docId: string) => {
  const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
  const docSnap = await getDoc(docRef)
  return { id: docSnap.id, ...docSnap.data() } as UserActivityUpdated
}

const getUsersShiftsByDate = async (
  date: number,
): Promise<Array<UserActivityUpdated>> => {
  const collRef = collection(firestore, Collection.Shifts)
  const queryRef = query(collRef, where('shiftDate', '==', date))
  const shiftSnap = await getDocs(queryRef)
  return shiftSnap.docs.map(
    document =>
      ({
        id: document.id,
        ...document.data(),
      } as UserActivityUpdated),
  )
}
class Dashboard implements DashboardService {
  async punchIn({ uid, date }: punchInParams): Promise<UserActivityUpdated> {
    const collRef = collection(firestore, Collection.Shifts)
    const { date: utcDate, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const location = await pingServerForLocation()

    const payload: UserActivityUpdated = {
      start: time,
      intervals: [],
      end: 0,
      userId: uid,
      status: UserCurrentStatus.In,
      shiftDate: timestampToUTCTimestamp(date),
      workLocation: location,
    }
    const newDoc = await addDoc(collRef, payload)
    const activity = {
      createdAt: timeStamp,
      message: UserCurrentStatus.In,
      statusBadge: Status.Success,
      uid,
      date: utcDate,
    }
    // need to add the user activity to activity feed
    await service.addActivityToFeed(activity)

    return {
      id: newDoc.id,
      ...payload,
    } as UserActivityUpdated
  }

  async punchOut({ uid, docId }: punchOutParams): Promise<UserActivityUpdated> {
    const { date, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)

    const payload = { end: timeStamp, status: UserStatus.Out }
    await setDoc(docRef, payload, { merge: true })
    const activity = {
      createdAt: timeStamp,
      message: UserStatus.Out,
      statusBadge: Status.Error,
      uid,
      date,
    }
    // need to add the user activity to activity feed
    await service.addActivityToFeed(activity)

    return { id: docId, userId: uid, ...payload }
  }

  async startBreak({
    uid,
    docId,
  }: startBreakParams): Promise<UserActivityUpdated> {
    const { date, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)

    await updateDoc(docRef, {
      intervals: arrayUnion({
        start: timeStamp,
        end: 0,
      }),
      status: UserStatus.Brb,
    })

    const activity = {
      createdAt: timeStamp,
      message: UserStatus.Brb,
      statusBadge: Status.Warning,
      uid,
      date,
    }
    // need to add the user activity to activity feed
    await service.addActivityToFeed(activity)

    return {
      id: docId,
      userId: uid,
      breakInterval: { start: timeStamp, end: 0 },
      status: UserStatus.Brb,
    }
  }

  async punchBack({
    uid,
    docId,
  }: punchBackParams): Promise<UserActivityUpdated> {
    const { date, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
    const docSnap = await getDoc(docRef)

    const userShift = {
      id: docSnap.id,
      ...docSnap.data(),
    } as UserActivityUpdated

    const intervals = userShift.intervals as Array<Interval>
    intervals[intervals.length - 1].end = timeStamp

    const payload = {
      intervals,
      status: UserStatus.In,
    }
    await updateDoc(docRef, payload)
    const activity = {
      createdAt: timeStamp,
      message: UserStatus.Back,
      statusBadge: Status.Success,
      uid,
      date,
    }
    // need to add the user activity to activity feed
    await service.addActivityToFeed(activity)

    return {
      userId: uid,
      ...userShift,
      ...payload,
    }
  }

  async getUserActivities({
    uid,
    date,
  }: getUserActivitiesParams): Promise<UserActivityUpdated> {
    const collRef = collection(firestore, Collection.Shifts)
    const queryRef = query(
      collRef,
      where('shiftDate', '==', timestampToUTCTimestamp(date)),
      where('userId', '==', uid),
      limit(1),
    )

    const shiftSnap = await getDocs(queryRef)
    const shiftDoc = first(shiftSnap.docs)
    const userShift = {
      id: shiftDoc?.id,
      ...shiftDoc?.data(),
    } as UserActivityUpdated

    return userShift?.id
      ? userShift
      : {
          ...defaultUserActivity,
          userId: uid,
          shiftDate: timestampToUTCTimestamp(date),
          status: '',
        }
  }

  async deleteUserActivity({
    uid,
    activity,
    docId,
    date,
  }: deleteUserActivityParams): Promise<UserActivityUpdated> {
    const utcDate = timestampToUTCTimestamp(date)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
    const docSnap = await getDoc(docRef)
    const shift = { id: docSnap.id, ...docSnap.data() } as UserActivityUpdated

    const message = getActivityBasedDeleteMessage(
      utcDate,
      activity,
      shift?.start ?? 0,
      shift?.end ?? 0,
    )

    let userShift = { ...shift }

    if (activity === UserStatus.In) {
      await setDoc(
        docRef,
        { status: '', ...defaultUserActivity },
        { merge: true },
      )
      userShift = {
        status: '',
        ...defaultUserActivity,
        userId: uid,
      }
    } else {
      await setDoc(docRef, { status: UserStatus.In, end: 0 }, { merge: true })
      userShift = {
        ...shift,
        status: UserStatus.In,
        end: 0,
      }
    }
    const { date: utc_date, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const activityObj = {
      createdAt: timeStamp,
      message,
      statusBadge: Status.Error,
      uid,
      date: utc_date,
    }
    // need to add activity in the feed
    await service.addActivityToFeed(activityObj)
    return userShift
  }

  async deleteUserInterval({
    uid,
    index,
    docId,
    date,
  }: deleteUserIntervalParams): Promise<UserActivityUpdated> {
    const utcDate = timestampToUTCTimestamp(date)
    const shift = await getUserActivityById(docId)
    const intervals = sortBy(shift?.intervals, obj => moment(obj.start))
    const deletedInterval = intervals[index]
    intervals.splice(index, 1)

    const message = getActivityBasedBreakDeleteMessage(utcDate, deletedInterval)

    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
    const status = getUserStatusAfterActivityDeletion({ ...shift, intervals })
    await updateDoc(docRef, {
      status,
      intervals: arrayRemove(deletedInterval),
    })

    const { date: utc_date, time } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(time)
    const activity = {
      createdAt: timeStamp,
      message,
      statusBadge: Status.Error,
      uid,
      date: utc_date,
    }

    await service.addActivityToFeed(activity)

    return { ...shift, status, intervals }
  }

  async editUserActivity({
    uid,
    time,
    activity,
    docId,
    date,
  }: editUserActivityParams): Promise<UserActivityUpdated> {
    const utcDate = timestampToUTCTimestamp(date)
    const shift = await getUserActivityById(docId)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)

    const payload = {} as { start?: number; end?: number }
    const message = getEditedActivityMessage(utcDate, activity, time, shift)
    activity === UserStatus.In ? (payload.start = time) : (payload.end = time)
    await setDoc(docRef, payload, { merge: true })

    const { date: utc_date, time: serverTime } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(serverTime)
    const activityObj = {
      createdAt: timeStamp,
      message,
      statusBadge: Status.Primary,
      uid,
      date: utc_date,
    }
    // need to add the edited activity to feed
    await service.addActivityToFeed(activityObj)

    return { ...shift, ...payload }
  }

  async editUserBrbActivity({
    uid,
    date,
    time,
    index,
    docId,
  }: editUserBrbActivityParams): Promise<UserActivityUpdated> {
    const utcDate = timestampToUTCTimestamp(date)
    const shift = await getUserActivityById(docId)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)

    const intervals = sortBy(shift?.intervals, obj => moment(obj.start))
    intervals.every((interval, idx) => {
      if (index === idx) {
        interval.start = time
        return false
      }
      return true
    })
    await updateDoc(docRef, {
      intervals,
    })
    const message = getEditedBrbActivityMessage(utcDate, shift, index, time)
    const { date: utc_date, time: serverTime } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(serverTime)
    const activityObj = {
      createdAt: timeStamp,
      message,
      statusBadge: Status.Primary,
      uid,
      date: utc_date,
    }
    // need to add activity in feed
    await service.addActivityToFeed(activityObj)
    return { ...shift, intervals }
  }

  async editUserInterval({
    uid,
    date,
    startTime,
    endTime,
    index,
    docId,
  }: editUserIntervalParams): Promise<UserActivityUpdated> {
    const shift = await getUserActivityById(docId)
    const utcDate = timestampToUTCTimestamp(date)
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)

    const intervals = sortBy(shift?.intervals, obj => moment(obj.start))
    const interval = intervals[index]
    const message = getEditedUserIntervalMessage(
      utcDate,
      interval,
      startTime,
      endTime,
    )

    intervals.every((iinterval, idx) => {
      if (idx === index) {
        iinterval.start = startTime
        iinterval.end = endTime
        return false
      }
      return true
    })

    await updateDoc(docRef, {
      intervals,
    })
    const { date: utc_date, time: serverTime } = await getServerDateAndTime()
    const timeStamp = timeStampWithZeroSeconds(serverTime)
    const activityObj = {
      createdAt: timeStamp,
      message,
      statusBadge: Status.Primary,
      uid,
      date: utc_date,
    }
    // need to add activity in feed
    await service.addActivityToFeed(activityObj)
    return { ...shift, intervals }
  }

  async getMembersData(date: number): Promise<Array<User>> {
    const utcDate = timestampToUTCTimestamp(date)
    const shifts: UserActivityUpdated[] = await getUsersShiftsByDate(utcDate)
    const colRef = collection(firestore, 'users')
    const queryRef = query(colRef, where('isActive', '==', true))
    const usersSnap = await getDocs(queryRef)
    const membersData: Array<User> = usersSnap.docs.map(
      document => ({ uid: document.id, ...document.data() } as User),
    )

    membersData.forEach(member => {
      const memberShift = shifts.find(shift => shift?.userId === member?.uid)
      if (memberShift) {
        member.workLocation = memberShift.workLocation
        member.currentStatus = memberShift.status
        member.shiftId = memberShift.id
        member.inTime = memberShift.start
      }
    })

    return sortBy(membersData, 'name')
  }

  async getMemberShifts({
    uid,
    fromDate,
    toDate,
  }: getMemberShiftsParams): Promise<Array<UserActivityUpdated>> {
    const fromUtcDate = timestampToUTCTimestamp(fromDate)
    const toUtcDate = timestampToUTCTimestamp(toDate)
    const colRef = collection(firestore, Collection.Shifts)

    const queryRef = query(
      colRef,
      where('userId', '==', uid),
      where('shiftDate', '>=', fromUtcDate),
      where('shiftDate', '<=', toUtcDate),
    )
    const colSnap = await getDocs(queryRef)

    const shifts = colSnap.docs.map(
      document =>
        ({
          id: document.id,
          ...document.data(),
        } as UserActivityUpdated),
    )
    return sortBy(shifts, obj => moment(obj.shiftDate)).reverse()
  }

  async updateWorkLocation(
    docId: string,
    location: string,
  ): Promise<UserLocation> {
    const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
    await setDoc(
      docRef,
      {
        workLocation: location,
      },
      { merge: true },
    )
    return { shiftId: docId, location }
  }

  async editMemberActivity({
    role,
    start,
    end,
    workLocation,
    interval,
    docId,
  }: editMemberActivityParams): Promise<UserActivityUpdated> {
    if (role === Role.Admin) {
      const shift = await getUserActivityById(docId)
      const docRef = doc(firestore, `${Collection.Shifts}/${docId}`)
      const payload = {
        start,
        end,
        workLocation,
      } as EditActivityPayload
      const intervals = sortBy([...(shift?.intervals ?? [])], 'start')
      if (interval && intervals.length) {
        intervals[intervals.length - 1].end = interval.end
        payload.intervals = intervals
      }

      await setDoc(docRef, payload, { merge: true })
      return { ...shift, ...payload }
    }
    return {} as UserActivityUpdated
  }

  async deleteFCMToken(userId: string, fcmToken: string): Promise<void> {
    try {
      const collRef = collection(firestore, Collection.DeviceTokens)
      const queryRef = query(
        collRef,
        where('userId', '==', userId),
        where('fcmToken', '==', fcmToken),
      )
      const querySnap = await getDocs(queryRef)
      const tokenSnap = querySnap?.docs[0] ?? null
      const token = tokenSnap?.id
        ? { id: tokenSnap?.id, ...tokenSnap?.data() }
        : null

      if (!token) return
      const docRef = doc(firestore, `/device-tokens/${token?.id}`)
      await deleteDoc(docRef)
    } catch (err) {
      console.error(err)
    }
  }
}

export const dashboardService = new Dashboard()
