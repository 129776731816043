import {
  CalendarTodayOutlined,
  DynamicFeedOutlined,
  FilterListTwoTone,
  TodayOutlined,
  WeekendOutlined,
} from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  DateFilter,
  DateFilterLabel,
  dateFormat,
  Role,
} from 'core/constants/enum'
import Path from 'core/navigation/path'
import { User } from 'core/service'
import { VirtualizedList } from 'features/activity-feed/components/VirtualizedList'
import { getActivityFeed } from 'features/activity-feed/thunks/activity-feed'
import { getMyTeam } from 'features/my-team/thunks/my-team'
import { getAllUsers } from 'features/users-management/thunks/users-management'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { timestampToYMDFormat } from '../../dashboard/utils/dashboard'
import { FilterFeed } from '../components/FilterFeed'
import { setActivityFeedDataAction } from '../reducers/activity-feed'
import {
  getEndOfLastWeek,
  getStartOfLastWeek,
  getStartOfThisWeek,
} from '../utils/activity-feed'

const ActivityFeed = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedUserId = searchParams.get('userId')
  const { serverDate } = useAppSelector(state => state.app)

  const [selectedMember, setSelectedMember] = useState<User | null>(null)
  const [startDate, setStartDate] = useState(
    moment(serverDate).local().format(dateFormat),
  )
  const [endDate, setEndDate] = useState(
    moment(serverDate).local().format(dateFormat),
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterLabel, setFilterLabelState] = useState(DateFilterLabel.Today)

  const { isLoading, users, teamMembers } = useAppSelector(
    state => state.activityFeed,
  )
  const { user } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (selectedUserId && user?.uid) {
      if (teamMembers.length) {
        const teamMember = teamMembers?.find(row => row?.uid === selectedUserId)
        teamMember && setSelectedMember(teamMember)
      }
      setSearchParams({ userId: selectedUserId })
    }
  }, [selectedUserId, setSearchParams, teamMembers, user?.uid])

  useEffect(() => {
    if (user?.role === Role.Admin) {
      dispatch(getAllUsers(user?.role ?? ''))
    } else if (user?.role === Role.Manager) {
      dispatch(getMyTeam(user?.uid ?? ''))
    }
  }, [dispatch, user?.role, user?.uid])

  useEffect(() => {
    if (user?.role === Role.Admin && users?.length) {
      const userIdsList: string[] = users
        ?.filter(row => row.isActive)
        ?.map(row => row?.uid ?? '')
      dispatch(
        getActivityFeed({
          users: userIdsList,
          fromDate: moment(serverDate).startOf('day').valueOf(),
          toDate: moment(serverDate).endOf('day').valueOf(),
        }),
      )
    }
    return () => {
      dispatch(setActivityFeedDataAction([]))
    }
  }, [dispatch, serverDate, user?.role, users, users?.length])

  useEffect(() => {
    if (user?.role === Role.Manager && selectedMember?.uid && filterLabel) {
      const fromDate = moment(startDate).startOf('day').valueOf()
      const toDate = moment(endDate).endOf('day').valueOf()
      dispatch(
        getActivityFeed({
          users: [selectedMember?.uid ?? ''],
          fromDate,
          toDate,
        }),
      )
    } else {
      dispatch(setActivityFeedDataAction([]))
    }
  }, [
    dispatch,
    serverDate,
    selectedMember?.uid,
    filterLabel,
    user?.role,
    startDate,
    endDate,
  ])

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilter = (dateFilter: string) => {
    const date = serverDate
    let startValue = '',
      endValue = ''
    switch (dateFilter) {
      case DateFilter.Today:
        setFilterLabelState(DateFilterLabel.Today)
        startValue = timestampToYMDFormat(date)
        endValue = startValue
        break
      case DateFilter.ThisWeek:
        setFilterLabelState(DateFilterLabel.ThisWeek)
        startValue = timestampToYMDFormat(getStartOfThisWeek(date))
        endValue = timestampToYMDFormat(date)
        break
      case DateFilter.LastWeek:
        setFilterLabelState(DateFilterLabel.LastWeek)
        startValue = timestampToYMDFormat(getStartOfLastWeek(date))
        endValue = timestampToYMDFormat(getEndOfLastWeek(date))
        break
      default:
        break
    }
    setStartDate(startValue)
    setEndDate(endValue)
  }

  return user?.role !== Role.Member ? (
    <Container sx={styles.root}>
      <Box alignItems="center" display="flex" gap={2}>
        <DynamicFeedOutlined color="action" fontSize="large" />
        <Typography variant="h4">Activity Feed</Typography>
      </Box>
      {user?.role === Role.Admin && <FilterFeed />}
      {user?.role === Role.Manager && (
        <>
          <Box sx={styles.customFilter}>
            <Autocomplete
              getOptionLabel={option => option.name}
              options={teamMembers}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Team Member"
                  size="small"
                  variant="outlined"
                />
              )}
              renderOption={(params, option) => (
                <Box component="li" {...params}>
                  {option.name}
                </Box>
              )}
              sx={{ width: '300px' }}
              value={selectedMember}
              onChange={(_event, newValue: User | null) => {
                setSearchParams({ userId: newValue?.uid ?? '' })
                setSelectedMember(newValue)
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="inherit"
                startIcon={<FilterListTwoTone />}
                onClick={handleMenu}
              >
                Filter by {filterLabel}
              </Button>
            </Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="menu-filter"
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose()
                  handleFilter(DateFilter.Today)
                }}
              >
                <CalendarTodayOutlined sx={styles.filterMenuIcon} />
                <Typography variant="body2">Today</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose()
                  handleFilter(DateFilter.ThisWeek)
                }}
              >
                <TodayOutlined sx={styles.filterMenuIcon} />
                <Typography variant="body2">This Week</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose()
                  handleFilter(DateFilter.LastWeek)
                }}
              >
                <WeekendOutlined sx={styles.filterMenuIcon} />
                <Typography variant="body2">Last Week</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </>
      )}
      <Paper
        sx={[styles.vList, { mt: user?.role === Role.Admin ? 10 : 3 }]}
        variant="outlined"
      >
        {isLoading ? (
          <Box sx={styles.loader}>
            <CircularProgress size={25} />
            Loading...
          </Box>
        ) : (
          <VirtualizedList
            users={user?.role === Role.Admin ? users : teamMembers}
          />
        )}
      </Paper>
    </Container>
  ) : (
    <Navigate to={Path.Root} />
  )
}

const styles = {
  root: { mt: 1, p: 0.5 },
  vList: {
    mb: 2,
    p: 2,
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 4px',
  },
  filterMenuIcon: {
    mr: 1.5,
    my: 0.5,
    width: '1rem',
    height: '1rem',
  },
  card: {
    pt: '0.5rem',
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 0.375rem 1.5rem 0rem, rgba(0, 0, 0, 0.08) 0rem 0rem 0rem 0.063rem',
  },
  cardTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  filterBtn: {
    ml: 1,
  },
  listItemText: {
    display: 'flex',
    alignItems: 'baseline',
  },
  filterByDate: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
  },
  loader: {
    my: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  dateFilter: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: 'invert(50%)',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    py: '0.25rem',
    mb: '0.35rem',
  },
  filterIcon: {
    p: 0,
  },
  customFilter: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    mt: 1,
    justifyContent: 'flex-end',
  },
}

export default ActivityFeed
