import {
  Box,
  CircularProgress,
  Container,
  Link,
  Typography,
} from '@mui/material'
import colors from 'core/constants/color'
import { Role } from 'core/constants/enum'
import Path from 'core/navigation/path'
import { Attendance } from 'features/members/components/Attendance'
import { Profile } from 'features/members/components/Profile'
import {
  fetchManagerData,
  fetchMemberData,
} from 'features/members/reducers/members'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export const MemberDetails = () => {
  const navigate = useNavigate()
  const { user } = useAppSelector(state => state.auth)

  const dispatch = useAppDispatch()
  const param = useParams()
  const memberId = param.id ?? ''
  const { memberData, isLoading } = useAppSelector(state => state.members)

  useEffect(() => {
    if (user?.role !== Role.Admin && user?.uid !== memberId) {
      navigate(Path.Members)
    }
    dispatch(fetchMemberData(memberId))
  }, [dispatch, memberId, navigate, user?.role, user?.uid])

  useEffect(() => {
    if (memberData?.managerId) {
      dispatch(fetchManagerData(memberData?.managerId))
    }
  }, [dispatch, memberData?.managerId])

  if (isLoading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress size={35} />
      </Box>
    )
  }

  return memberData ? (
    <Container sx={styles.rootContainer}>
      <Profile />
      <Attendance />
    </Container>
  ) : (
    <Container maxWidth="xs" sx={styles.root}>
      <Typography variant="h4">Oops!</Typography>
      <Typography my={2}>
        We can&#39;t seem to find the user you are looking for.
      </Typography>
      <Typography mb={2} sx={styles.error}>
        Error Code: 404
      </Typography>
      <Link href="/users" variant="body1">
        Users
      </Link>
    </Container>
  )
}

const styles = {
  rootContainer: { p: 0.5, mt: 1.5 },
  tabs: {
    mt: 5,
  },
  noTabs: { display: 'none' },
  tab: { textTransform: 'none' },
  root: {
    mt: 10,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  error: {
    color: colors.grey,
  },
  loader: {
    mt: 2,
    display: 'flex',
    justifyContent: 'center',
  },
}
