import colors from 'core/constants/color'
import {
  CustomDaysFilterLabel,
  WorkRequestStatus,
  WorkRequestType,
  YMDFormat,
} from 'core/constants/enum'
import { UserWorkRequest } from 'core/service'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import { DateFilter } from '../service'

export const getChipColorForStatus = (label: string) => {
  if (label === WorkRequestStatus.Open) {
    return styles.warning
  }
  if (label === WorkRequestStatus.Approved) {
    return styles.success
  }
  if (label === WorkRequestStatus.Rejected) {
    return styles.error
  }
}

export const getDateRangeBasedOnDateFilter = (filter: string): DateFilter => {
  const currentDate = moment().format(YMDFormat)
  if (filter === CustomDaysFilterLabel.ThisMonth) {
    return {
      start: moment(currentDate).startOf('month').format(YMDFormat),
      end: moment(currentDate).endOf('month').format(YMDFormat),
    }
  } else if (filter === CustomDaysFilterLabel.Last6Months) {
    return {
      start: moment(currentDate)
        .subtract(6, 'month')
        .startOf('month')
        .format(YMDFormat),
      end: moment(currentDate)
        .subtract(1, 'month')
        .endOf('month')
        .format(YMDFormat),
    }
  } else if (filter === CustomDaysFilterLabel.Last1Year) {
    return {
      start: moment(currentDate)
        .subtract(12, 'month')
        .startOf('month')
        .format(YMDFormat),
      end: moment(currentDate)
        .subtract(1, 'month')
        .endOf('month')
        .format(YMDFormat),
    }
  }
  return {
    start: moment(currentDate)
      .add(1, 'month')
      .startOf('month')
      .format(YMDFormat),
    end: moment(currentDate).add(3, 'month').endOf('month').format(YMDFormat),
  }
}

export const getCountOfDaysWithoutWeekends = (
  startDate: string,
  endDate: string,
) => {
  const start = moment(startDate)
  const end = moment(endDate)

  const days = Array.from({ length: end.diff(start, 'days') + 1 }, (_, i) =>
    moment(start).add(i, 'days'),
  ).filter(day => day.isoWeekday() < 6).length
  return days.toString()
}

export const canAddNewRequest = (
  requests: Array<UserWorkRequest>,
  fromDate: string,
  toDate: string,
  type: string,
) => {
  // @ts-ignore
  const Moment_Range = extendMoment(moment)
  const range1 = Moment_Range.range(moment(fromDate), moment(toDate))
  if (type === WorkRequestType.WFH) {
    return requests.every(req => {
      const range2 = Moment_Range.range(
        moment(req.fromDate),
        moment(req.toDate),
      )
      return !(
        range1.overlaps(range2) ||
        range2.overlaps(range1) ||
        range1.contains(range2) ||
        range2.contains(range1)
      )
    })
  }
  const leaveRequests = requests.filter(
    req => req.type === WorkRequestType.Leave,
  )
  const WFHRequests = requests.filter(req => req.type !== WorkRequestType.Leave)
  return (
    WFHRequests.every(
      req =>
        !(
          moment(req.fromDate).isSame(moment(fromDate)) &&
          moment(req.toDate).isSame(moment(toDate))
        ),
    ) &&
    leaveRequests.every(req => {
      const range2 = Moment_Range.range(
        moment(req.fromDate),
        moment(req.toDate),
      )
      return !(
        range1.overlaps(range2) ||
        range2.overlaps(range1) ||
        range1.contains(range2) ||
        range2.contains(range1)
      )
    })
  )
}

export const canCreateWorkRequestForToday = (
  allowedDates: string[],
  startDate: string,
  endDate: string,
) => {
  const today = moment().format('YYYY-MM-DD')
  // @ts-ignore
  const MomentRange = extendMoment(moment)
  const range = MomentRange.range(
    moment(startDate, 'YYYY-MM-DD'),
    moment(endDate, 'YYYY-MM-DD'),
  )
  return (
    !range.contains(moment(today)) ||
    allowedDates.includes(moment(today).format('DD/MM/YYYY'))
  )
}

const styles = {
  success: {
    backgroundColor: colors.green,
    minWidth: 80,
    color: colors.black,
  },
  warning: {
    color: colors.black,
    backgroundColor: colors.yellow,
    minWidth: 80,
  },
  error: {
    backgroundColor: colors.red,
    minWidth: 80,
    color: colors.white,
  },
}
