import { Box, Button, Typography } from '@mui/material'
import { GazettedHoliday } from 'core/service'
import { getGazettedHolidays } from 'features/settings/thunks/settings'
import React, { useState, useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'
import AddOrUpdateHolidayForm from './AddOrUpdateHolidayForm'
import GazettedHolidaysTable from './GazettedHolidaysTable'
import HolidayModal from './HolidayModal'

const GazettedHolidays = () => {
  const dispatch = useAppDispatch()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [editedHolidayId, setEditedHolidayId] = useState<string>('')
  const [initialValues, setInitialValues] = useState<GazettedHoliday>({
    startDate: '',
    endDate: '',
    holidayTitle: '',
  })

  useEffect(() => {
    dispatch(getGazettedHolidays())
  }, [dispatch])

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleOpenModalForEdit = (values: GazettedHoliday) => {
    setIsOpenModal(true)
    setEditedHolidayId(values.id ?? '')
    setInitialValues(values)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setEditedHolidayId('')
    setInitialValues({ startDate: '', endDate: '', holidayTitle: '' })
  }
  return (
    <Box>
      <Box sx={styles.header}>
        <Typography component="h4" sx={styles.heading}>
          Gazetted Holidays
        </Typography>
        <Button variant="contained" onClick={handleOpenModal}>
          Add Holiday
        </Button>
      </Box>

      <GazettedHolidaysTable openEditModal={handleOpenModalForEdit} />

      <HolidayModal
        Component={
          <AddOrUpdateHolidayForm
            editedHolidayId={editedHolidayId}
            editedInitialValues={initialValues}
            onClose={handleCloseModal}
          />
        }
        maxWidth="md"
        open={isOpenModal}
        title={editedHolidayId ? 'Update Holiday' : 'Create Holiday'}
        onClose={handleCloseModal}
      />
    </Box>
  )
}

export default GazettedHolidays

const styles = {
  header: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontSize: '20px',
  },
  borderedBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #bdbdbd',
    mt: '2.5rem',
    p: '1.5rem',
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
  },
}
