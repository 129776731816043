import GroupIcon from '@mui/icons-material/Group'
import { Typography, Container, Box, Button } from '@mui/material'
import { Role } from 'core/constants/enum'
import Path from 'core/navigation/path'
import { User } from 'core/service'
import CreateOrUpdateUserForm from 'features/users-management/components/CreateOrUpdateUserForm'
import UserModal from 'features/users-management/components/UserModal'
import UsersTable from 'features/users-management/components/UsersTable'
import { getAllUsers } from 'features/users-management/thunks/users-management'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const UsersManagement = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [editedUserId, setEditedUserId] = useState<string>('')
  const [initValues, setInitValues] = useState<User>({
    uid: '',
    email: '',
    name: '',
    role: '',
    isActive: true,
    managerId: '',
  })

  useEffect(() => {
    const role = user?.role ? user?.role : ''
    dispatch(getAllUsers(role))
  }, [dispatch, user])

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setEditedUserId('')
    setInitValues({
      uid: '',
      email: '',
      name: '',
      role: '',
      isActive: true,
      managerId: '',
    })
  }

  const handleOpenModalForEdit = (editedUser: User) => {
    setIsOpenModal(true)
    setEditedUserId(editedUser?.uid ?? '')
    setInitValues(editedUser)
  }

  return user?.role === Role.Admin ? (
    <Container sx={styles.root}>
      <Box sx={styles.headBar}>
        <Box alignItems="center" display="flex" gap={2}>
          <GroupIcon color="action" fontSize="large" />
          <Typography variant="h4">Users Management</Typography>
        </Box>
        <Box>
          <Button variant="contained" onClick={handleOpenModal}>
            Create User
          </Button>
        </Box>
      </Box>
      <Box>
        <UserModal
          Component={
            <CreateOrUpdateUserForm
              editedInitialValues={initValues}
              editedUserId={editedUserId}
              onClose={handleCloseModal}
            />
          }
          open={isOpenModal}
          title={editedUserId ? 'Edit User Details' : 'Create User'}
          onClose={handleCloseModal}
        />
      </Box>
      <UsersTable openEditModal={handleOpenModalForEdit} />
    </Container>
  ) : (
    <Navigate to={Path.Root} />
  )
}

export default UsersManagement

const styles = {
  root: { mt: 2.5, p: 0 },
  headBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}
