import { createSlice } from '@reduxjs/toolkit'
import { User } from 'core/service'
import { service } from 'core/service/firebase'
import {
  editMemberActivity,
  editProfile,
  editProfileImage,
  fetchMemberAttendance,
} from 'features/members/thunks/members'
import { MembersState } from 'features/members/types'
import { toast } from 'react-toastify'
import { AppDispatch } from 'store'

const initialState = {
  memberData: null,
  managerData: null,
  editProfileMessage: '',
  editProfileImageMessage: '',
  memberAttendanceData: [],
  isLoading: false,
  attendanceLoading: false,
} as MembersState

const membersSlice = createSlice({
  name: 'membersSlice',
  initialState,
  reducers: {
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
    setAttendanceLoading: (state, action) => {
      state.attendanceLoading = action.payload
    },
    setMemberAttendanceDataAction: (state, action) => {
      state.memberAttendanceData = action.payload
    },
    setMemberDataAction: (state, action) => {
      state.memberData = action.payload
    },
    setManagerDataAction: (state, action) => {
      state.managerData = action.payload
    },
    setEditProfileMessageAction: (state, action) => {
      state.editProfileMessage = action.payload
    },
    setEditProfileImageMessageAction: (state, action) => {
      state.editProfileImageMessage = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(editProfile.fulfilled, state => {
      state.editProfileMessage = 'User profile is updated'
    })
    builder.addCase(editProfileImage.fulfilled, (state, action) => {
      if (state.memberData) {
        state.memberData = {
          ...state.memberData,
          profilePicture: action.payload.fileName,
          photoUrl: action.payload.photoUrl,
        }
      }
    })
    builder.addCase(fetchMemberAttendance.pending, state => {
      state.attendanceLoading = true
    })
    builder.addCase(fetchMemberAttendance.fulfilled, (state, action) => {
      state.memberAttendanceData = action.payload
      state.attendanceLoading = false
    })
    builder.addCase(fetchMemberAttendance.rejected, state => {
      state.attendanceLoading = false
    })
    builder.addCase(editMemberActivity.fulfilled, (state, action) => {
      state.memberAttendanceData = state.memberAttendanceData.map(activity =>
        activity.id === action.payload.id ? action.payload : activity,
      )
      toast.success('Member Activity Updated Successfully.')
    })
  },
})

export const fetchMemberData = (uid: string) => (dispatch: AppDispatch) => {
  dispatch(membersSlice.actions.setIsLoadingAction(true))
  service.getMemberData(uid, (data: User | null) => {
    dispatch(membersSlice.actions.setMemberDataAction(data))
    dispatch(membersSlice.actions.setIsLoadingAction(false))
  })
}

export const fetchManagerData = (uid: string) => (dispatch: AppDispatch) => {
  dispatch(membersSlice.actions.setIsLoadingAction(true))
  service.getMemberData(uid, (data: User | null) => {
    dispatch(membersSlice.actions.setManagerDataAction(data))
    dispatch(membersSlice.actions.setIsLoadingAction(false))
  })
}

export const { setEditProfileMessageAction, setEditProfileImageMessageAction } =
  membersSlice.actions

export default membersSlice
