import { onMessageListener } from 'core/config/firebase'
import { MessagePayload } from 'firebase/messaging'
import React from 'react'
import toast, { Toaster } from 'react-hot-toast'

type NotificationT = {
  title: string
  body: string
}

function ToastDisplay(props: NotificationT) {
  const { body, title } = props
  return (
    <div>
      <p>
        <strong>{title}</strong>
      </p>
      <p>{body}</p>
    </div>
  )
}

const Notification = () => {
  const notify = (title: string, body: string) =>
    toast(<ToastDisplay body={body} title={title} />, {
      duration: 6000,
      position: 'top-center',
      style: {
        background: '#e4f3ff',
      },
    })

  onMessageListener()
    .then((payload: unknown | MessagePayload) => {
      if (payload) {
        const title = (payload as MessagePayload)?.data?.title ?? ''
        const body = (payload as MessagePayload)?.data?.body ?? ''
        notify(title, body)
      }
    })
    .catch(err => console.error('sending notification failed: ', err))

  return <Toaster />
}

export default Notification
