import { WorkRequestStatus } from 'core/constants/enum'
import moment from 'moment'
import * as yup from 'yup'

const fileTypeRegex = /\.(jpe?g|png|pdf|docx?)$/i
export const workRequestSchema = yup.object().shape({
  fromDate: yup.date().required('This is required field.'),
  toDate: yup
    .date()
    .test(
      'toDate should after fromDate check',
      'To Date must be after the From Date',
      function (value): boolean {
        const fromDate = moment(this.parent.fromDate)
        const toDate = moment(value)
        return toDate.isSameOrAfter(fromDate, 'date')
      },
    )
    .required('This is required field.'),
  reason: yup.string().trim().required('This is required field.'),
  leaveType: yup.string().required(),
  medicalDoc: yup
    .mixed()
    .nullable()
    .when('leaveType', {
      is: 'Sick',
      then: yup
        .mixed()
        .nullable()
        .test(
          'FILE_SIZE',
          'The maximum allowed size is 1MB',
          value =>
            !value ||
            (value && typeof value === 'string') ||
            (value && typeof value !== 'string' && value.size <= 1024 * 1024),
        )
        .test(
          'fileFormat',
          'File must be an image, pdf, or doc only',
          value =>
            !value ||
            (value && typeof value === 'string') ||
            (value &&
              typeof value !== 'string' &&
              fileTypeRegex.test(value.name)),
        ),
    }),
})

export const ApproveOrRejectWorkRequestSchema = yup.object().shape({
  requestStatus: yup.string().required(),
  remarks: yup
    .string()
    .label('Supervisor Remarks')
    .when('requestStatus', {
      is: WorkRequestStatus.Rejected,
      then: yup.string().trim().required('This is required field.'),
      otherwise: yup.string().trim().notRequired(),
    })
    .when('requestStatus', {
      is: WorkRequestStatus.Open,
      then: yup.string().trim().required('This is required field.'),
      otherwise: yup.string().trim().notRequired(),
    }),
})

export const LeaveRequestSchema = yup.object().shape({
  fromDate: yup.date().required('This is required field.'),
  toDate: yup
    .date()
    .test(
      'toDate should after fromDate check',
      'To Date must be after the From Date',
      function (value): boolean {
        const fromDate = moment(this.parent.fromDate)
        const toDate = moment(value)
        return toDate.isSameOrAfter(fromDate, 'date')
      },
    )
    .required('This is required field.'),
  reason: yup.string().trim().required('This is required field.'),
  leaveType: yup.string().required(),
  memberId: yup.string().required('This is required field.'),
})
