import { firestore, storage } from 'core/config/firebase'
import { Collection } from 'core/constants/collection'
import { Role, WorkRequestStatus } from 'core/constants/enum'
import {
  ApprovedOrRejectedUserWorkRequest,
  CancelledWorkRequest,
  User,
  UserWorkRequest,
} from 'core/service'
import WorkRequestService, {
  GetUserWorkRequestsParams,
  CreateWorkRequestsParams,
  UpdateWorkRequestsParams,
  ApproveOrRejectWorkRequestParams,
  CreateLeaveRequestByAdminParams,
  GetMembersWorkRequestsParams,
} from 'features/work-request/service'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

class RequestService implements WorkRequestService {
  async getUserWorkRequests({
    uid,
    statusFilter,
    dateFilter,
  }: GetUserWorkRequestsParams): Promise<Array<UserWorkRequest>> {
    const userDocRef = doc(firestore, `${Collection.Users}/${uid}`)
    const userSnap = await getDoc(userDocRef)

    const user = { uid: userSnap.id, ...userSnap.data() } as User

    const colRef = collection(firestore, Collection.WorkRequests)
    const commonStart = where('fromDate', '>=', dateFilter?.start)
    const commonEnd = where('fromDate', '<=', dateFilter?.end)
    const userClause = where('userId', '==', uid)

    const queryRef =
      statusFilter === 'All' || !statusFilter
        ? query(colRef, userClause, commonStart, commonEnd)
        : query(
            colRef,
            userClause,
            commonStart,
            commonEnd,
            where('status', '==', statusFilter),
          )

    const colSnap = await getDocs(queryRef)
    return colSnap.docs.map(
      document =>
        ({
          id: document.id,
          ...document.data(),
          requestedBy: user?.name,
        } as UserWorkRequest),
    )
  }

  async getUserAllWorkRequests(uid: string): Promise<Array<UserWorkRequest>> {
    const userDocRef = doc(firestore, `${Collection.Users}/${uid}`)
    const userSnap = await getDoc(userDocRef)
    const user = { uid: userSnap.id, ...userSnap.data() } as User
    const colRef = collection(firestore, Collection.WorkRequests)
    const queryRef = query(colRef, where('userId', '==', uid))
    const colSnap = await getDocs(queryRef)
    return colSnap.docs.map(
      document =>
        ({
          id: document.id,
          ...document.data(),
          requestedBy: user?.name,
        } as UserWorkRequest),
    )
  }

  async createUserWorkRequest({
    uid,
    fromDate,
    toDate,
    reason,
    type,
    medicalDoc,
    leaveType,
    requestedBy,
    managerId,
  }: CreateWorkRequestsParams): Promise<UserWorkRequest> {
    const collectionRef = collection(firestore, Collection.WorkRequests)
    const nowTimeStamp = Date.now()
    const requestData = {
      createdAt: nowTimeStamp,
      status: WorkRequestStatus.Open,
      fromDate,
      toDate,
      reason,
      remarks: [],
      approvedBy: '',
      approvedAt: '',
      type,
      medicalDoc:
        medicalDoc && typeof medicalDoc !== 'string'
          ? `${nowTimeStamp}_${medicalDoc?.name}`
          : null,
      leaveType,
      userId: uid,
      managerId,
    }
    await uploadMedicalDocToStorage(uid, nowTimeStamp, medicalDoc)
    const response = await addDoc(collectionRef, requestData)
    return {
      ...requestData,
      userId: uid,
      managerId,
      requestedBy,
      id: response.id,
    }
  }

  async updateUserWorkRequest({
    uid,
    docId,
    createdAt,
    fromDate,
    toDate,
    reason,
    type,
    medicalDoc,
    leaveType,
    requestedBy,
    remarks,
    managerId,
  }: UpdateWorkRequestsParams): Promise<UserWorkRequest> {
    const nowTimeStamp = Date.now()
    const docRef = doc(firestore, `${Collection.WorkRequests}/${docId}`)
    const requestData = {
      createdAt,
      status: WorkRequestStatus.Open,
      fromDate,
      toDate,
      reason,
      remarks,
      approvedBy: '',
      approvedAt: '',
      type,
      medicalDoc:
        typeof medicalDoc !== 'string'
          ? `${nowTimeStamp}_${medicalDoc?.name}`
          : medicalDoc,
      leaveType,
      managerId,
    }
    await uploadMedicalDocToStorage(uid, nowTimeStamp, medicalDoc)
    await updateDoc(docRef, requestData)
    return { ...requestData, userId: uid, requestedBy, id: docId }
  }

  async deleteUserWorkRequest(docId: string): Promise<string> {
    const docRef = doc(firestore, `${Collection.WorkRequests}/${docId}`)
    await deleteDoc(docRef)
    return docId
  }

  async approveOrRejectUserWorkRequest({
    userType,
    uid,
    status,
    approvedBy,
    remarks,
    docId,
  }: ApproveOrRejectWorkRequestParams): Promise<ApprovedOrRejectedUserWorkRequest> {
    const nowDateTime = new Date().toLocaleString()
    const docRef = doc(firestore, `${Collection.WorkRequests}/${docId}`)

    const data = {
      status,
      approvedBy,
      approvedAt: nowDateTime,
      remarks,
    }
    await updateDoc(docRef, data)

    return { ...data, userType, id: docId, userId: uid }
  }

  async cancelUserWorkRequest(docId: string): Promise<CancelledWorkRequest> {
    const nowDateTime = new Date().toLocaleString()
    const docRef = doc(firestore, `${Collection.WorkRequests}/${docId}`)

    const data = {
      status: WorkRequestStatus.Cancelled,
      cancelledAt: nowDateTime,
    }
    await updateDoc(docRef, data)
    return { id: docId, ...data }
  }

  async getMembersWorkRequests({
    uid,
    role,
    statusFilter,
    dateFilter,
  }: GetMembersWorkRequestsParams): Promise<Array<UserWorkRequest>> {
    const userCollRef = collection(firestore, `${Collection.Users}`)
    const userQueryRef1 = query(
      userCollRef,
      where('managerId', '==', uid),
      where('isActive', '==', true),
    )
    const userQueryRef2 = query(userCollRef, where('isActive', '==', true))
    const usersSnap = await getDocs(
      role === Role.Manager ? userQueryRef1 : userQueryRef2,
    )
    const users = usersSnap.docs.map(
      user =>
        ({
          uid: user.id,
          ...user.data(),
        } as User),
    )
    const activeUserIds: string[] = users?.map(user => user?.uid ?? '')

    const collRef = collection(firestore, Collection.WorkRequests)
    const ManagerTeamQuery = where('managerId', '==', uid)
    const commonStart = where('fromDate', '>=', dateFilter?.start)
    const commonEnd = where('fromDate', '<=', dateFilter?.end)
    const queryRef1 =
      statusFilter === 'All' || !statusFilter
        ? query(collRef, ManagerTeamQuery, commonStart, commonEnd)
        : query(
            collRef,
            ManagerTeamQuery,
            commonStart,
            commonEnd,
            where('status', '==', statusFilter),
          )
    const queryRef2 =
      statusFilter === 'All' || !statusFilter
        ? query(collRef, commonStart, commonEnd)
        : query(
            collRef,
            commonStart,
            commonEnd,
            where('status', '==', statusFilter),
          )

    const requests = await getDocs(
      role === Role.Manager ? queryRef1 : queryRef2,
    )
    const workRequests = requests.docs
      .filter(req => activeUserIds.includes(req.data().userId))
      .map(req => ({ id: req.id, ...req.data() } as UserWorkRequest))

    const updatedWorkRequests =
      role === Role.Admin
        ? workRequests.filter(req => req.userId !== uid)
        : workRequests

    return updatedWorkRequests.map(req => {
      const user = users.find(userDoc => userDoc?.uid === req.userId)
      return { ...req, requestedBy: user?.name }
    })
  }

  async getMembersAllWorkRequests(
    uid: string,
    role: string,
  ): Promise<Array<UserWorkRequest>> {
    const userColl = collection(firestore, `${Collection.Users}`)
    const userQueryRef1 = query(
      userColl,
      where('managerId', '==', uid),
      where('isActive', '==', true),
    )
    const userQueryRef2 = query(userColl, where('isActive', '==', true))
    const usersSnap = await getDocs(
      role === Role.Manager ? userQueryRef1 : userQueryRef2,
    )
    const users = usersSnap.docs.map(
      user =>
        ({
          uid: user.id,
          ...user.data(),
        } as User),
    )
    const activeUserIds: string[] = users?.map(user => user?.uid ?? '')

    const collRef = collection(firestore, Collection.WorkRequests)
    const ManagerTeamQuery = where('managerId', '==', uid)
    const queryRef1 = query(collRef, ManagerTeamQuery)

    const requests = await getDocs(role === Role.Manager ? queryRef1 : collRef)
    const workRequests = requests.docs
      .filter(req => activeUserIds.includes(req.data().userId))
      .map(req => ({ id: req.id, ...req.data() } as UserWorkRequest))

    const updatedWorkRequests =
      role === Role.Admin
        ? workRequests.filter(req => req.userId !== uid)
        : workRequests

    return updatedWorkRequests.map(req => {
      const user = users.find(userDoc => userDoc?.uid === req.userId)
      return { ...req, requestedBy: user?.name }
    })
  }

  async createUserLeaveRequestByAdmin({
    uid,
    fromDate,
    toDate,
    reason,
    approvedBy,
    type,
    leaveType,
    requestedBy,
  }: CreateLeaveRequestByAdminParams): Promise<UserWorkRequest> {
    const docRef = doc(firestore, `${Collection.Users}/${uid}`)
    const docSnap = await getDoc(docRef)
    const user = { uid: docSnap.id, ...docSnap.data() } as User

    const collectionRef = collection(firestore, Collection.WorkRequests)
    const nowTimeStamp = Date.now()
    const requestData = {
      createdAt: nowTimeStamp,
      status: WorkRequestStatus.Approved,
      fromDate,
      toDate,
      reason,
      remarks: [],
      approvedBy,
      approvedAt: new Date().toLocaleString(),
      type,
      leaveType,
      requestedBy,
      userId: uid,
      managerId: user?.managerId,
    }
    const response = await addDoc(collectionRef, requestData)
    return { ...requestData, id: response.id }
  }

  async getMedicalDocDownloadURL(
    uid: string,
    fileName: string,
  ): Promise<string> {
    const storageRef = ref(storage, `/files/work-requests/${uid}/${fileName}`)
    const url = await getDownloadURL(storageRef)
    return url
  }
}

export const workRequestService = new RequestService()

export const uploadMedicalDocToStorage = async (
  uid: string,
  timeStamp: number,
  medicalDoc?: File | string | null,
) => {
  if (medicalDoc && typeof medicalDoc !== 'string') {
    const storageRef = ref(
      storage,
      `/files/work-requests/${uid}/${timeStamp}_${medicalDoc?.name}`,
    )
    await uploadBytesResumable(storageRef, medicalDoc)
  }
}
