import ClearIcon from '@mui/icons-material/Clear'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import React from 'react'

type RequestModalProps = {
  open: boolean
  title: string
  onClose: () => void
  Component: React.ReactNode
}

const RequestModal: React.FC<RequestModalProps> = props => {
  const { open, title, onClose, Component } = props

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle component="h2" sx={styles.modalTitle} variant="h6">
        {title}
        <Tooltip title="close">
          <IconButton onClick={onClose}>
            <ClearIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>{Component}</DialogContent>
    </Dialog>
  )
}

export default RequestModal

const styles = {
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 0,
  },
}
