import { createSlice } from '@reduxjs/toolkit'
import { User } from 'core/service'
import { signout } from 'features/landing/thunks/auth'
import {
  disableUserToCreateWorkRequestForToday,
  enableUserToCreateWorkRequestForToday,
  getMyTeam,
  updateMemberNotifications,
} from 'features/my-team/thunks/my-team'
import { MyTeamState } from 'features/my-team/types'
import { orderBy } from 'lodash'

const initialState = {
  isLoading: false,
  myTeam: [] as Array<User>,
} as MyTeamState

const myTeamSlice = createSlice({
  name: 'myTeamSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyTeam.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getMyTeam.fulfilled, (state, action) => {
      state.myTeam = orderBy(
        action.payload,
        ({ name }) => name.toLowerCase(),
        'asc',
      )
      state.isLoading = false
    })
    builder.addCase(getMyTeam.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(updateMemberNotifications.fulfilled, (state, action) => {
      const user = action.payload
      state.myTeam = state.myTeam.map(u => {
        if (user.uid === u.uid) {
          return {
            ...user,
            notificationsEnabled: user.notificationsEnabled,
          }
        }
        return u
      })
    })

    builder.addCase(
      enableUserToCreateWorkRequestForToday.fulfilled,
      (state, action) => {
        const user = action.payload
        state.myTeam = state.myTeam.map(u => {
          if (user.uid === u.uid) {
            return {
              ...user,
              allowedWorkRequestDates: user.allowedWorkRequestDates,
            }
          }
          return u
        })
      },
    )

    builder.addCase(
      disableUserToCreateWorkRequestForToday.fulfilled,
      (state, action) => {
        const user = action.payload
        state.myTeam = state.myTeam.map(u => {
          if (user.uid === u.uid) {
            return {
              ...user,
              allowedWorkRequestDates: user.allowedWorkRequestDates,
            }
          }
          return u
        })
      },
    )

    builder.addCase(signout.fulfilled, state => {
      state.isLoading = false
      state.myTeam = [] as Array<User>
    })
  },
})

export default myTeamSlice
