import { isUserOnBreak } from 'features/dashboard/utils/dashboard'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'store/hooks'

export const WorkClock = () => {
  const { userActivity, totalBreak } = useAppSelector(state => state.dashboard)
  const userOnBreak = isUserOnBreak(userActivity?.intervals ?? [])
  const [date, setDate] = useState(moment.duration(0))

  useEffect(() => {
    const refreshClock = () => {
      const userStartTime = userActivity.start ?? 0
      const userEndTime = userActivity.end ?? 0
      const lastIntervalStart = moment(
        userActivity?.intervals?.[userActivity?.intervals?.length - 1]?.start ??
          0,
      )

      if (userStartTime === 0) {
        setDate(moment.duration(0))
      } else if (userEndTime === 0 && !userOnBreak) {
        const elapsedTime = moment().diff(userStartTime + totalBreak)
        setDate(moment.duration(elapsedTime))
      } else if (userEndTime !== 0 && !userOnBreak) {
        const elapsedTime = moment(userEndTime).diff(userStartTime + totalBreak)
        setDate(moment.duration(elapsedTime))
      } else if (userOnBreak && lastIntervalStart.isValid()) {
        const elapsedTime = lastIntervalStart.diff(userStartTime + totalBreak)
        setDate(moment.duration(elapsedTime))
      } else {
        setDate(moment.duration(0))
      }
    }

    const timerId = setInterval(refreshClock, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [userActivity, totalBreak, userOnBreak])

  const formattedTime = moment.utc(date.as('milliseconds')).format('HH:mm:ss')

  return <>{formattedTime}</>
}
