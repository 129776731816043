import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
} from '@mui/material'
import { ErrorAlertTimeout } from 'core/constants/enum'
import Path from 'core/navigation/path'
import { LoginCardHeader, styles } from 'features/landing/components/LoginForm'
import {
  setIsResetPasswordSubmittingAction,
  setResetPasswordErrorAction,
} from 'features/landing/reducers/auth'
import { resetPasswordSchema } from 'features/landing/schema'
import { resetPassword } from 'features/landing/thunks/auth'
import { Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { resetPasswordError, isResetPasswordSubmitting } = useAppSelector(
    state => state.auth,
  )

  if (resetPasswordError) {
    setTimeout(() => {
      dispatch(setResetPasswordErrorAction(''))
    }, ErrorAlertTimeout)
  }

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <Card color="#345" sx={styles.loginCard} variant="outlined">
        <LoginCardHeader />
        {resetPasswordError === 'false' && (
          <Alert severity="success" sx={styles.alert}>
            Password reset link has been sent. Please check your inbox.
          </Alert>
        )}
        {resetPasswordError && resetPasswordError !== 'false' && (
          <Alert severity="error" sx={styles.alert}>
            {resetPasswordError}
          </Alert>
        )}
        <Formik
          initialValues={{ email: '' }}
          validationSchema={resetPasswordSchema}
          onSubmit={values => {
            dispatch(setResetPasswordErrorAction(''))
            dispatch(setIsResetPasswordSubmittingAction(true))
            dispatch(resetPassword(values))
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Box noValidate component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                autoComplete="email"
                error={!!(touched.email && errors.email)}
                helperText={touched.email && errors.email ? errors.email : ''}
                id="email"
                label="Email Address"
                margin="normal"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              <Button
                fullWidth
                disabled={isResetPasswordSubmitting}
                sx={styles.signInBtn}
                type="submit"
                variant="contained"
              >
                {isResetPasswordSubmitting
                  ? 'Sending Reset Link...'
                  : 'Reset Password'}
              </Button>
              <Typography
                sx={styles.forgotPassword}
                variant="body2"
                onClick={() => navigate(Path.Root)}
              >
                Remember password?
              </Typography>
              <Typography
                color="text.secondary"
                mt={4}
                textAlign="center"
                variant="body2"
              >
                {'Copyright © '}
                Whoisin Tracker {new Date().getFullYear()}
                {'.'}
              </Typography>
            </Box>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

export default ResetPassword
