import { EditOutlined, FilterListTwoTone } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { Spinner } from 'core/components/Spinner'
import { UserActivityStatus } from 'core/constants/enum'
import { User } from 'core/service'
import baseTheme from 'core/theme/base'
import React, { MouseEvent, useState } from 'react'
import { useAppSelector } from 'store/hooks'

type UsersTableProps = {
  openEditModal: (editedUser: User) => void
}

const UsersTable: React.FC<UsersTableProps> = props => {
  const { openEditModal } = props
  const [anchorElForStatusFilter, setAnchorElForStatusFilter] =
    useState<null | HTMLElement>(null)
  const [statusFilter, setStatusFilter] = useState(UserActivityStatus.Active)
  const [page, setPage] = useState(0)
  const rowsPerPage = 10
  const { isDarkMode } = useAppSelector(state => state.app)
  const { users, isLoading } = useAppSelector(state => state.usersManagement)

  const filteredUsers =
    statusFilter === UserActivityStatus.Active
      ? users.filter(user => user.isActive)
      : users.filter(user => !user.isActive)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleOpenStatusFilterMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElForStatusFilter(event.currentTarget)
  }

  const handleCloseStatusFilterMenu = () => {
    setAnchorElForStatusFilter(null)
  }

  const getMangerNameById = (managerId: string) => {
    const manager = users?.find(user => user.uid === managerId)
    return manager?.name ? manager?.name : ''
  }

  if (isLoading)
    return (
      <Box sx={{ mt: '1.5rem' }}>
        <Spinner />
      </Box>
    )

  return (
    <Box sx={{ mt: '2.5rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          color="inherit"
          startIcon={<FilterListTwoTone />}
          onClick={handleOpenStatusFilterMenu}
        >
          Filter by {statusFilter}
        </Button>
        <Menu
          keepMounted
          anchorEl={anchorElForStatusFilter}
          id="menu-date-filter"
          open={Boolean(anchorElForStatusFilter)}
          onClose={handleCloseStatusFilterMenu}
        >
          {Object.values(UserActivityStatus).map(value => (
            <MenuItem
              key={value}
              onClick={() => {
                setStatusFilter(value)
                setPage(0)
                handleCloseStatusFilterMenu()
              }}
            >
              <Typography variant="body2">{value}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {filteredUsers.length && !isLoading ? (
        <Paper
          sx={isDarkMode ? styles.tableDark : styles.tableLight}
          variant="outlined"
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeader}>Email</TableCell>
                  <TableCell sx={styles.tableHeader}>Full Name</TableCell>
                  <TableCell sx={styles.tableHeader}>Role</TableCell>
                  <TableCell sx={styles.tableHeader}>Reports to</TableCell>
                  <TableCell sx={styles.tableHeader}>Status</TableCell>
                  <TableCell sx={styles.tableHeader}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(user => (
                    <TableRow key={user.uid}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        {user.managerId
                          ? getMangerNameById(user.managerId)
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {user.isActive ? 'Active' : 'In Active'}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit User">
                          <IconButton
                            sx={styles.editBtn}
                            onClick={() => openEditModal(user)}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box sx={styles.borderedBox}>
          <Typography sx={styles.noRequestFound} variant="h6">
            No User Found
          </Typography>
        </Box>
      )}
      {filteredUsers.length > rowsPerPage && (
        <TablePagination
          component="div"
          count={filteredUsers.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          onPageChange={handleChangePage}
        />
      )}
    </Box>
  )
}

export default UsersTable

const styles = {
  tableDark: {
    border: '0.031rem solid rgba(255, 255, 255, 10%)',
    mt: '1rem',
  },
  tableLight: {
    border: '0.031rem solid rgba(0, 0, 0, 10%)',
    mt: '1rem',
  },
  noRequestFound: {
    display: 'flex',
    justifyContent: 'center',
  },
  editBtn: {
    '&:hover': { color: baseTheme.palette.primary.main },
  },
  borderedBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #bdbdbd',
    mt: '1.5rem',
    p: '1.5rem',
  },
  tableHeader: {
    fontSize: '0.9rem',
  },
}
