import { Box, Grid, Button, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'core/components/FormikTextField'
import { YMDFormat } from 'core/constants/enum'
import { GazettedHoliday } from 'core/service'
import { timestampToYMDFormat } from 'features/dashboard/utils/dashboard'
import { getCountOfDaysWithoutWeekends } from 'features/work-request/utils/work-request'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { GazettedHolidayForm } from '../schema'
import { addGazettedHoliday, updateGazettedHoliday } from '../thunks/settings'

type ComponentProps = {
  editedHolidayId: string
  editedInitialValues: GazettedHoliday
  onClose: () => void
}

const AddOrUpdateHolidayForm: React.FC<ComponentProps> = props => {
  const dispatch = useAppDispatch()
  const { editedHolidayId, editedInitialValues, onClose } = props
  const [loading, setLoading] = useState(false)
  const { serverDate } = useAppSelector(state => state.app)
  const { gazettedHolidays } = useAppSelector(state => state.settings)

  const initialValues = {
    startDate: editedInitialValues.startDate ?? '',
    endDate: editedInitialValues.endDate ?? '',
    holidayTitle: editedInitialValues.holidayTitle ?? '',
  }

  const canAddHoliday = (
    holidays: GazettedHoliday[],
    startDate: string,
    endDate: string,
  ) => {
    // @ts-ignore
    const Moment_Range = extendMoment(moment)
    let canAdd = true

    const range = Moment_Range.range(moment(startDate), moment(endDate))
    holidays.every(holiday => {
      const range1 = Moment_Range.range(
        moment(holiday.startDate),
        moment(holiday.endDate),
      )
      if (
        range.overlaps(range1) ||
        range1.overlaps(range) ||
        range.isSame(range1) ||
        range.contains(range1) ||
        range1.contains(range)
      ) {
        canAdd = false
        return false
      }
      return true
    })
    return canAdd
  }

  const handleSubmit = async (values: GazettedHoliday) => {
    setLoading(true)
    const updatedValues = {
      holidayTitle: values.holidayTitle,
      startDate: moment(values.startDate).format(YMDFormat),
      endDate: moment(values.endDate).format(YMDFormat),
    }

    const holidays = editedHolidayId
      ? gazettedHolidays.filter(holiday => holiday.id !== editedHolidayId)
      : gazettedHolidays

    const canCreateHoliday = canAddHoliday(
      holidays,
      updatedValues.startDate,
      updatedValues.endDate,
    )

    if (!canCreateHoliday) {
      toast.warning(
        'You have already created holiday that overlaps with this date range.',
      )
      setLoading(false)
      onClose()
      return
    }

    if (editedHolidayId && canCreateHoliday) {
      await dispatch(
        updateGazettedHoliday({ id: editedHolidayId, ...updatedValues }),
      )
    } else if (canCreateHoliday) {
      await dispatch(addGazettedHoliday(updatedValues))
    }
    setLoading(false)
    onClose()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GazettedHolidayForm}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container spacing={2.5} sx={{ mt: '0rem' }}>
            <Grid item sx={{ pt: '0rem' }} xs={12}>
              <FormikTextField
                name="holidayTitle"
                readonly={false}
                size="medium"
                textLabel="Holiday Title"
              />
            </Grid>
            <Grid item md={6} sx={{ pt: '0rem' }} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="startDate"
                      readonly={true}
                      size="medium"
                      textLabel="Start Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.startDate}
                  onChange={newVal => setFieldValue('startDate', newVal)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={6} sx={{ pt: '0rem' }} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  minDate={timestampToYMDFormat(serverDate)}
                  renderInput={params => (
                    <FormikTextField
                      name="endDate"
                      readonly={true}
                      size="medium"
                      textLabel="End Date"
                      {...params}
                    />
                  )}
                  shouldDisableDate={date => {
                    const updatedDay = moment(date)
                    return updatedDay.day() === 0 || updatedDay.day() === 6
                  }}
                  value={values.endDate}
                  onChange={newVal => setFieldValue('endDate', newVal)}
                  onOpen={() => setFieldValue('endDate', values.startDate)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={6} sx={styles.days} xs={12}>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                Total Days:
              </Typography>
              <Typography sx={{ fontSize: '13px' }}>
                {getCountOfDaysWithoutWeekends(
                  values.startDate,
                  values.endDate,
                )}{' '}
                day(s)
              </Typography>
            </Grid>
          </Grid>

          <Box sx={styles.btnBox}>
            <Button
              color="inherit"
              disabled={loading}
              variant="text"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={loading} type="submit" variant="contained">
              {editedHolidayId ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateHolidayForm

const styles = {
  btnBox: {
    mt: '2rem',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'right',
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
}
