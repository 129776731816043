import { createAsyncThunk } from '@reduxjs/toolkit'
import { auth, messaging } from 'core/config/firebase'
import { Role } from 'core/constants/enum'
import { User } from 'core/service'
import { service } from 'core/service/firebase'
import { dashboardService } from 'features/dashboard/service/service'
import {
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { getToken } from 'firebase/messaging'

export const signin = createAsyncThunk(
  'auth/signin',
  async (values: { email: string; password: string }, { rejectWithValue }) => {
    try {
      return await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (values: { email: string }, { rejectWithValue }) => {
    try {
      const data = await fetchSignInMethodsForEmail(auth, values.email)
      if (data.length > 0) {
        try {
          await sendPasswordResetEmail(auth, values.email)
        } catch (err) {
          return rejectWithValue(err)
        }
      }
      return data.length
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const signout = createAsyncThunk(
  'auth/signout',
  async (user: User | null) => {
    if (!user) return
    if (user?.role !== Role.Member) {
      localStorage.removeItem('isNotificationBlocked')
      const userId = user?.uid
      const fcmToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })
      if (fcmToken && userId) {
        await dashboardService.deleteFCMToken(userId, fcmToken)
      }
    }
    await signOut(auth)
    return
  },
)

export const getUserData = createAsyncThunk(
  'auth/setUserData',
  async (values: { email: string; uid: string }, { rejectWithValue }) => {
    try {
      return await service.getUserData(values.email, values.uid)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (
    values: { currentPassword: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      return await service.changePassword(
        auth.currentUser,
        values.currentPassword,
        values.password,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
