import {
  dayAndDateFormat,
  LocalStringDateFormat,
  Status,
  TimeHMFFormat,
  UserStatus,
  YMDFormat,
} from 'core/constants/enum'
import { AlertStatus, Interval, UserActivityUpdated } from 'core/service'
import { first } from 'lodash'
import moment from 'moment'

export const isUserOnBreak = (intervals: Array<Interval>) => {
  if (intervals?.length > 0) {
    return (
      intervals[intervals.length - 1].start !== 0 &&
      intervals[intervals.length - 1].end === 0
    )
  }
  return false
}

export const timeStampWithZeroSeconds = (time: number) => {
  const dateTime = moment(time).seconds(0).format()
  return moment(dateTime).valueOf()
}

export const calculateBreakInMilliSeconds = (start: number, end: number) => {
  if (start === 0 || end === 0) return 0
  const startTime = new Date(start).getTime()
  const endTime = new Date(end).getTime()
  const diff = endTime - startTime
  const milliSeconds = Math.floor(diff)
  return milliSeconds
}

export const timestampToWMDYFormat = (timestamp: number) =>
  moment(timestamp).format(dayAndDateFormat)

export const timestampToHMFormat = (timestamp: number) =>
  moment(timestamp).format(TimeHMFFormat)

export const timestampToHMSFormat = (timestamp: number) => {
  let seconds = Math.floor(timestamp / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  seconds -= minutes * 60
  minutes -= hours * 60
  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

export const milliSecondsToHMFormat = (milliSeconds: number) => {
  let min = Math.floor(milliSeconds / 60000)
  const hr = Math.floor(min / 60)
  min -= hr * 60
  return (hr ? `${hr}h` : '') + `${min}m`
}

export const milliSecondsToHours = (milliSeconds: number) =>
  Math.round((milliSeconds / 3600000) * 100 + Number.EPSILON) / 100

export const timestampToLocalStringFormat = (timestamp: number) =>
  moment(timestamp).format(LocalStringDateFormat)

export const checkForTimeConflictsInUserActivity = (
  userActivity: UserActivityUpdated,
  time: number,
  activity: string,
): AlertStatus => {
  const totalIntervals = userActivity?.intervals?.length ?? 0
  let message = ''
  let severity = ''
  if (activity === UserStatus.In) {
    if (userActivity.start === time) {
      message = 'Previous and selected starting time of shift are same'
      severity = Status.Error
    } else if (userActivity?.end !== 0 && time >= (userActivity?.end ?? 0)) {
      message =
        'Starting time of shift cannot be greater than or equal to ending time'
      severity = Status.Error
    } else if (
      totalIntervals > 0 &&
      time >= (first(userActivity?.intervals)?.start ?? 0)
    ) {
      message = 'Starting time of shift is conflicting with a break duration'
      severity = Status.Error
    } else {
      message = 'Activity time is updated'
      severity = Status.Success
    }
    return { message, severity }
  }

  if (userActivity.end === time) {
    message = 'Previous and selected ending time of shift are same'
    severity = Status.Error
  } else if (time <= (userActivity?.start ?? 0)) {
    message =
      'Ending time of shift cannot be less than or equal to starting time'
    severity = Status.Error
  } else if (totalIntervals > 0) {
    let maxEndTime = 0
    userActivity?.intervals?.every(interval => {
      if (interval.end !== 0) {
        maxEndTime = interval.end
        return false
      }
      return true
    })
    if (maxEndTime === 0) {
      maxEndTime = first(userActivity?.intervals)?.start ?? -1
    }
    if (time <= maxEndTime) {
      message = 'Shift end time conflicts with break duration'
      severity = Status.Error
    } else {
      message = 'Activity time is updated'
      severity = Status.Success
    }
  } else {
    message = 'Activity time is updated'
    severity = Status.Success
  }
  return { message, severity }
}

export const checkForTimeConflictsInUserInterval = (
  userActivity: UserActivityUpdated,
  time: number,
  activity: string,
  index: number,
): AlertStatus => {
  let message = ''
  let severity = ''
  if (activity === UserStatus.Brb) {
    if (time <= (userActivity?.start ?? 0)) {
      message =
        'Break starting time cannot be less than or equal to starting time of shift'
      severity = Status.Error
    } else if (userActivity.end !== 0 && time >= (userActivity.end ?? 0)) {
      message =
        'Break starting time cannot be greater than or equal to ending time of shift'
      severity = Status.Error
    } else if (
      index > 0 &&
      time <= (userActivity?.intervals?.[index - 1]?.end ?? 0)
    ) {
      message = 'Break starting time is conflicting with another break time'
      severity = Status.Error
    } else {
      message = 'Activity time is updated'
      severity = Status.Success
    }
    return { message, severity }
  }
  if (time <= (userActivity.start ?? 0)) {
    message =
      'Break ending time cannot be less than or equal to starting time of shift'
    severity = Status.Error
  } else if (userActivity.end !== 0 && time >= (userActivity.end ?? 0)) {
    message =
      'Break ending time cannot be greater than or equal to ending time of shift'
    severity = Status.Error
  } else if (
    index < (userActivity?.intervals?.length ?? 0) - 1 &&
    time >= (userActivity?.intervals?.[index + 1]?.start ?? 0)
  ) {
    message = 'Break ending time is conflicting with another break time'
    severity = Status.Error
  } else {
    message = 'Activity time is updated'
    severity = Status.Success
  }
  return { message, severity }
}

export const getUserStatusAfterActivityDeletion = (
  userActivity: UserActivityUpdated,
): string => {
  if (userActivity.start === 0) return ''
  if (userActivity.end !== 0) return UserStatus.Out
  const totalIntervals = userActivity?.intervals?.length ?? 0
  if (totalIntervals > 0) {
    if (userActivity?.intervals?.[totalIntervals - 1]?.end !== 0)
      return UserStatus.In
    return UserStatus.Brb
  }
  return UserStatus.In
}

export const timestampToDateTime = (timestamp: number) => {
  const slices = new Date(timestamp).toLocaleString('sv').split(':')
  return `${slices[0]}:${slices[1]}`
}

export const timestampToYMDFormat = (timestamp: number) =>
  moment(timestamp).format(YMDFormat)

export const timestampToUTCTimestamp = (timestamp: number) =>
  timestamp - new Date(timestamp).getTimezoneOffset() * 60000

export const getTotalBreak = (intervals: Array<Interval>): number => {
  let totalBreak = 0
  intervals?.forEach(interval => {
    if (interval.start !== 0 && interval.end !== 0) {
      const startTime = new Date(interval.start).getTime()
      const endTime = new Date(interval.end).getTime()
      const diff = endTime - startTime
      const milliSeconds = Math.floor(diff)
      totalBreak += milliSeconds
    }
  })
  return totalBreak
}

export const getActivityBasedDeleteMessage = (
  utcDate: number,
  activity: string,
  start: number,
  end: number,
): string => {
  if (activity === UserStatus.In) {
    return `Deleted start time of ${timestampToHMFormat(start)}
     for the shift of ${timestampToLocalStringFormat(utcDate)}`
  }
  return `Deleted end time of ${timestampToHMFormat(
    end,
  )} for the shift of ${timestampToLocalStringFormat(utcDate)}`
}

export const getActivityBasedBreakDeleteMessage = (
  utcDate: number,
  interval: Interval,
): string =>
  interval.end !== 0
    ? `Deleted a break of ${milliSecondsToHMFormat(
        interval.end - interval.start,
      )} from ${timestampToHMFormat(interval.start)} to ${timestampToHMFormat(
        interval.end,
      )} for the shift of ${timestampToLocalStringFormat(utcDate)}`
    : `Deleted a break from ${timestampToHMFormat(
        interval.start,
      )} for the shift of ${timestampToLocalStringFormat(utcDate)}`

export const getEditedActivityMessage = (
  utcDate: number,
  activity: string,
  time: number,
  userActivity: UserActivityUpdated,
): string => {
  if (activity === UserStatus.In) {
    return `Edited start time from ${timestampToHMFormat(
      userActivity?.start ?? 0,
    )} to ${timestampToHMFormat(time)} 
    for the shift of ${timestampToLocalStringFormat(utcDate)}`
  }

  return `Edited end time from ${timestampToHMFormat(
    userActivity?.end ?? 0,
  )} to ${timestampToHMFormat(
    time,
  )} for the shift of ${timestampToLocalStringFormat(utcDate)}`
}

export const getEditedBrbActivityMessage = (
  utcDate: number,
  userActivity: UserActivityUpdated,
  index: number,
  time: number,
): string =>
  `Edited break start time from ${timestampToHMFormat(
    userActivity?.intervals?.[index].start ?? 0,
  )} to ${timestampToHMFormat(
    time,
  )} for the shift of ${timestampToLocalStringFormat(utcDate)}`

export const getEditedUserIntervalMessage = (
  utcDate: number,
  interval: Interval,
  startTime: number,
  endTime: number,
): string =>
  `Edited break from (${timestampToHMFormat(
    interval.start,
  )} - ${timestampToHMFormat(interval.end)}) to (${timestampToHMFormat(
    startTime,
  )} - ${timestampToHMFormat(
    endTime,
  )}) for the shift of ${timestampToLocalStringFormat(utcDate)}`
