import { createSlice } from '@reduxjs/toolkit'
import { GazettedHoliday } from 'core/service'
import {
  saveEmailConfigSetting,
  getEmailConfigSetting,
  addGazettedHoliday,
  updateGazettedHoliday,
  getGazettedHolidays,
  deleteGazettedHoliday,
} from 'features/settings/thunks/settings'
import { SettingsState } from 'features/settings/types'
import { sortBy as _sortBy } from 'lodash'
import { toast } from 'react-toastify'

const initialState = {
  isLoading: true,
  config: {},
  gazettedHolidays: [] as GazettedHoliday[],
} as SettingsState

const SettingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saveEmailConfigSetting.pending, state => {
      state.isLoading = true
    })
    builder.addCase(saveEmailConfigSetting.fulfilled, (state, action) => {
      state.isLoading = false
      state.config = action.payload
      toast.success('Settings are saved successfully.')
    })
    builder.addCase(saveEmailConfigSetting.rejected, state => {
      state.isLoading = false
      toast.error('Something went wrong, try again.')
    })

    builder.addCase(getEmailConfigSetting.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getEmailConfigSetting.fulfilled, (state, action) => {
      state.isLoading = false
      state.config = action.payload
    })
    builder.addCase(getEmailConfigSetting.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(addGazettedHoliday.fulfilled, (state, action) => {
      state.isLoading = false
      state.gazettedHolidays = _sortBy(
        [...state.gazettedHolidays, action.payload],
        ['startDate'],
      ).reverse()
      toast.success('Holiday added successfully.')
    })

    builder.addCase(updateGazettedHoliday.fulfilled, (state, action) => {
      const updatedHoliday = action.payload as GazettedHoliday
      state.gazettedHolidays.forEach(holiday => {
        if (holiday.id === updatedHoliday.id) {
          holiday.startDate = updatedHoliday.startDate
          holiday.endDate = updatedHoliday.endDate
          holiday.holidayTitle = updatedHoliday.holidayTitle
        }
      })
      state.gazettedHolidays = _sortBy(state.gazettedHolidays, [
        'startDate',
      ]).reverse()
      toast.success('Holiday updated successfully.')
    })

    builder.addCase(deleteGazettedHoliday.pending, state => {
      state.isLoading = true
    })
    builder.addCase(deleteGazettedHoliday.fulfilled, (state, action) => {
      const deletedId = action.payload
      state.gazettedHolidays = state.gazettedHolidays.filter(
        holiday => holiday.id !== deletedId,
      )
      state.isLoading = false
      toast.success('Holiday deleted successfully.')
    })
    builder.addCase(deleteGazettedHoliday.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(getGazettedHolidays.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getGazettedHolidays.fulfilled, (state, action) => {
      state.isLoading = false
      state.gazettedHolidays = action.payload
    })
    builder.addCase(getGazettedHolidays.rejected, state => {
      state.isLoading = false
    })
  },
})

export default SettingsSlice
