import { createSlice } from '@reduxjs/toolkit'
import { AppState } from 'features/app/types'

const initialState = {
  isDarkMode: false,
  mobileDrawerOpen: false,
  serverDate: 0,
  isDateFetching: false,
} as AppState

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setDarkModeAction: (state, action) => {
      state.isDarkMode = action.payload
    },
    setMobileDrawerOpen: (state, action) => {
      state.mobileDrawerOpen = action.payload
    },
    setServerDateAction: (state, action) => {
      state.serverDate = action.payload
    },
    setIsDateFetchingAction: (state, action) => {
      state.isDateFetching = action.payload
    },
  },
})

export const {
  setDarkModeAction,
  setMobileDrawerOpen,
  setServerDateAction,
  setIsDateFetchingAction,
} = appSlice.actions

export default appSlice
