import { createSlice } from '@reduxjs/toolkit'
import { getReports } from 'features/reports/thunks/reports'
import { ReportsState } from 'features/reports/types'

const initialState = {
  reportsData: [],
  isLoading: false,
} as ReportsState

const reportsSlice = createSlice({
  name: 'membersSlice',
  initialState,
  reducers: {
    setReportsDataAction: (state, action) => {
      state.reportsData = action.payload
    },
    setIsLoadingAction: (state, action) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getReports.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.reportsData = action.payload
      state.isLoading = false
    })
    builder.addCase(getReports.rejected, state => {
      state.isLoading = false
    })
  },
})

export const { setReportsDataAction, setIsLoadingAction } = reportsSlice.actions

export default reportsSlice
