import { CssBaseline, ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { NotFound } from 'core/components/NotFound'
import CustomNotification from 'core/components/Notification'
import { ProtectedRoute } from 'core/components/ProtectedRoute'
import { PublicRoute } from 'core/components/PublicRoute'
import { Spinner } from 'core/components/Spinner'
import Template from 'core/components/Template'
import { auth, messaging } from 'core/config/firebase'
import { Role } from 'core/constants/enum'
import darkTheme from 'core/theme/dark'
import lightTheme from 'core/theme/light'
import { getRoutesBasedOnUserRole } from 'core/utls'
import { setDarkModeAction } from 'features/app/reducers/app'
import { setUserAction } from 'features/landing/reducers/auth'
import { getUserData } from 'features/landing/thunks/auth'
import { onAuthStateChanged } from 'firebase/auth'
import { getToken } from 'firebase/messaging'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { saveFcmToken } from './features/dashboard/thunks/dashboard'

function App() {
  const { isDarkMode } = useAppSelector(state => state.app)
  const { user } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()
  const [isCheckingAuthState, setIsCheckingAuthState] = useState(true)

  const routes = getRoutesBasedOnUserRole(user?.role ?? '')

  useEffect(() => {
    const requestPermissions = async () => {
      if (
        'Notification' in window &&
        Notification.permission === 'granted' &&
        user?.uid &&
        user?.role !== Role.Member
      ) {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
        if (token && user?.uid) {
          await dispatch(
            saveFcmToken({ userId: user?.uid ?? '', fcmToken: token }),
          )
        }
      }
    }
    requestPermissions()
  }, [user?.uid, user?.role, dispatch])

  useEffect(() => {
    const isDarkModeFromLS = window.localStorage.getItem('isDarkMode')
    if (isDarkModeFromLS === null)
      window.localStorage.setItem('isDarkMode', JSON.stringify(false))
    else dispatch(setDarkModeAction(JSON.parse(isDarkModeFromLS)))
    const unsub = onAuthStateChanged(auth, authUser => {
      if (authUser) {
        const values = {
          email: authUser.email ? authUser.email : '',
          uid: authUser.uid,
        }
        Sentry.setUser({
          email: authUser?.email || 'unknown',
        })
        dispatch(getUserData(values)).then(() => setIsCheckingAuthState(false))
      } else {
        dispatch(setUserAction(null))
        setIsCheckingAuthState(false)
      }
    })
    return () => unsub && unsub()
  }, [dispatch])

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {isCheckingAuthState ? (
        <Spinner />
      ) : (
        <Router>
          <Routes>
            {routes?.map(route => (
              <Route
                key={route.path}
                element={
                  route.isPrivate ? (
                    <ProtectedRoute path={route.path} user={user}>
                      <Template selected={route.path}>
                        <route.component />
                      </Template>
                    </ProtectedRoute>
                  ) : (
                    <PublicRoute user={user}>
                      <route.component />
                    </PublicRoute>
                  )
                }
                path={route.path}
              />
            ))}
            <Route element={<NotFound />} path="*" />
          </Routes>
        </Router>
      )}
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        position="top-right"
        rtl={false}
      />
      <CustomNotification />
    </ThemeProvider>
  )
}

export default App
